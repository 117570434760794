import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { forwardRef } from "react";

type TextInputProps = {
  label: string;
} & TextFieldProps;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, ...props }: TextInputProps, ref) => {
    return (
      <label className="flex flex-col gap-2">
        <span className="font-semibold text-sm">{label}</span>
        <TextField
          variant="outlined"
          ref={ref}
          fullWidth
          size="small"
          {...props}
        />
      </label>
    );
  }
);

export default TextInput;
