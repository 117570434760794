import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { useCookies } from 'react-cookie'

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    useEffect(() => {
      const listener = props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!authUser || !condition(authUser)) {
            props.history.push(ROUTES.SIGN_IN);
          }
        }
      );

      const tokenInterval = setInterval(() => {
        if (props.firebase.auth.currentUser) {
          props.firebase.auth.currentUser.getIdToken(true)
            .then((token) => {
              removeCookie('token', { path: '/' });
              setCookie('token', token, { path: '/' });
            })
            .catch((err) => {
              alert(`Token retrieval error: ${err}`);
            });
        }
        //todo change this
      }, 10000);

      return () => {
        listener();
        clearInterval(tokenInterval);
      };
    }, []);

    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser && condition(authUser) ? <Component {...props} /> : null
        }
      </AuthUserContext.Consumer>
    );
  };

  return compose(withRouter, withFirebase)(WithAuthorization);
};

export default withAuthorization;
