import React, { Component } from "react";

import CompanyAddEditForm from "./companyAddEditFront";
import CompanyAddEditFunc from "./companyAddEditFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Helpers from "../../../Helpers";

const INITIAL_STATE = {
  action: "Add",
  companyCode: "",
  companyTitle: "",
  companyIcon: [],
  companyDescription: "",
  companyAccountLabel: "",
  companyCodeError: "",
  companyTitleError: "",
  companyIconError: "",
  companyAccountLabelError: "",
  fileName: "",
  isLoading: false,
  toggleAlert: false,
  messageType: "warning",
  messageAlert: "",
  toggleActionAlert: false,
};

class CompanyAddEditFormBase extends Component {
  constructor(props) {
    super(props);

    this.helpers = new Helpers();
    this.state = { ...INITIAL_STATE };
    this.companyAddEditFunction = new CompanyAddEditFunc();
    this.db = firebase.firestore();
    this.fundCompRef = this.db.collection("fundCompanies");
  }

  async componentDidMount() {
    var compId = this.props.location.state ? this.props.location.state.id : -1;

    if (compId !== -1) {
      this.setState({ action: "Edit" });
      await this.getCompanyDetails(compId);
    }
  }

  async getCompanyDetails(compId) {
    await this.fundCompRef
      .doc(compId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const companyData = doc.data();
          let compDesc = this.helpers.stringToWhiteSpaceConverter(
            companyData.description
          );
          this.setState({
            companyCode: companyData.code,
            companyTitle: companyData.name,
            companyDescription: compDesc,
            companyAccountLabel: companyData.accountLabel,
            fileName: companyData.icon,
          });
        } else {
          this.setState({
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: "Document doesn't exists.",
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  handleInputChange = async (event) => {
    if (event.target.name === "companyIcon") {
      const file = event.target.files;

      var dimension = await this.getImageDimension(file);

      if (file.length > 0) {
        if (dimension.width > 200 && dimension.height > 100) {
          this.setState({
            companyIcon: [],
            fileName: "",
            companyIconError: "File exceeds the required size",
          });

          event.target.value = null;
        } else if (dimension.width < 100 && dimension.height < 50) {
          this.setState({
            companyIcon: [],
            fileName: "",
            companyIconError: "File does not meet the minimum required size",
          });

          event.target.value = null;
        } else {
          this.setState({
            companyIcon: file,
            fileName: file[0].name,
            companyIconError: "",
          });
        }
      } else {
        this.setState({
          companyIcon: [],
          fileName: "",
          companyIconError: "Please upload an icon.",
        });
      }
    } else {
      const name = event.target.name;
      this.setState({ [event.target.name]: event.target.value }, () =>
        this.validator(name)
      );
    }
  };

  getImageDimension(file) {
    const dimension = [];
    var _URL = window.URL || window.webkitURL;
    var img;

    var promise = new Promise((resolve, reject) => {
      img = new Image();
      img.onload = function () {
        dimension.push({ width: this.width, height: this.height });
        resolve(dimension[0]);
      };
      img.onerror = function (err) {
        reject(err.message);
      };
      img.src = _URL.createObjectURL(file[0]);
    });

    return promise;
  }

  validator = (name) => {
    const { categoryCode, categoryTitle } = this.state;
    switch (name) {
      case "categoryCode":
        if (categoryCode.trim() === "") {
          this.setState({ categoryCodeError: "Please input company code." });
        } else {
          this.setState({ categoryCodeError: "" });
        }
        break;
      case "categoryTitle":
        if (categoryTitle.trim() === "") {
          this.setState({ categoryTitleError: "Please input company name." });
        } else {
          this.setState({ categoryTitleError: "" });
        }
        break;
      default:
        break;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    var companyId = this.props.location.state
      ? this.props.location.state.id
      : -1;

    this.setState({ isLoading: true });

    const {
      companyCode,
      companyTitle,
      companyIcon,
      companyDescription,
      companyAccountLabel,
      companyCodeError,
      companyTitleError,
      companyIconError,
      companyAccountLabelError,
    } = this.state;

    const isValid =
      companyCodeError === "" &&
      companyTitleError === "" &&
      companyIconError === "" &&
      companyAccountLabelError === "";

    if (isValid) {
      let companyObj = {
        name: companyTitle,
        code: companyCode,
        description:
          this.helpers.whiteSpaceToStringConverter(companyDescription),
        accountLabel: companyAccountLabel,
      };

      if (companyId === -1) {
        this.companyAddEditFunction
          .doCompanyAdd(companyObj, companyIcon)
          .then(() => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "success",
              messageAlert: "Company added successfully.",
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.COMPANY_LIST);
            }, 1000);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "error",
              messageAlert: error.message,
            });
          });
      } else {
        companyObj.companyUid = companyId;
        this.companyAddEditFunction
          .doCompanyEdit(companyObj, companyIcon)
          .then(() => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "success",
              messageAlert: "Company updated successfully.",
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.COMPANY_LIST);
            }, 1000);
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "error",
              messageAlert: error.message,
            });
          });
      }
    } else {
      this.setState({
        isLoading: false,
        toggleAlert: true,
        messageType: "error",
        messageAlert: "Check all fields before proceeding.",
      });
    }
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleActionAlert: false });
  };

  render() {
    return (
      <CompanyAddEditForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
      />
    );
  }
}

export default withRouter(CompanyAddEditFormBase);
