import React, { Component } from "react";

import DashboardFunction from "./dashboardFunction";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";

import Navigation from "../Navigation";

const DashboardPage = () => {
  return (
    <>
      <Navigation content={<DashboardForm />} />
    </>
  );
};

class DashboardPageBase extends Component {
  render() {
    return (
      <>
        <DashboardFunction props={this.props} />
        <label
          variant="caption"
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const DashboardForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(DashboardPageBase);

export default DashboardPage;

export { DashboardForm };
