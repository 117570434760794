export const riskData = [
  {
    question: "Which of the following instruments have you invested in?",
    key: 'instrumentsInvested',
    answer: [
      "Time Deposits, Money Market Funds",
      "Government Securities, Corporate Bonds, Peso/Dollar Bond Funds (MF and UITF)",
      "Direct Stocks, Equity Mutual Funds/UITFs, Derivatives",
    ]
  },
  {
    question: "What's your priority in investing?",
    key: 'investmentPriority',
    answer: [
      "Capital Preservation",
      "Income Generation but with Capital Protection",
      "Capital Appreciation"
    ]
  },
  {
    question: "How familiar are you with different types of investments?",
    key: 'investmentFamiliarity',
    answer: [
      "My knowledge is limited",
      "My knowledge is average",
      "My knowledge is extensive"
    ]
  },
  {
    question: "Your current investments are mostly in which financial instrument?",
    key: 'investmentType',
    answer: [
      "Time Deposits, Money Market Funds",
      "Government Securities, Corporate Bonds, Peso/Dollar Bond Funds (MF and UITF)",
      "Direct Stocks, Equity Mutual Funds/UITFs, Derivatives"
    ]
  },
  {
    question: "How stable are your finances?",
    key: 'incomeStability',
    answer: [
      "Somewhat Stable",
      "Stable but seeing major expenses in 3 years",
      "Very stable for the next 3 years",
    ]
  },
  {
    question: "How many years can you invest without redeeming your funds?",
    key: 'periodWithoutRedemption',
    answer: [
      "1 year",
      "3-5 years",
      "More than 5 years",
    ]
  },
  {
    question: "How many years until your retirement?",
    key: 'yearsUntilRetirement',
    answer: [
      "1 year-7 years",
      "8-15 years",
      "More than 15 years"
    ]
  },
  {
    question: "How much risk are you willing to take?",
    key: 'willingRisk',
    answer: [
      "Low Risk",
      "Moderate Risk",
      "High Risk"
    ]
  },
  {
    question: "What portfolio are you most comfortable with?",
    key: 'portfolioComfort',
    answer: [
      "Highest return of 10%, biggest loss of 5%",
      "Highest return of 40%, biggest loss of 30%",
      "Highest return of 60%, biggest loss of 50%"
    ]
  },
];