import React, { useEffect, useState } from "react";
import Navigation from "../../Navigation";
import { compose } from "recompose";
import withAuthorization from "../../Session/withAuthorization";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../Firebase";
import { ExportClientData } from "../../Operations/Download";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import moment from "moment";
import CustomDataTable from "../../CustomDataTable";
import firebase from "firebase/compat/app";

const condition = (authUser) => !!authUser;

const GraduatingUsersListPage = (props) => {
  return (
    <>
      <Navigation content={<GradUsers props={props} />} />
    </>
  );
};

const GradUserList = ({ props }) => {
  const db = firebase.firestore();

  const [userDataList, setUserDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      setLoading(true);
      const [users, ratesSnapshot, fundsSnapshot] = await Promise.all([
        db.collection("users").where("investmentSummary", "!=", null).get(),
        db
          .collection("fundRates")
          .orderBy("dateUploaded", "desc")
          .limit(1)
          .get(),
        db.collection("funds").get(),
      ]);
      let usersWithData = users.docs
        .filter(
          (user) =>
            user.data().investmentSummary &&
            user.data().investmentSummary.length > 0
        )
        .map((user) => user.data());

      const rates = ratesSnapshot.docs.map((doc) => doc.data())[0];
      const funds = fundsSnapshot.docs.map((doc) => doc.data());

      usersWithData = usersWithData.map((user) => {
        const investmentSummary = user.investmentSummary;
        let investmentAmount = 0;
        if (investmentSummary.length > 0) {
          investmentSummary.forEach((investment) => {
            const selectedFund = funds.find(
              (fund) => fund.code === investment.fundCode
            );
            const rate = rates.fundDetails.find(
              (obj) => obj.fundName === selectedFund.name
            );

            investmentAmount += rate.rate * investment.value;
          });
        }

        const submissionDate = moment
          .unix(user.dateCreated.seconds)
          .format("YYYY-MM-DD hh:mm");
        const registrationDate = moment
          .unix(user.dateKYCCompleted.seconds)
          .format("YYYY-MM-DD hh:mm");

        return {
          ...user,
          tier: user.stats.tier ?? "Legacy",
          clientName: `${user.firstName} ${user.lastName}`,
          submissionDate,
          registrationDate,
          portfolioValue: investmentAmount,
        };
      });

      const filteredUsers = usersWithData.filter(
        (user) =>user.tier === 2 && user.portfolioValue >= 45000 && user.portfolioValue <= 50000
      );

      setUserDataList(filteredUsers);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: "accountNumber",
      label: "Account Number",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "portfolioValue",
      label: "Portfolio Value",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          const floatValue =
            typeof value === "string" ? parseFloat(value) : value;

          if (!isNaN(floatValue)) {
            return floatValue.toLocaleString("en-PH", {
              style: "currency",
              currency: "PHP",
            });
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "tier",
      label: "Tier",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    selectableRows: false,
    download: false,
    print: false,
    sortOrder: {
      name: "date",
      direction: "asc",
    },
    onRowClick: (data, meta) => {
      this.redirectUser(meta.dataIndex);
    },
  };

  const loader = (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 999,
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <>
      <div className="userListRoot">
        <div className="userListpaper">
          <div className="userListBody"></div>
          <h1>Graduating Users</h1>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "10px",
            }}
          >
            <label variant="caption" className={"userListdescription"}>
              {`List of all clients where investments total or greater than ₱45,000.00`}
            </label>

            <ExportClientData
              data={userDataList}
              button={
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: "white",
                    textTransform: "capitalize",
                  }}
                >
                  Export All Data
                </Button>
              }
              filename={`Graduating User List - ${moment().format(
                "MM/DD/YY - hh:mm a"
              )}`}
            />
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              {loading && loader}
              <CustomDataTable
                data={userDataList}
                columns={columns}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

const GradUsers = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(GradUserList);

export default GraduatingUsersListPage;
