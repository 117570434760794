import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect, { SelectProps } from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    position: "relative",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  label: string;
  options: Option[];
} & SelectProps;

type Option = {
  label: string;
  value: string;
};

export default function Select({ label, options, ...props }: Props) {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required
      className={classes.formControl}
      size="small"
    >
      <label className="font-semibold text-sm">{label}</label>
      <MuiSelect
        className={classes.selectEmpty}
        defaultValue={options[0].value}
        {...props}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
