import React, { Component } from "react";

import TermsAndConditionFunction from "./termsAndConditionFunction";

import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../Session/withAuthorization";

import Navigation from "../../Navigation";

const TermsConditionPage = () => {
  return (
    <>
      <Navigation content={<TermsConditionForm />} />
    </>
  );
};

class TermsConditionPageBase extends Component {
  render() {
    return (
      <>
        <TermsAndConditionFunction props={this.props} />
        <label
          variant="caption"
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved TAC.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const TermsConditionForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(TermsConditionPageBase);

export default TermsConditionPage;

export { TermsConditionForm };
