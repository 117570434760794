import React, { Component } from "react";

import SigninFront from "./signinFront";
import * as ROUTES from "../../constants/routes";
import { withCookies } from "react-cookie";

const INITIAL_STATE = {
  email: "",
  password: "",
  showPass: false,
  errors: "",
  emailError: "",
  passwordError: "",
  toggleAlert: false,
  alertType: "warning",
  messageAlert: "",
  loading: false,
};

class SigninFucntion extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.fb = props.props.firebase;
    this.history = props.props.history;
  }

  validate = (e) => {
    const { email, password } = this.state;

    const errors = {};
    switch (e.target.name) {
      case "email":
        if (email.indexOf("@") === -1) {
          errors.emailError = "Invalid Email Address.";
        } else {
          errors.emailError = "";
        }
        break;
      case "password":
        if (password.length < 1) {
          errors.passwordError = "This field is required.";
        } else {
          errors.passwordError = "";
        }
        break;
      default:
        if (email.length === 0) {
          errors.emailError = "This field is required.";
        }
        if (password.length === 0) {
          errors.passwordError = "This field is required.";
        }
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    if (Object.keys(errors).length !== 0) {
      return true;
    } else {
      return false;
    }
  };

  onhandleFormChange = (e) => {
    // event must persist to be used in setState callback
    // without persisting, event will be nullified after setState
    e.persist();
    const { name, value } = e.target;

    this.setState(
      (prev) => ({
        ...prev,
        [name]: value,
      }),
      () => {
        this.validate(e);
      }
    );
  };

  onClickShowPassword = () => {
    this.setState({ showPass: true });
  };

  onMouseDownPassword = () => {
    this.setState({ showPass: false });
  };

  onhandleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });
    const { email, password } = this.state;
    const { cookies } = this.props;
    const isValid = email !== "" || password !== "";

    if (isValid) {
      this.fb
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          this.fb
            .getCustomClaims()
            .then((res) => {
              if (["admin", "kyc", "cms"].includes(res.claims.role)) {
                this.setState({
                  toggleAlert: true,
                  messageAlert: "Login success.",
                  alertType: "success",
                  loading: false,
                });
                cookies.set("token", res.token, { path: "/" });
                this.history.push(ROUTES.DASHBOARD);
              } else {
                this.fb.doSignOut();

                this.setState({
                  toggleAlert: true,
                  messageAlert: "Invalid account.",
                  alertType: "error",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                toggleAlert: true,
                messageAlert: "Unable to retrieve auth claims.",
                alertType: "error",
                loading: false,
              });
            });
        })
        .catch((error) => {
          this.setState(
            {
              toggleAlert: true,
              messageAlert: error.message,
              alertType: "error",
            },
            () => {
              this.setState({ loading: false });
            }
          );
        });
    } else {
      this.setState({
        toggleAlert: true,
        messageAlert: "Unable to sign in.",
        alertType: "error",
        loading: false,
      });
    }
  };

  render() {
    return (
      <SigninFront
        state={this.state}
        onhandleFormChange={() => this.onhandleFormChange.bind(this)}
        onClickShowPassword={() => this.onClickShowPassword.bind(this)}
        onMouseDownPassword={() => this.onMouseDownPassword.bind(this)}
        onhandleSubmit={() => this.onhandleSubmit.bind(this)}
      />
    );
  }
}

export default withCookies(SigninFucntion);
