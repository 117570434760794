import React, { useRef } from "react";

import "./css/termsAndCondition.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { withRouter } from "react-router-dom";

import { ValidatorForm } from "react-material-ui-form-validator";
import OutlinedInputValidator from "../../Helpers/CustomInputValidator/outlinedInput";
import WysiwygValidator from "../../Helpers/CustomInputValidator/wysiwyg";

import CustomModal from "../../Helpers/CustomModal";

import { Button, CircularProgress, Snackbar } from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TermsAndConditionForm = (props) => {
  const refForm = useRef("form");
  const {
    termsTitle,
    termsDescription,
    termsContent,
    isContentEmpty,
    isChange,
    isSubmit,
    toggleDialog,
    messageType,
    messageAlert,
    toggleActionAlert,
    isLoading,
  } = props.state;

  let title = "";
  switch (props.location.pathname) {
    case ROUTES.CONTENT:
      title = "Terms and Conditions";
      break;

    case ROUTES.REDEMPTION_TERMS:
      title = "Redemption Terms";
      break;

    default:
      title = "Privacy Policy";
      break;
  }

  return (
    <>
      <div className={"termsAndConditionRoot"}>
        <ValidatorForm
          ref={refForm}
          onSubmit={props.onSubmit()}
          onError={props.onCheckWysiwygError()}
        >
          <div className={"termsAndConditionContainer"}>
            <label className={"termsAndConditionHeader"}>{title}</label>
            <label className={"termsAndConditionTitle"}>Title</label>

            <OutlinedInputValidator
              name="termsTitle"
              value={termsTitle}
              style={{ width: "100%" }}
              inputProps={{
                className: "termsAndConditionTextBox",
                value: termsTitle,
              }}
              variant="outlined"
              onChange={props.onChange()}
              placeholder="Title..."
              autoComplete="off"
              validators={["required"]}
              errorMessages={["This field is required"]}
            />

            <br />
            <label className={"termsAndConditionTitle"}>Description</label>

            <OutlinedInputValidator
              name="termsDescription"
              value={termsDescription}
              style={{ width: "100%" }}
              inputProps={{
                className: "termsAndConditionDescription",
                value: termsDescription,
              }}
              variant="outlined"
              onChange={props.onChange()}
              placeholder="Description..."
              autoComplete="off"
              multiline
              rows={7}
              validators={["required"]}
              errorMessages={["This field is required"]}
            />

            <br />
            <label className={"termsAndConditionTitle"}>Content</label>

            <WysiwygValidator
              editorState={termsContent}
              value={termsContent}
              isSubmit={isSubmit}
              toolbarClassName={"termsAndConditionToolbar"}
              wrapperClassName={
                isContentEmpty
                  ? "termsAndConditionWrapperError"
                  : "termsAndConditionWrapper"
              }
              editorClassName={"termsAndConditionEditor"}
              onEditorStateChange={props.onHandleContentChange()}
              toolbar={{
                options: [
                  "inline",
                  "fontSize",
                  "list",
                  "textAlign",
                  "link",
                  "image",
                ],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                list: {
                  inDropdown: true,
                  options: ["unordered", "ordered"],
                },
                textAlign: {
                  inDropdown: true,
                  options: ["left", "center", "right", "justify"],
                },
                link: {
                  inDropdown: true,
                },
              }}
              validators={["isContentReq"]}
              errorMessages={["This field is required"]}
            />

            <br />
            <div>
              <Button
                onClick={props.onHandleDialog()}
                style={{ pointerEvents: isLoading ? "none" : "all" }}
                className={"termsAndConditionCancelButton"}
              >
                Cancel
              </Button>
              {!isLoading ? (
                <Button type="submit" className={"termsAndConditionAddButton"}>
                  Save Changes
                </Button>
              ) : (
                <CircularProgress className={"termsAndConditionLoading"} />
              )}
            </div>
          </div>
        </ValidatorForm>
      </div>

      <CustomModal
        toggleDialog={toggleDialog}
        onClose={props.onHandleDialog()}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={2000}
        open={toggleActionAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withRouter(TermsAndConditionForm);
