import React, { useEffect, useMemo, useState } from 'react';
import { Paper, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Button, FormControl, InputLabel, OutlinedInput, LinearProgress, Snackbar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { Alert } from '@material-ui/lab';

const EditContent = ({ item, updateItem, selectedRow }) => {
    const [rate, setRate] = useState(item.rate)
    const [ytd, setYtd] = useState(item.ytd) // usememo

    const [loading, setLoading] = useState(false)

    const [alertOpen, setAlertOpen] = useState(false)
    const [returnData, setReturnData] = useState({})

    const same = useMemo(() => item.rate === rate && item.ytd === ytd, [item, rate, ytd])

    const save = async () => {
        setLoading(true)
        const obj = {
            id: item.id,
            fundName: selectedRow,
            rate: parseFloat(rate),
            ytd: parseFloat(ytd)
        }

        const data = await updateItem(obj);

        setReturnData(data);
        setAlertOpen(true);
        setLoading(false);
    }

    useEffect(() => {
        if (item) {
            setRate(item.rate)
            setYtd(item.ytd)
        }
    }, [item])

    return (

        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity={returnData.ok ? "success" : "error"}
                >
                    {returnData.message}
                </Alert>
            </Snackbar>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
                <div style={{ flexGrow: 1, marginBottom: '5px' }}>
                    <span>{`NAVPS for: ${moment.unix(item.navpsDate).format("MM/DD/YYYY")}`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px', marginBottom: '5px' }}>
                    <span>Rate:</span>
                    <TextField
                        id="rate"
                        variant="outlined"
                        size={'small'}
                        value={rate}
                        type='number'
                        onChange={(e) => setRate(e.target.value)}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px', marginBottom: '5px' }}>
                    <span>YTD:</span>
                    <TextField
                        id="ytd"
                        variant="outlined"
                        size={'small'}
                        value={ytd}
                        type='number'
                        onChange={(e) => setYtd(e.target.value)}
                    />
                </div>
                {loading && <LinearProgress style={{ marginBottom: '5px' }} color="primary" />}
                {!same && (
                    <Button disabled={loading} fullWidth variant="contained" color="primary" style={{ color: 'white' }} onClick={() => save()}>
                        Save
                    </Button>
                )}
            </div>
        </>

    )

}


const CalendarView = ({ selectedRow, rateDataList, updateItem }) => {
    const dataList = useMemo(() => {
        const list = [];
        if (selectedRow !== "") {
            rateDataList.forEach((doc) => {
                const fundDetail = doc.data().fundDetails.find((x) => x.fundName === selectedRow)
                list.push(
                    {
                        id: doc.id,
                        date: doc.data().date.seconds,
                        navpsDate: fundDetail.date.seconds,
                        rate: fundDetail.rate,
                        ytd: fundDetail.ytd
                    }
                )
            })
        }

        return list;
    }, [selectedRow, rateDataList])

    return (
        <>
            {dataList.map((item) => (
                <Accordion style={{ flexGrow: 1 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={`header-${item.id}`}
                    >
                        <span style={{ fontWeight: 'bold', fontSize: '1rem' }}>{moment.unix(item.date).format("MM/DD/YYYY - HH:mm")}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EditContent item={item} updateItem={updateItem} selectedRow={selectedRow} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default CalendarView;