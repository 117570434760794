import React from "react";
import CustomDataTable from "../../CustomDataTable";
import { Grid, Button } from "@material-ui/core";
import { Download } from "../Download";

export function NewInvestmentListFront(props) {
  document.body.style.backgroundColor = "white";
  return (
    <div className="cms-main-content">
      <div className="body-content">
        <div className="transaction-list">
          <h1>New Investments</h1>
          <span>
            Consists of all new investments. Click a row for more details.
          </span>
          <br /> <br /> <br />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomDataTable
                title={"Transactions List"}
                data={props.transactions_list}
                columns={props.columns}
                options={props.options}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
