import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import {
  Grid,
  FormControl,
  Select,
  Button,
  CircularProgress,
  Snackbar,
  Divider,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import LinkButton from "@material-ui/core/Link";
import Validators from "../../Helpers/validators";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import MobileNumberField from "../../ValidatedFields/MobileNumberField";
import "./css/addUser.css";
import { isReferralCodeUnique } from "@/services";
import isAlphaNumeric from "validator/lib/isAlphanumeric";
import { ReferralLinkGuideLine } from "@/components/ReferrerProfile";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.validators = new Validators();
  }

  componentDidMount() {
    this.validators.mount();
    this.validators.isMatchValue(this.props.state.email);

    ValidatorForm.addValidationRule("isReferralCodeUnique", async (value) => {
      const isUnique = await isReferralCodeUnique(value);
      return isUnique;
    });
  }

  componentWillUnmount() {
    this.validators.unmount();
    ValidatorForm.removeValidationRule("isReferralCodeUnique");
  }

  handleChange = (event) => {
    this.props.onChangeValidator(event);
    this.form.isFormValid(false);
  };

  render() {
    const {
      firstName,
      lastName,
      userName,
      mobileNum,
      userType,
      status,
      password,
      confirmPassword,
      toggleAlert,
      severity,
      messageAlert,
      loading,
      isAddSuccess,
      referralCode,
    } = this.props.state;
    const props = this.props;
    return (
      <>
        <div className={"addUserRoot"}>
          <ValidatorForm
            onSubmit={props.onSubmit()}
            ref={(r) => (this.form = r)}
            id="parent-form"
          >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <label className={"addUserlabels1"}>Add New User</label>
                {toggleAlert && (
                  <Alert className={"addUseralertWidth"} severity={severity}>
                    {messageAlert}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={"addUserlabels"}>First Name</label>
                <br />
                <TextValidator
                  name="firstName"
                  inputProps={{
                    className: "addUsertextboxs",
                    style: { padding: "12px 15px 12px 12px" },
                  }}
                  variant="outlined"
                  value={firstName}
                  fullWidth
                  onChange={props.onChange()}
                  placeholder="Your First Name..."
                  autoComplete="off"
                  validators={["required"]}
                  errorMessages={["Please input first name."]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={"addUserlabels"}>Last Name</label>
                <br />
                <TextValidator
                  name="lastName"
                  inputProps={{
                    className: "addUsertextboxs",
                    style: { padding: "12px 0px 12px 12px" },
                  }}
                  variant="outlined"
                  value={lastName}
                  fullWidth
                  onChange={props.onChange()}
                  placeholder="Your Last Name..."
                  autoComplete="off"
                  validators={["required"]}
                  errorMessages={["Please input last name."]}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={"addUserlabels"}>Mobile Number</label>
                <br />
                <MobileNumberField
                  type="number"
                  name="mobileNum"
                  value={mobileNum}
                  onChange={props.onMobileNumKeyUp()}
                  validators={[
                    "required",
                    "maxStringLength:10",
                    "minStringLength:10",
                  ]}
                  errorMessages={[
                    "This field is required.",
                    "Maximum length is 10 digits",
                    "Minimum length is 10 digits",
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className={"addUserlabels"}>User Type</label>
                <br />
                <FormControl variant="outlined" size="small">
                  <Select
                    native
                    name="userType"
                    value={userType}
                    onChange={props.onChange()}
                    validators={["required"]}
                    errorMessages={["This field is required."]}
                    inputProps={{
                      className: "addUserselectboxs",
                      style: { padding: "12px 32px 12px 12px" },
                    }}
                    disabled={props.state.mode === "add-rm"}
                  >
                    {/* if userType is manager, dropdown should only list manager as the only option and should be disabled, otherwise render Admin,KYC,CMS */}
                    {[
                      ...(userType === "Manager"
                        ? ["Manager"]
                        : ["Admin", "KYC", "CMS"]),
                    ].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <label className={"addUserlabels"}>Status</label>
                <br />
                <FormControl variant="outlined" size="small">
                  <Select
                    native
                    name="status"
                    value={status}
                    onChange={props.onChange()}
                    validators={["required"]}
                    errorMessages={["This field is required."]}
                    inputProps={{
                      className: "addUserselectboxs",
                      style: { padding: "12px 18px 12px 12px" },
                    }}
                  >
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Select>
                </FormControl>
              </Grid>
              {userType === "Admin" && (
                <Grid item xs={12}>
                  <h4>User Key:</h4>
                  <p>
                    <b>Admin: </b>Has access to all parts
                  </p>
                  <p>
                    <b>KYC: </b>Has access to all client information only
                  </p>
                  <p>
                    <b>CMS: </b>Has access to fund-related info only
                  </p>
                </Grid>
              )}
              {userType === "Manager" && (
                <Grid item xs={12}>
                  <label className={"addUserlabels"}>Referral Code</label>
                  <br />
                  <TextValidator
                    name="referralCode"
                    value={referralCode}
                    validators={[
                      "required",
                      "isReferralCodeUnique",
                      "minStringLength:4",
                      "maxStringLength:20",
                    ]}
                    onChange={props.onChange()}
                    inputProps={{
                      style: { padding: "12px 0px 12px 12px" },
                    }}
                    variant="outlined"
                    errorMessages={[
                      "This field is required.",
                      "Referral code already exists.",
                      "Referral Code is too short.",
                      "Referral Code is too long.",
                    ]}
                  />
                </Grid>
              )}

              {userType === "Manager" && (
                <Grid item xs={12}>
                  <ReferralLinkGuideLine />
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <label className={"addUserlabels2"}>Login info</label>
              </Grid>
              <Grid item xs={12}>
                <label className={"addUserlabels"}>Email Address</label>
                <br />
                <TextValidator
                  name="userName"
                  inputProps={{
                    className: "addUsertextboxs2",
                    style: { padding: "12px 0px 12px 12px" },
                  }}
                  variant="outlined"
                  value={userName}
                  onChange={props.onChange()}
                  placeholder="Your email address..."
                  autoComplete="off"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "This field is required.",
                    "Please input a valid email address.",
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={"addUserlabels"}>Password</label>
                <br />
                <TextValidator
                  name="password"
                  type="password"
                  inputProps={{
                    className: "addUsertextboxs2",
                    style: { padding: "12px 0px 12px 12px" },
                  }}
                  variant="outlined"
                  value={password}
                  placeholder="Enter passsword..."
                  autoComplete="off"
                  onChange={this.handleChange}
                  onCut={props.onCopyPasteCut()}
                  onCopy={props.onCopyPasteCut()}
                  onPaste={props.onCopyPasteCut()}
                  validators={["required", "isValidPassword"]}
                  errorMessages={[
                    "This field is required.",
                    "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <label className={"addUserlabels"}>Confirm Password</label>
                <br />
                <TextValidator
                  name="confirmPassword"
                  type="password"
                  inputProps={{
                    className: "addUsertextboxs2",
                    style: { padding: "12px 0px 12px 12px" },
                  }}
                  variant="outlined"
                  value={confirmPassword}
                  placeholder="Confirm password..."
                  autoComplete="off"
                  onChange={props.onChange()}
                  onCut={props.onCopyPasteCut()}
                  onCopy={props.onCopyPasteCut()}
                  onPaste={props.onCopyPasteCut()}
                  validators={["required", "isMatch"]}
                  errorMessages={[
                    "This field is required.",
                    "Password doesn't match.",
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <br />
                <LinkButton
                  component={Link}
                  to={ROUTES.USERS}
                  color="secondary"
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    pointerEvents: loading ? "none" : "all",
                  }}
                >
                  Cancel
                </LinkButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                {loading === false ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className={"addUseraddButton"}
                  >
                    Add User
                  </Button>
                ) : (
                  <CircularProgress style={{ marginLeft: "120px" }} />
                )}
              </Grid>
            </Grid>
            <br />
            <br />
          </ValidatorForm>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={4000}
          open={isAddSuccess}
          onClose={props.onhandleClose()}
          key={1}
        >
          <Alert onClose={props.onhandleClose()} severity={severity}>
            {messageAlert}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default UserForm;
