import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";

import UserEditFunction from "./userEditFunction";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../Session/withAuthorization";

import Navigation from "../../Navigation";

const UserAddPage = () => {
  return <UserAddForm />;
};

class UserAddPageBase extends Component {
  render() {
    return (
      <>
        <Navigation />
        <UserEditFunction props={this.props} />
        <center style={{ marginTop: 40, marginBottom: 10 }}>
          <Typography variant="caption">
            Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
            reserved.
          </Typography>
        </center>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const UserAddForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(UserAddPageBase);

export default UserAddPage;

export { UserAddForm };
