import axios from "axios";
import * as ROUTES from "../../../../constants/routes";
import Helpers from "../../../Helpers";

export default class CategoryAddFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doCategoryAdd = (catName, catDesc, catCode) => {
    const token = this.helpers.getCookie("token");
    catDesc = this.helpers.whiteSpaceToStringConverter(catDesc);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(
        "req",
        `{"name": "${catName}",\n "description": "${catDesc}",\n "code": "${catCode}"}`
      );
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/createNewCategory",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
