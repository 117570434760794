import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import Navigation from "../Navigation";
import Helpers from "../Helpers";
import firebase from "firebase/compat/app";
import moment from "moment";

import CustomDataTable from "../CustomDataTable";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  FormLabel,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import SaveAltIcon from "@material-ui/icons/SaveAlt";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

const RecentUpload = () => {
  return <Navigation content={<RecentUploadPage />} />;
};

class RecentUploadBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "fileName",
          label: "File Name",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "uploader",
          label: "Uploaded By",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "dateUploaded",
          label: "Date Uploaded",
          options: {
            filter: true,
            filterType: "custom",
            customFilterListOptions: {
              render: (v) => {
                if (v[0] && v[1]) {
                  return `Min Date: ${v[0]}, Max Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Date: ${v[1]}`;
                }
                return false;
              },
              update: (filterList, filterPos, index) => {
                console.log(
                  "customFilterListOnDelete: ",
                  filterList,
                  filterPos,
                  index
                );

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, "");
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(dateUploaded, filters) {
                let filter1, filter2;
                dateUploaded = new moment(dateUploaded);

                if (filters[0] !== undefined) filter1 = new moment(filters[0]);

                if (filters[1] !== undefined) filter2 = new moment(filters[1]);
                if (filter1 && filter2) {
                  return (
                    moment(dateUploaded).isBefore(filter1) ||
                    moment(dateUploaded).isAfter(filter2)
                  );
                } else if (filter1) {
                  return moment(dateUploaded).isBefore(filter1);
                } else if (filter2) {
                  return moment(dateUploaded).isAfter(filter2);
                }
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Date Uploaded</FormLabel>
                  <FormGroup row>
                    <span>Earliest</span>
                    <TextField
                      value={filterList[index][0] || ""}
                      type="date"
                      onChange={(event) => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                    />
                    <span>Latest</span>
                    <TextField
                      value={filterList[index][1] || ""}
                      type="date"
                      onChange={(event) => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </FormGroup>
                </div>
              ),
            },
            print: false,
          },
        },
        {
          name: "action",
          label: "Actions",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <IconButton
                    onClick={(e) => {
                      this.downloadExcel(e, tableMeta.rowData);
                    }}
                  >
                    <SaveAltIcon style={{ color: "#256141" }} />
                  </IconButton>
                </>
              );
            },
          },
        },
      ],
      option: {
        filter: true,
        filterType: "multiselect",
        responsive: "standard",
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        selectableRows: false,
        download: false,
        //serverSide: true,
        rowsPerPageOptions: [10],
        onTableChange: (action, tableState) => {
          //console.log(action, tableState);
        },
        onFilterChange: function (columnId, value) {},
        sortOrder: {
          name: "dateUploaded",
          direction: "desc",
        },
        setFilterChipProps: (colIndex, colName, data) => {
          console.log(colIndex, colName, data);
          return {
            color: "primary",
            variant: "outlined",
          };
        },
      },
      listing: [],
      type: "",
      deleteModalOpen: false,
      deleteLoading: false,
      currentSelectedRowIndex: -1,
      currentSelectedRow: {},
    };
    this.storage = firebase.storage().ref();
    this.helpers = new Helpers();
  }

  componentDidMount = () => {
    let type = new URLSearchParams(this.props.location.search).get("type");
    this.setState({ type: type });
    this.getRecords(type);
  };

  componentDidUpdate = () => {
    let type = new URLSearchParams(this.props.location.search).get("type");

    if (type !== null && this.state.type !== type) {
      //console.log(`${this.state.type} + ${type}`);
      this.setState({ type: type });
      this.getRecords(type);
    }
  };

  getRecords = (type) => {
    let list = [];

    return (
      this.props.firebase.db
        .collection("uploadRecord")
        .where("type", "==", type)
        //.orderBy('dateUploaded', 'desc')
        .get()
        .then((result) => {
          result.forEach((doc, index) => {
            let date = new moment.unix(doc.data().dateUploaded.seconds).format(
              "MM/DD/YYYY, HH:mm"
            );
            let obj = {
              fileName: doc.data().fileName,
              uploader: doc.data().uploadedBy,
              dateUploaded: date,
              action: doc.data().location,
              id: doc.id,
            };
            list.push(obj);
          });
          return this.setState({ listing: list });
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };

  downloadExcel = (e, value) => {
    e.preventDefault();
    const storageRef = firebase.storage().ref();
    storageRef
      .child(value[3])
      .getDownloadURL()
      .then((url) => {
        window.open(url, "_blank");
      })
      .catch((error) => {
        alert(error);
      });
  };

  openDeleteDialog = (e, value) => {
    e.preventDefault();
    this.setState({
      deleteModalOpen: true,
      currentSelectedRow: value.rowData,
      currentSelectedRowIndex: value.rowIndex,
    });
  };

  deleteExcel = (e, value) => {
    e.preventDefault();
    let listing = this.state.listing;
    console.log(value);

    console.log(listing);

    let index = listing.findIndex((element, index) => {
      return (
        value[0] === element.fileName &&
        value[1] === element.uploader &&
        value[2] === element.dateUploaded
      );
    });
    let item = listing[index];
    listing.splice(index, 1);

    const storageRef = firebase.storage().ref();
    return this.setState({ deleteLoading: true }, () => {
      let arrPromise = [];

      arrPromise.push(storageRef.child(value[3]).delete());

      arrPromise.push(
        this.props.firebase.db.collection("uploadRecord").doc(item.id).delete()
      );

      return Promise.all(arrPromise)
        .then(() => {
          this.setState({ deleteModalOpen: false, deleteLoading: false });
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    });
  };

  render = () => {
    const {
      columns,
      option,
      listing,
      deleteModalOpen,
      currentSelectedRow,
      deleteLoading,
      type,
    } = this.state;

    let deleteModal = (
      <div>
        <Dialog
          open={deleteModalOpen}
          onClose={() => {
            this.setState({ deleteModalOpen: false });
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete selected Excel file?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Selected file:</b>
              {` ${currentSelectedRow[0]}`}
            </DialogContentText>
            <DialogContentText>
              <b>Uploaded By:</b>
              {` ${currentSelectedRow[1]}`}
            </DialogContentText>
          </DialogContent>
          {deleteLoading ? <LinearProgress /> : ""}
          <DialogActions>
            <Button
              onClick={(e) => this.deleteExcel(e, currentSelectedRow)}
              color="primary"
              autoFocus
              variant="contained"
              disabled={deleteLoading}
            >
              <span style={{ color: "white" }}>Yes</span>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    let title = "List of ";

    if (type === "redemption") title += "Redemptions";
    else title += "Bookings";

    return (
      <>
        {deleteModal}
        <div className="cms-main-content">
          <div className="body-content">
            <div className="transaction-list">
              <h1>{title}</h1>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomDataTable
                    title={"Excel List"}
                    data={listing}
                    columns={columns}
                    options={option}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  };
}

const condition = (authUser) => !!authUser;

const RecentUploadPage = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(RecentUploadBase);

export default RecentUpload;
export { RecentUploadPage };
