import React from "react";

import formStyle from "./css/editUser.module.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

import {
  Grid,
  TextField,
  Fade,
  FormHelperText,
  FormControl,
  Select,
  InputAdornment,
  OutlinedInput,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

import LinkButton from "@material-ui/core/Link";

import { makeStyles } from "@material-ui/core/styles";
// import { StylesProvider } from '@material-ui/core/styles'
// import AlertBox from '../../AlertBox'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  margin: {
    marginTop: "10px",
  },
  field: {
    padding: "12px 0px 12px 12px",
  },
}));

const UserEditForm = (props) => {
  const classes = useStyle();

  const {
    firstName,
    lastName,
    mobileNumber,
    userType,
    status,
    firstNameError,
    lastNameError,
    mobileNumberError,
    toggleAlert,
    severity,
    messageAlert,
    loading,
    isEditSuccess,
  } = props.state;

  return (
    <>
      <div className={formStyle.root}>
        <form onSubmit={props.onSubmit()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label className={formStyle.labels1}>Edit User</label>
              {toggleAlert && (
                <Alert className={formStyle.alertWidth} severity={severity}>
                  {messageAlert}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={formStyle.labels}>First Name</label>
              <br />
              <TextField
                name="firstName"
                className={classes.margin}
                inputProps={{
                  className: formStyle.textboxs,
                  style: { padding: "12px 15px 12px 12px" },
                }}
                variant="outlined"
                value={firstName}
                onChange={props.onChange()}
                placeholder="Your First Name..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {firstNameError}
                </FormHelperText>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={formStyle.labels}>Last Name</label>
              <br />
              <TextField
                name="lastName"
                className={classes.margin}
                inputProps={{
                  className: formStyle.textboxs,
                  style: { padding: "12px 0px 12px 12px" },
                }}
                variant="outlined"
                value={lastName}
                onChange={props.onChange()}
                placeholder="Your Last Name..."
                autoComplete="off"
                required
              />
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {lastNameError}
                </FormHelperText>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <label className={formStyle.labels}>Mobile Number</label>
              <br />
              <FormControl>
                <OutlinedInput
                  name="mobileNumber"
                  value={mobileNumber}
                  className={classes.margin}
                  inputProps={{
                    className: formStyle.textboxs1,
                    maxLength: 10,
                    style: { padding: "12px 0px 12px 0px" },
                  }}
                  required
                  autoComplete="off"
                  startAdornment={
                    <InputAdornment position="start">
                      <label className={formStyle.textboxs1Text}>+63</label>
                    </InputAdornment>
                  }
                  onChange={props.onMobileNumKeyUp()}
                />
              </FormControl>
              <Fade in={true}>
                <FormHelperText style={{ color: "red" }}>
                  {mobileNumberError}
                </FormHelperText>
              </Fade>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={formStyle.labels}>User Type</label>
              <br />
              <FormControl variant="outlined" size="small">
                <Select
                  native
                  name="userType"
                  value={userType}
                  onChange={props.onChange()}
                  className={classes.margin}
                  inputProps={{
                    className: formStyle.selectboxs,
                    style: { padding: "12px 32px 12px 12px" },
                  }}
                >
                  {["Admin", "RM/IM"].map((item, index) => (
                    <option key={index} value={index + 1}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label className={formStyle.labels}>Status</label>
              <br />
              <FormControl variant="outlined" size="small">
                <Select
                  native
                  name="status"
                  value={status}
                  onChange={props.onChange()}
                  className={classes.margin}
                  inputProps={{
                    className: formStyle.selectboxs,
                    style: { padding: "12px 18px 12px 12px" },
                  }}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <br />
              <br />
              <label className={formStyle.labels2}>Login info</label>
            </Grid>
            <Grid item xs={12} sm={3}>
              <br />
              <LinkButton
                component={Link}
                to={ROUTES.USERS}
                color="secondary"
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  pointerEvents: loading ? "none" : "all",
                }}
              >
                Cancel
              </LinkButton>
            </Grid>
            <Grid item xs={12} sm={3}>
              {loading === false ? (
                <Button
                  type="submit"
                  variant="contained"
                  className={formStyle.addButton}
                >
                  Save Changes
                </Button>
              ) : (
                <CircularProgress style={{ marginLeft: "120px" }} />
              )}
            </Grid>
          </Grid>
        </form>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={isEditSuccess}
        onClose={props.onhandleClose()}
        key={1}
      >
        <Alert onClose={props.onhandleClose()} severity={severity}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserEditForm;
