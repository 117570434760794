import axios from "axios";
import * as ROUTES from "../../../../constants/routes";
import Helpers from "../../../Helpers";

export default class CompanyAddEditFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doCompanyAdd = (compObj, compIcon) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("req", JSON.stringify(compObj));
      formData.append("icon", compIcon[0]);
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/createFundCompany",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doCompanyEdit = (compObj, compIcon) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      var icon = !compIcon[0] ? "" : compIcon[0];

      const formData = new FormData();
      formData.append("req", JSON.stringify(compObj));
      formData.append("icon", icon);
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/editFundCompany",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
