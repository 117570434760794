import React, { Component } from "react";

import CompanyAddEditFunction from "./companyAddEditFunction";
import { withFirebase } from "../../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../../Session/withAuthorization";

import Navigation from "../../../Navigation";

const CompanyAddEditPage = () => {
  return <Navigation content={<CompanyAddEditForm />} />;
};

class CompanyAddEditPageBase extends Component {
  render() {
    return (
      <>
        <CompanyAddEditFunction props={this.props} />
        <label
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const CompanyAddEditForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(CompanyAddEditPageBase);

export default CompanyAddEditPage;

export { CompanyAddEditForm };
