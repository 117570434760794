import React from "react";

import signinStyle from "./css/signin.module.css";
import logo from "../../images/logo.png";

import {
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Fade,
  FormHelperText,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import AlertBox from "../Alert";

import { withCookies } from "react-cookie";

const SigninFront = (props) => {
  var buttonHide;

  const {
    email,
    password,
    showPass,
    emailError,
    passwordError,
    toggleAlert,
    alertType,
    messageAlert,
    loading,
  } = props.state;

  if (!loading) {
    buttonHide = (
      <Button variant="contained" size="large" color="primary" type="submit">
        Sign In
      </Button>
    );
  } else {
    buttonHide = <CircularProgress />;
  }

  return (
    <div className={signinStyle.loginBg}>
      <div className={signinStyle.login}>
        <div className={signinStyle.left}>
          <img src={logo} alt="logo" />
          <br />
          <br />
          <h2 className={signinStyle._title}>Welcome to Rampver</h2>
          <br />
          {toggleAlert && (
            <AlertBox severity={alertType} message={messageAlert} />
          )}
          <form onSubmit={props.onhandleSubmit()} name="form">
            <TextField
              name="email"
              type="text"
              label="Email Address"
              placeholder="Enter your email address..."
              fullWidth
              margin="normal"
              autoComplete="off"
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              value={email}
              variant="outlined"
              helperText={emailError}
              onChange={props.onhandleFormChange()}
              error={emailError !== "" ? true : false}
            />
            {/* <TextField
                            name="password"
                            label="Password"
                            placeholder="Enter your password..."
                            fullWidth
                            margin="normal"
                            value={password}
                            type={showPass ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={props.onClickShowPassword()}
                                    onMouseDown={props.onMouseDownPassword()}
                                  >
                                    {showPass ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            onChange={props.onhandleFormChange()}
                            InputLabelProps={{
                                    shrink: true,
                            }}
                            helperText={passwordError}
                            variant="outlined"
                            error={passwordError !== '' ? true : false}
                        /> */}
            <FormControl fullWidth>
              <OutlinedInput
                name="password"
                value={password}
                inputProps={{ style: { padding: "12px 0px 12px 12px" } }}
                required
                placeholder="Password"
                autoComplete="off"
                type={showPass ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={props.onClickShowPassword()}
                      onMouseDown={props.onMouseDownPassword()}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={props.onhandleFormChange()}
              />
            </FormControl>
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {passwordError}
              </FormHelperText>
            </Fade>
            <br /> <br />
            {buttonHide}
          </form>
          {/* <br/> <br/>
                    <div className={signinStyle.flex}>
                        <label className={signinStyle.signWith}>or sign in with</label>
                        <div className="border"></div>
                    </div>
                    <br/>
                    <div className={signinStyle.btnGoogle}>
                        <img src={google} alt="google logo"/>
                        <label>Google</label>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default SigninFront;
