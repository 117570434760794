export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

// removes all whitespace in text
export const trimAllWhiteSpace = (text: string) => {
  if (!text) return "";

  return text.replace(/\s/g, "");
};

export const removeSpecialCharactersFromString = (text: string) => {
  if (!text) return "";

  return text.replace(/[^a-zA-Z0-9]/g, "");
};
