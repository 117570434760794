import React, { Component } from "react";

import FundAddEditFunction from "./fundAddEditFunction";
import { withFirebase } from "../../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../../Session/withAuthorization";

import Navigation from "../../../Navigation";

const FundAddEditPage = () => {
  return <Navigation content={<FundAddEditForm />} />;
};

class FundAddEditPageBase extends Component {
  render() {
    return (
      <>
        <FundAddEditFunction props={this.props} />
        <label
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const FundAddEditForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(FundAddEditPageBase);

export default FundAddEditPage;

export { FundAddEditForm };
