import React, { Component } from "react";

import CategoriesAddFunction from "./categoriesAddFunction";
import { withFirebase } from "../../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../../Session/withAuthorization";

import Navigation from "../../../Navigation";

const CategoriesAddPage = () => {
  return <Navigation content={<CategoriesAddForm />} />;
};

class CategoriesAddPageBase extends Component {
  render() {
    return (
      <>
        <CategoriesAddFunction props={this.props} />
        <label
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const CategoriesAddForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(CategoriesAddPageBase);

export default CategoriesAddPage;

export { CategoriesAddForm };
