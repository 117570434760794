import Button from "./Button";
import { useHistory } from "react-router-dom";

export default function LoadingPage() {
  const history = useHistory();
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center">
      <div className="text-center">
        <span className="font-bold text-9xl">500</span>
        <h1 className="font-semibold mt-12">Internal Server Error</h1>
        <p className="mt-8 max-w-lg">
          Oops, something went wrong. Try to refresh this page or feel free to
          contact us if the problem persists
        </p>
        <div className="mt-12">
          <Button onClick={() => history.go(0)}>Reload the page</Button>
        </div>
      </div>
    </div>
  );
}
