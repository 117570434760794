import React from "react";
import ReactDOM from "react-dom";
import { NotificationProvider } from "@/context/NotificationContext";

import "./index.css";
import App from "./components/App";
// import App from './components/SignIn';

import reportWebVitals from "./reportWebVitals";
import Firebase, { FirebaseContext } from "./components/Firebase";

import { ThemeProvider, createMuiTheme } from "@material-ui/core";

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: "#C8932A",
    },
    secondary: {
      main: "#f05454",
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif`,
  },
  ListItemText: {
    fontFamily: `"Poppins", sans-serif`,
  },
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={THEME}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
