import { ValidatorForm } from "react-material-ui-form-validator";
import { convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";
import Firebase from "../Firebase/firebase";

export default class Helpers {
  constructor() {
    this.firebase = new Firebase();
  }

  whiteSpaceToStringConverter = (text) => {
    let returnText = text.replace(/\n/g, "/n ");
    return returnText;
  };

  stringToWhiteSpaceConverter = (text) => {
    let returnText = text.replace(/\/n /gi, `\n`);
    return returnText;
  };

  timeStampFormatter = (timeStamp) => {
    var d = new Date(timeStamp.toDate());
    var dateString = timeStamp.toDate().toDateString();

    var hours = d.getHours();
    var minutes = d.getMinutes();
    var time = this.tConvert(hours, minutes);

    return `${dateString} ${time}`;
  };

  tConvert = (hours, minutes) => {
    var AmOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var finalTime =
      hours + ":" + (minutes > 9 ? minutes : `${0}${minutes}`) + " " + AmOrPm;

    return finalTime;
  };

  formatToPHP = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
    });

    return formatter.format(value);
  };

  formatToNavps = (value) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 4,
    });

    return formatter.format(value);
  };

  numberCommaSeparated = (value) => {
    const formatter = new Intl.NumberFormat("en-US");

    return formatter.format(value);
  };

  dateFormat = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  wysiwgyValidator = () => {
    ValidatorForm.addValidationRule("isContentReq", (value) => {
      var fieldVal = draftToMarkdown(convertToRaw(value.getCurrentContent()));

      return !this.isEmtpy(fieldVal.trim());
    });
  };

  isExisty = (value) => {
    return value !== null && value !== undefined;
  };

  isEmtpy = (value) => {
    if (value instanceof Array) {
      return value.length === 0;
    }

    return value === "" || !this.isExisty(value);
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  handleError = (error) => {
    const status = error.response.status;
    if (status === 401 || status === 403) {
      if (status === 401) window.location.href = "/invalid";
      else alert(error.response.data.message);
    }
  };
}
