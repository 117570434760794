import moment from "moment";
import React, { useMemo } from "react";
import Navigation from "../Navigation";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Input,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CustomDataTable from "../CustomDataTable";
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import Helpers from "../Helpers";
import * as ROUTES from "../../constants/routes";
import { Alert } from "@material-ui/lab";

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { ExportApprovalRecurringData } from "./Download";

const RecurringInvestmentPage = (props) => {
  return <Navigation content={<RecurringInvestmentBase props={props} />} />;
};

const AmountEdit = ({ amount, updateAmount, id }) => {
  const [editable, setEditable] = useState(false);
  const [displayAmt, setDisplayAmt] = useState(amount);

  const [loading, setLoading] = useState(false);

  const cancelEdit = () => {
    setDisplayAmt(amount);
    setEditable(false);
  };

  const handleTextAreaChange = (event) => {
    const input = event.target.value;
    if (/^\d*$/.test(input) && input > 0) {
      setDisplayAmt(input);
    }
  };

  const onAccept = async () => {
    try {
      setLoading(true);
      await updateAmount({
        newAmount: parseFloat(displayAmt),
        id,
      });
      setEditable(false);
      setLoading(false);
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  return (
    <>
      {editable ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Input
            value={displayAmt}
            onChange={handleTextAreaChange}
            style={{ width: "100px" }}
            type="number"
            error={displayAmt.length <= 0}
          />
          <IconButton
            onClick={() => cancelEdit()}
            disabled={loading}
            size="small"
          >
            <CloseIcon style={{ color: "red" }} />
          </IconButton>
          <IconButton
            onClick={() => onAccept()}
            size="small"
            disabled={loading || displayAmt <= 0}
          >
            <CheckIcon style={{ color: "green" }} />
          </IconButton>
        </div>
      ) : (
        <>
          <span>{parseFloat(displayAmt).toLocaleString()}</span>
          <IconButton onClick={() => setEditable(true)} size="small">
            <EditIcon style={{ color: "#C8932A" }} />
          </IconButton>
        </>
      )}
    </>
  );
};

const DayEdit = ({ day, updateDate, id }) => {
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayDate, setDisplayDate] = useState(day);
  const [openModal, setOpenModal] = useState(false);

  const cancelEdit = () => {
    setDisplayDate(day);
    setEditable(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmAccept = async () => {
    setOpenModal(false);
    try {
      setLoading(true);
      await updateDate({
        newDate: parseInt(displayDate),
        id,
      });
      setEditable(false);
      setLoading(false);
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  return (
    <>
      {editable ? (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Select
            fullWidth
            value={displayDate}
            onChange={(e) => setDisplayDate(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={16}>16</MenuItem>
          </Select>

          <IconButton
            onClick={cancelEdit}
            disabled={loading}
            size="small"
          >
            <CloseIcon style={{ color: 'red' }} />
          </IconButton>
          <IconButton
            onClick={handleOpenModal}
            size="small"
            disabled={loading}
          >
            <CheckIcon style={{ color: 'green' }} />
          </IconButton>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexGrow: 1 }}>
          <span>{day}</span>
          <IconButton onClick={() => setEditable(true)} size="small">
            <EditIcon style={{ color: '#C8932A' }} />
          </IconButton>
        </div>
      )}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update the date to {displayDate}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAccept} color="primary" disabled={loading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


const RecurringInvestmentBase = ({ props }) => {
  const [recurringData, setRecurringData] = useState([]);
  const [parameter, setParameter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");
  useEffect(() => {
    getInvestment();
    setParameter(
      new URLSearchParams(props.location.search).get("status") ?? null
    );
  }, []);

  async function getInvestment() {
    try {
      const data = await props.firebase.db
        .collection("investmentsRecurring")
        .get();
      const list = data.docs.map((obj) => {
        let objData = obj.data();
        objData.id = obj.id;
        objData.userName = `${objData.userFirstName} ${objData.userLastName}`;
        return objData;
      });

      const exportData = await Promise.all(
        list
          .filter((item) => item.status === "processing")
          .map(async (item) => {
            try {
              const data = await props.firebase.db
                .collection("users")
                .doc(item.userUid)
                .collection("accountDetails")
                .doc("AD")
                .get();

              return {
                amount: item.amount,
                userAccount: item.userAccountNumber,
                registrationDate: moment
                  .unix(item.dateCreated?.seconds)
                  .format("MM/DD/YYYY h:mm a"),
                userName: item.userName,
                fundName: item.fundName,
                fundCode: item.transactionData.fundDetails.fundCode,
                status: item.status,
                accountName: data.data()?.accountName ?? 'n/a',
                bankName: data.data()?.bankName ?? 'n/a',
                bankNumber: data.data()?.bankAccountNumber ?? 'n/a',
                userEmail: item.transactionData.clientDetails.userEmail,
                dayofMonth: item.day,
              };
            } catch (err) {
              throw new Error(err);
            }
          })
      );

      setExportData(exportData);
      setRecurringData(list);
    } catch (err) {
      alert(err);
    }
  }

  async function updateAmountValue(payload) {
    setIsLoading(true);
    try {
      const helper = new Helpers();
      const token = helper.getCookie("token");
      await axios.post(
        ROUTES.FUNCTIONAL_BASE_URL +
        "investments-recurring-recurringInvestments/update-recurring-amount",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getInvestment();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function updateDateValue(payload) {
    setIsLoading(true);
    try {
      const helper = new Helpers();
      const token = helper.getCookie("token");
      await axios.post(
        ROUTES.FUNCTIONAL_BASE_URL +
        "investments-recurring-recurringInvestments/update-recurring-date",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getInvestment();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  async function changeApprovalStatus(docId, status) {
    setIsLoading(true);
    try {
      const statusObj = {
        investmentId: docId,
        status,
      };
      const helper = new Helpers();
      const token = helper.getCookie("token");
      await axios.post(
        ROUTES.FUNCTIONAL_BASE_URL +
        "investments-recurring-recurringInvestments/update-client-recurring",
        statusObj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getInvestment();
      setSnackMsg(`Investment set as: ${status}`);
      setSnackOpen(true);
      setIsLoading(false);
    } catch (err) {
      alert(err);
      setSnackMsg(``);
      setSnackOpen(false);
      setIsLoading(false);
    }
  }

  async function changeEnabledStatus(docId, status) {
    setIsLoading(true);
    try {
      const statusObj = {
        id: docId,
        status: status === true ? "active" : "inactive",
      };
      const helper = new Helpers();
      const token = helper.getCookie("token");
      await axios.post(
        ROUTES.FUNCTIONAL_BASE_URL +
        "investments-recurring-recurringInvestments/update-recurring-status",
        statusObj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getInvestment();
      setSnackMsg(`Investment set as: ${status}`);
      setSnackOpen(true);
      setIsLoading(false);
    } catch (err) {
      alert(err);
      setSnackMsg(``);
      setSnackOpen(false);
      setIsLoading(false);
    }
  }

  const columns = useMemo(
    () => [
      {
        name: "dateCreated",
        label: "Register Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment.unix(value.seconds).format("MM/DD/YYYY h:mm a");
          },
        },
      },
      {
        name: "userAccountNumber",
        label: "Account Number",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "userName",
        label: "User Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "transactionData",
        label: "Email Address",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return value.clientDetails.userEmail;
          },
        },
      },

      {
        name: "fundName",
        label: "Fund Name",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "amount",
        label: "Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <AmountEdit
              amount={value}
              id={tableMeta.rowData[9]}
              updateAmount={updateAmountValue}
            />
          ),
        },
      },

      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          ...(parameter !== null ? { filterList: [parameter] } : null),
        },
      },
      {
        name: "day",
        label: "Day of the Month",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <DayEdit day={value} id={tableMeta.rowData[9]} updateDate={updateDateValue}/>
          )
        },
      },
      {
        name: "id",
        label: "Approval Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const status = tableMeta.rowData[6];
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {status !== "processing" ? (
                  <span>No actions available</span>
                ) : (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      style={{
                        backgroundColor: "#256141",
                        color: "white",
                        marginRight: "5px",
                      }}
                      onClick={() => changeApprovalStatus(value, "active")}
                      disabled={isLoading}
                    >
                      Approve
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ backgroundColor: "#C62828", color: "white" }}
                      onClick={() => changeApprovalStatus(value, "declined")}
                      disabled={isLoading}
                    >
                      Decline
                    </Button>
                  </>
                )}
              </div>
            );
          },
        },
      },
      {
        name: "id",
        label: "Status Actions",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const status = tableMeta.rowData[6];
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={status === "active"}
                      onChange={(e) =>
                        changeEnabledStatus(value, e.target.checked)
                      }
                      color="primary"
                      disabled={!["active", "inactive"].includes(status)}
                    />
                  }
                  label={status === "active" ? "Active" : "Inactive"}
                />
              </div>
            );
          },
        },
      },
    ],
    [parameter]
  );

  const options = useMemo(() => {
    return {
      filterType: "checkbox",
      responsive: "standard",
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      selectableRows: false,
      download: false,
      print: false,
      sortOrder: {
        name: "dateCreated",
        direction: "desc",
      },
      rowsPerPageOptions: [50, 100, 200],
      rowsPerPage: 100,
    };
  }, []);

  const loader = (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 999,
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <div className="cms-main-content">
      <div className="body-content">
        <h1>Recurring Investments</h1>
        <span>Listing of recurring investments</span>
        <ExportApprovalRecurringData
          data={exportData}
          button={
            <Button
              disabled={exportData.length === 0}
              variant="contained"
              color="primary"
              style={{
                width: "200px",
                height: "45px",
                color: "white",
                padding: "15px 5px 15px 5px",
                marginTop: "28px",
                marginBottom: "28px",
                position: "absolute",
                right: "40px",
                top: "120px",
                textTransform: "capitalize",
              }}
            >
              Generate Processing Data
            </Button>
          }
          filename={`Recurring_Approval - ${moment().format(
            "MM/DD/YY - hh:mm a"
          )}`}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div style={{ position: "relative" }}>
              {isLoading && loader}
              <CustomDataTable
                title={"Recurring Transactions List"}
                data={recurringData}
                columns={columns}
                options={options}
              />
            </div>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={3000}
          onClose={() => setSnackOpen(false)}
        >
          <Alert onClose={() => setSnackOpen(false)} severity="success">
            {snackMsg}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

const RecurringInvestment = compose(
  withAuthorization((authUser) => !!authUser),
  withRouter,
  withFirebase
)(RecurringInvestmentPage);

export default RecurringInvestment;
