/*
  To see information about the modal component, go to this link: https://v4.mui.com/components/modal/#modal
*/

import Navigation from "./Navigation";
import CustomDataTable from "./CustomDataTable";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import * as ROUTES from "../constants/routes";
import { Link, useHistory } from "react-router-dom";
import Button from "./Button";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./Firebase/firebase";
import { useEffect, useState } from "react";
import { copyTextToClipboard } from "@/utils";
import { CLIENT_BASE_URL } from "@/constants/routes";
import { Referrer } from "@/types";
import withAuthentication from "@/hoc/withAuthentication";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import useNotify from "@/hooks/useNotify";

const COLUMNS = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: false,
    },
  },
  {
    name: "email",
    label: "Email",
    options: {
      filter: false,
    },
  },
  {
    name: "number",
    label: "Number",
    options: {
      filter: false,
    },
  },
  {
    name: "referralLink",
    label: "Referral Link",
    options: {
      filter: false,
    },
  },
  {
    name: "",
    label: "",
    options: {
      filter: false,
    },
  },
];

const ReferrersPage = () => {
  const [managers, setManagers] = useState<Referrer[]>([]);
  const [isManagersLoading, setIsManagersLoading] = useState(true);
  const [isManagersError, setIsManagersError] = useState(false);
  const history = useHistory();
  const notify = useNotify();

  useEffect(() => {
    // fetch users with role manager
    (async () => {
      try {
        // if parentUserId is null, then it is a manager
        const q = query(
          collection(db, "referrers"),
          where("parentUserId", "==", null)
        );

        const querySnapshot = await getDocs(q);
        const managers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Referrer[];

        setManagers(managers);
      } catch {
        setIsManagersError(false);
      } finally {
        setIsManagersLoading(false);
      }
    })();
  }, []);

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    customToolBar: () => <div>sadsdasad</div>,
    selectableRowsHideCheckboxes: true,
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData: any, rowMeta: { dataIndex: number }) => {
      // id of the manager that was clicked
      const managerId = managers[rowMeta.dataIndex].id;

      history.push({
        pathname: `/referrers/profiles/${managerId}`,
      });
    },
  };

  if (isManagersLoading) return <LoadingPage />;
  if (isManagersError) return <ErrorPage />;

  const managersData = managers.map((manager) => ({
    name: `${manager.firstName} ${manager.lastName}`,
    email: manager.email,
    number: manager.phone,
    referralLink: (
      <IconButton
        aria-label="referral link"
        onClick={(e) => {
          e.stopPropagation();

          if (!manager.referralCode) {
            notify("Referral link not available for this user.", "error");
            return;
          }

          copyTextToClipboard(`${CLIENT_BASE_URL}/ref/${manager.referralCode}`);

          notify("Copied to clipboard", "success");
        }}
      >
        <LinkIcon />
      </IconButton>
    ),
  }));

  return (
    <>
      <Navigation
        content={
          <div className="p-16" style={{ marginLeft: "260px" }}>
            <h1>Referrers</h1>

            <div className="text-right">
              <Button
                component={Link}
                to={`${ROUTES.ADD_USER}?userType=Manager`}
              >
                Add New RM
              </Button>
            </div>

            <div className="mt-4">
              <CustomDataTable
                columns={COLUMNS}
                options={options}
                data={managersData}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default withAuthentication(ReferrersPage);
