import axios from "axios";
import * as ROUTES from "../../../constants/routes";
import Helpers from "../../Helpers";

export default class ClientProfileFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doApproveClient = (
    userId,
    isInvestor,
    adminComments,
    document,
    isLegacy = false
  ) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      let formatted = "";

      if (adminComments !== "")
        formatted = this.helpers.whiteSpaceToStringConverter(adminComments);

      formData.append(
        "req",
        JSON.stringify({
          useruid: userId,
          isInvestor: isInvestor,
          comments: formatted,
          isLegacy,
        })
      );

      if (isInvestor)
        if (document !== null) {
          document.forEach((item, index) => {
            formData.append("doc " + index, item.file);
          });
        }
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "/cms-admin/changeUserInvestorStatus",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doAssignManager = (userId, managerId) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "/cms-admin/assignManagerToUser",
          JSON.stringify({
            useruid: userId,
            investmentManagerId: managerId,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doEditDocuments = (toDelete, toAdd, userUid) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(
        "req",
        JSON.stringify({
          toDelete,
          userUid,
        })
      );
      if (toAdd !== null) {
        toAdd.forEach((item, index) => {
          formData.append("doc " + index, item.file);
        });
      }

      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "/cms-admin/editDocuments",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doSetValidId = async (userId, isValid) => {
    const token = this.helpers.getCookie("token");
    return axios.post(
      ROUTES.FUNCTIONAL_BASE_URL + "/cms-admin/set-id",
      JSON.stringify({
        userId,
        isValid,
      }),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
}
