import React from "react";
import { Chip, Paper } from "@material-ui/core";
import { AttachFileOutlined } from "@material-ui/icons";

function UploadFile(props) {
  var renderFiles;
  if (props.files !== null) {
    const files = [...props.files];
    renderFiles = files.map((file) => (
      <Chip
        icon={<AttachFileOutlined />}
        label={file.name}
        onDelete={() => props.deleteFile(file.name)}
        className="custom-chip-upload"
        key={file.name}
      />
    ));
  }
  return (
    <>
      <div className="upload-box">
        <input
          type="file"
          className="upload-file"
          name="picture"
          onChange={(event) => props.fileChange(event)}
          multiple
        />
        <span>Drag and Drop files here or choose file</span>
      </div>
      <div className="label-bottom">
        <span>File Supported: {props.supported}</span>
        <span>Maximum Size: {props.maxSize}</span>
      </div>
      <Paper className="custom-list-upload" component="ul">
        {props.files !== null && renderFiles}
      </Paper>
    </>
  );
}

export default UploadFile;
