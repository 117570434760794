import { Box, Grid, makeStyles, IconButton, Paper, CircularProgress } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PeopleIcon from '@material-ui/icons/People';
import Helpers from "../Helpers";
import axios from "axios";
import * as ROUTES from "../../constants/routes";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  summaryHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#256141',
    padding: '5px 10px',
    color: 'white',
    fontSize: '14px',
    marginBottom: '10px',
  },
  summaryBox: {
    padding: '2px',
  },
  paper: {
    padding: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const useClientGraph = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchData = useCallback(async () => {
    setLoading(true);

    const url = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/client-graph`;
    try {
      const response = await axios.get(url);
      if (response.data.ok) {
        setData(response.data.data);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array means this callback is stable and won't change

  useEffect(() => {
    fetchData();
  }, [fetchData]); // Run fetchData once on mount

  return { data, loading, refresh: fetchData };
};

export const useClientDataFetch = () => {
  const [data, setData] = useState({
    tier1: 0,
    tier2: 0,
    tier3: 0,
    tier2AUM: 0,
    identity: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const url = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/clients-breakdown`;
    try {
      const response = await axios.get(url);
      if (response.data.ok) {
        setData({
          tier1: response.data.data.tier1,
          tier2: response.data.data.tier2,
          tier3: response.data.data.tier3,
          tier2AUM: 0,
          identity: 0
        });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, refresh: fetchData };
};

export const useAUMGraphFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);

    const url = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/aum-graph`;
    try {
      const response = await axios.get(url);
      if (response.data.ok) {
        setData(response.data.data);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, refresh: fetchData };
};

export const useAUMFetch = () => {
  const [data, setData] = useState({
    tier2AUM: 0,
    identity: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const helpers = new Helpers();
    const url = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/aum-breakdown`;
    try {
      const response = await axios.get(url);
      if (response.data.ok) {
        setData({
          tier2AUM: helpers.formatToPHP(response.data.data.tier2),
          identity: helpers.formatToPHP(response.data.data.tier3),
        });
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const calculateAUM = async () => {
    setLoading(true);
     const calculateUrl = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/calculate-aum-tier`;
     try {
       const response = await axios.get(calculateUrl);
       if (response.data.ok) {
        fetchData();
       }
     } catch (error) {
         console.error(error);
     } finally {
       setLoading(false);
     }
  }
  useEffect(() => {
      const timer = setTimeout(() => {
        calculateAUM();
      }, 3000);

      return () => clearTimeout(timer);
  }, [fetchData]); // Run fetchData once on mount

  return { data, loading, refresh: fetchData };
};

export const useSummaryFetch = () => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
 const helpers = new Helpers();
 const fetchData = async () => {
   setLoading(true);
   const url = `${ROUTES.FUNCTIONAL_BASE_URL}/cms-dashboard-dashboard/summary`;

   try {
     const { data } = await axios.get(url);
     if (data.ok) {
       setSummary({
         client: data.data.userCount,
         aum: helpers.formatToPHP(data.data.totalAUM),
         ytd: helpers.formatToPHP(data.data.totalYTDSales),
       });
     } else {
       alert(data.message);
     }
   } catch (error) {
     console.log(error);
     alert(error);
   } finally {
     setLoading(false);
   }
 };


 const calculateSummary = async () => {
   const apiUrl = `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/calculate-assets`;
   const token = helpers.getCookie("token");

   setLoading(true);

   try {
     const response = await axios.get(apiUrl, {
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
       },
     });

     if (response.data.ok) {
       fetchData();
     } else {
       console.error("Failed to calculate AUM", response.data);
     }
   } catch (error) {
     console.error("Error calculating AUM:", error);
   } finally {
     setLoading(false);
   }
 };
useEffect(() => {
  const timer = setTimeout(() => {
    calculateSummary();
  }, 3000);

  return () => clearTimeout(timer);
}, []);


  return { summary, loading };
};

export const SummaryBox = () => {
  const classes = useStyles();
  const { summary, loading } = useSummaryFetch();

  const summaryData = summary ? [
    { title: 'Total Approved Clients', value: summary.client, icon: <PeopleIcon color='primary' style={{ fontSize: 72 }} /> },
    { title: 'Assets Under Management', value: summary.aum, icon: <AccountBalanceIcon color='primary' style={{ fontSize: 72 }} /> },
    { title: 'YTD Sales', value: summary.ytd, icon: <TrendingUpIcon color='primary' style={{ fontSize: 72 }} /> },
  ] : [];

  return (
    <>
      <DashboardHeader title={'Summary'} />
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <div className=" text-center">
              <CircularProgress />
              <div style={{ marginTop: "10px" }}>Loading...</div>
            </div>
          </Grid>
        ) : (
          summaryData.map((item, index) => (
            <Grid item xs={4} className={classes.summaryBox}>
              <Paper key={index} className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <Box paddingLeft={'20px'}>
                      <h4>{item.title}</h4>
                      <span style={{ fontWeight: 'bold', fontSize: '42px' }}>{item.value}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={4} container justifyContent="center" alignItems="center">
                    {item.icon}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export const DashboardHeader = ({ title, date = '', enableRefresh = false, refreshDisabled = false, onRefresh }) => {
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.summaryHeader}>
        <span style={{ flexGrow: 1, padding: '3px' }}>{title}</span>
        {enableRefresh && (
          <>
            {date ? (<span style={{ marginRight: '10px' }}>As of: {date}</span>) : (<span style={{ marginRight: '10px' }}>Refresh</span>)}
            <IconButton disabled={refreshDisabled} onClick={onRefresh} aria-label="refresh" style={{ color: 'white', padding: '5px' }}>
              <RefreshIcon style={{ fontSize: '20px' }} />
            </IconButton>
          </>
        )}
      </div>
    </Paper>
  );
}