import React from "react";

import "./css/companyAddEdit.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import {
  TextField,
  Fade,
  FormHelperText,
  Button,
  CircularProgress,
  Snackbar,
  Chip,
} from "@material-ui/core";

import { AttachFile } from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CompanyAddEditForm = (props) => {
  const {
    action,
    companyCode,
    companyTitle,
    fileName,
    companyDescription,
    companyAccountLabel,
    companyCodeError,
    companyTitleError,
    companyIconError,
    companyDescriptionError,
    companyAccountLabelError,
    toggleAlert,
    messageType,
    messageAlert,
    toggleActionAlert,
    isLoading,
  } = props.state;

  return (
    <>
      <div className={"companyEditRoot"}>
        <form onSubmit={props.onSubmit()}>
          <div className={"companyEditContainer"}>
            <label className={"companyEditHeader"}>{`${action} Company`}</label>

            {toggleAlert && (
              <>
                <MuiAlert elevation={1} variant="filled" severity={messageType}>
                  {messageAlert}
                </MuiAlert>
                <br />
              </>
            )}

            <label className={"companyEditTitle"}>Company Code</label>

            <TextField
              name="companyCode"
              inputProps={{ className: "companyEditTextBox" }}
              variant="outlined"
              value={companyCode}
              onChange={props.onChange()}
              placeholder="Company code..."
              autoComplete="off"
              required
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {companyCodeError}
              </FormHelperText>
            </Fade>

            <br />
            <label className={"companyEditTitle"}>Company Name</label>

            <TextField
              name="companyTitle"
              inputProps={{ className: "companyEditTextBox" }}
              variant="outlined"
              value={companyTitle}
              onChange={props.onChange()}
              placeholder="Company name..."
              autoComplete="off"
              required
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {companyTitleError}
              </FormHelperText>
            </Fade>

            <br />
            <label className={"companyEditTitle"}>Icon</label>
            <input
              name="companyIcon"
              type="file"
              accept=".jpg,.jpeg,.png"
              className={"companyEditUpload"}
              onChange={props.onChange()}
              required={action === "Add"}
            />
            <FormHelperText style={{ color: "gray" }}>
              Min: 100x50px - Max: 200x100px (Width x Height)
            </FormHelperText>

            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {companyIconError}
              </FormHelperText>
            </Fade>

            {fileName && (
              <div>
                <Chip
                  icon={<AttachFile className={"companyEditUploadIconSize"} />}
                  label={fileName}
                  className={"companyEditUploadChip"}
                  color="secondary"
                />
              </div>
            )}
            <br />
            <label className={"companyEditTitle"}>Official Account Label</label>

            <TextField
              name="companyAccountLabel"
              inputProps={{ className: "companyEditTextBox" }}
              variant="outlined"
              value={companyAccountLabel}
              onChange={props.onChange()}
              placeholder="Rampver Financials, Inc. FAO"
              autoComplete="off"
              required
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {companyAccountLabelError}
              </FormHelperText>
            </Fade>

            <br />
            <label className={"companyEditTitle"}>Description</label>

            <TextField
              name="companyDescription"
              inputProps={{ className: "companyEditDescription" }}
              variant="outlined"
              value={companyDescription}
              onChange={props.onChange()}
              placeholder="Company description..."
              autoComplete="off"
              multiline
              rows={8}
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {companyDescriptionError}
              </FormHelperText>
            </Fade>

            <br />
            <div>
              <Button
                component={Link}
                to={ROUTES.COMPANY_LIST}
                style={{ pointerEvents: isLoading ? "none" : "all" }}
                className={"companyEditCancelButton"}
              >
                Cancel
              </Button>
              {!isLoading ? (
                <Button type="submit" className={"companyEditEditButton"}>
                  Save
                </Button>
              ) : (
                <CircularProgress className={"companyEditLoading"} />
              )}
            </div>
          </div>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleActionAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanyAddEditForm;
