import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 5, 3),
    
  },
}));

export default function ModalInstruction({ isOpen, isClose}) {
  
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={isClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.paper}>
            <h2 className=" text-center">Upload Booking/Redemption</h2>
            <Divider/>
            <div>
              <span>
                Note: The excel file must contain ONLY approved/redeemed entries
                in the status column.
              </span>
              <br />
              <span>Instructions:</span>
              <ol>
                <li>Click the upload box or drag file</li>
                <li>
                  Select Approved/Redemeed file. NOTE: Extension should be .xlsx
                </li>
                <li>Click submit</li>
              </ol>
              <span>
                The <b>Date Processed</b> column must follow the formatting{" "}
                <b>(YYYY-MM-DD)</b>.
              </span>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
