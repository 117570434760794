import { query, collection, where, limit, getDocs } from "firebase/firestore";
import { db } from "@/components/Firebase/firebase";
import { trimAllWhiteSpace } from "@/utils";

export const isReferralCodeUnique = async (referralCode: string) => {
  return new Promise((resolve, reject) => {
    const trimmedRefCode = trimAllWhiteSpace(referralCode);

    const q = query(
      collection(db, "referrers"),
      where("referralCode", "==", trimmedRefCode),
      limit(1)
    );

    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
