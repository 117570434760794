import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";
import Navigation from "../Navigation";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Snackbar, TextField, withStyles } from "@material-ui/core";
import { Edit, Search } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import CustomDataTable from "../CustomDataTable";
import moment from "moment";
import CalendarView from "./CalendarView";
import axios from "axios";
import * as ROUTES from "../../constants/routes";
import Helpers from "../Helpers";


const CssTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#C4C4C4",
            },
            "&:hover fieldset": {
                borderColor: "black",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#C8932A",
            },
        },
    },
})(TextField);

const NavpsPage = (props) => {
    return <Navigation content={<Navps />} />;
};

const NavpsBase = (props) => {
    const helpers = new Helpers();

    const token = helpers.getCookie("token");

    const [selectedYear, setSelectedYear] = useState(moment().year());
    const [selectedMonth, setSelectedMonth] = useState(moment().month());
    const [searchText, setSearchText] = useState('');

    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [rateDataList, setRateDataList] = useState([]);

    const db = props.firebase.db;

    const [chartLoading, setChartLoading] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState('');

    const handleOpenModal = (row) => {
        setModalOpen(true);
        setSelectedRow(row)
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedRow('')
    };

    const years = [2022, 2023];

    const months = [
        { value: 0, label: 'January' },
        { value: 1, label: 'February' },
        { value: 2, label: 'March' },
        { value: 3, label: 'April' },
        { value: 4, label: 'May' },
        { value: 5, label: 'June' },
        { value: 6, label: 'July' },
        { value: 7, label: 'August' },
        { value: 8, label: 'September' },
        { value: 9, label: 'October' },
        { value: 10, label: 'November' },
        { value: 11, label: 'December' },
    ];

    const columns = [
        {
            name: "fundName",
            label: "Fund Name",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "rate",
            label: "Rate",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "ytd",
            label: "YTD",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            {value === ""
                                ? "-"
                                : moment.unix(value.seconds).format("MM/DD/YYYY h:mm a")}
                        </>
                    );
                },
            },
        },
        {
            name: "action",
            label: "Actions",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <IconButton style={{ color: "#256141" }} aria-label="Edit" onClick={() => handleOpenModal(tableMeta.rowData[0])}>
                                <Edit />
                            </IconButton>
                        </>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: 'dropdown',
        responsive: 'vertical',
        sortOrder: {
            name: "fundName",
            direction: "asc",
        },
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        selectableRows: false,
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false
    };

    async function getRates() {
        try {
            setChartLoading(true)
            const rates = await db.collection('fundRates')
                .where('date', '>=', new Date(selectedYear, selectedMonth, 1))
                .where('date', '<', new Date(selectedYear, selectedMonth + 1, 1))
                .orderBy('date', 'desc')
                .get();
            const ratesList = [];
            const funds = rates.docs[0].data().fundDetails.map((x) => x.fundName);

            rates.forEach((doc) => {
                ratesList.push(doc.data());
            });

            const dataList = funds.map((fund) => {
                const details = ratesList[0].fundDetails.find((x) => x.fundName === fund)
                return {
                    fundName: fund,
                    rate: details.rate,
                    ytd: details.ytd,
                    date: details.date
                }
            })
            setRateDataList(rates);
            setTableData(dataList);
            setFilteredData(dataList)
            setChartLoading(false);
        } catch (err) {
            setChartLoading(false);
            console.log(err);
        }
    }

    async function updateItem(item) {
        try {
            let rateObj = {};

            rateDataList.forEach((doc) => {
                if (doc.id === item.id) {
                    rateObj = doc.data();
                }
            });

            const updatedFundDetails = rateObj.fundDetails.map((fundDetail) => {
                if (fundDetail.fundName === item.fundName) {
                    return {
                        ...fundDetail,
                        rate: item.rate,
                        ytd: item.ytd
                    };
                }
                return fundDetail;
            });

            const { data } = await axios.post(
                ROUTES.FUNCTIONAL_BASE_URL + 'operations-operations/update-navps',
                {
                    id: item.id,
                    fundDetails: updatedFundDetails
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            await getRates();
            return (data);
        } catch (err) {
            console.log(err.response.data.message);
            return ({ ok: false, message: err.response.data.message });
        }
    }

    useEffect(() => {
        getRates();
    }, [selectedYear, selectedMonth])

    const performSearch = (searchText, data) => {
        return data.filter(item => {
            const { fundName, rate, ytd } = item;
            if (searchText === '')
                return data;

            return (
                fundName.toLowerCase().includes(searchText.toLowerCase()) ||
                (rate !== null && rate.toString().includes(searchText)) ||
                (ytd !== null && ytd.toString().includes(searchText))
            );
        });
    };

    const handleSearch = (text) => {
        setSearchText(text);
        setFilteredData(performSearch(searchText, tableData));
    };


    return (
        <div className="cms-main-content">
            <div className="body-content">
                <h1>Fund List</h1>
                <div elevation={3} style={{ display: 'flex', flexDirection: 'row-reverse', gap: '5px', padding: '1rem' }}>
                    <FormControl
                        variant="outlined"
                    >
                        <CssTextField
                            name="search"
                            variant="outlined"
                            id="search"
                            value={searchText}
                            onChange={(event) => handleSearch(event.target.value)}
                            label="Search"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="search" edge="end">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                        <InputLabel >Month</InputLabel>
                        <Select
                            value={selectedMonth}
                            onChange={(event) => setSelectedMonth(event.target.value)}
                            label="month"
                            placeholder="Month"
                        >
                            {months.map((item) => <MenuItem value={item.value}>{item.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ minWidth: 200 }}>
                        <InputLabel>Year</InputLabel>
                        <Select
                            value={selectedYear}
                            onChange={(event) => setSelectedYear(event.target.value)}
                            placeholder="Year"
                            label="Year"
                        >
                            {years.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
                <Paper elevation={3} style={{ marginTop: '10px' }}>
                    {chartLoading && <LinearProgress style={{ marginBottom: '5px' }} color="primary" />}
                    <CustomDataTable
                        title={"NAVPS History"}
                        data={filteredData}
                        columns={columns}
                        options={options}
                        style={{ width: '100%' }}
                    />
                </Paper>
                <Dialog onClose={() => handleCloseModal(false)} fullWidth maxWidth={'sm'} open={modalOpen}>
                    <DialogTitle>
                        <span>{selectedRow}</span>
                    </DialogTitle>
                    <DialogContent dividers>
                        <CalendarView selectedRow={selectedRow} rateDataList={rateDataList} updateItem={updateItem} />
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );

}

const condition = (authUser) => !!authUser;

const Navps = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(NavpsBase);

export default NavpsPage;
