import React from "react";
import {
  Button,
  FormControl,
  Select,
  Snackbar,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';

import Helpers from "../Helpers";
import "./css/dashboard.css";
import moment from "moment";

import rampverLogo from "../../images/logo.png";
import { Alert } from "@material-ui/lab";

const DashboardForm = (props) => {
  const {
    processingDate,
    processingCount,
    processingRedemptionCount,
    applicationCount,
    assignManagerCount,
    recurringCount,
    graduatingUsers,
    autoDebitCount,
    fundRates,
    fundDate,
    isRatesUpdating,
    updateAlertOpen,
    updateAlertObj,
    mostInvestedCompany,
    mostInvestedFund,
    usersAmount,
    totalFundsAmount,
    loadingTasks,
    totalSharesAmount
  } = props.state;
  let helpers = new Helpers();

  const navpsAlert = (
    <Snackbar
      open={updateAlertOpen}
      autoHideDuration={6000}
      onClose={props.closeAlert()}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={props.closeAlert()}
        severity={updateAlertObj.ok ? "success" : "error"}
      >
        {updateAlertObj.message}
      </Alert>
    </Snackbar>
  );

  const categories = [
    { title: "Applications", count: applicationCount },
    { title: "Investments", count: processingCount },
    { title: "Redemptions", count: processingRedemptionCount },
    { title: "BPI ADA", count: autoDebitCount },
    { title: "Recurring", count: recurringCount },
    { title: "Graduating", count: graduatingUsers },
  ];

  return (
    <div className={"dashboardRoot"}>
      {navpsAlert}
      <div className={"dashboardMasterContainer"}>
        <label className={"dashboardLabel"}>Dashboard</label>
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Paper style={{ height: "100%" }}>
              <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#256141', padding: '5px 10px', color: 'white', fontSize: '14px' }}>
                <span style={{ flexGrow: 1 }}>Today's Task</span>
                <span style={{ marginRight: '10px' }}>As of: {processingDate}</span>
                <IconButton disabled={loadingTasks} aria-label="refresh" style={{ color: 'white', padding: '5px' }} onClick={props.refreshListCount()}>
                  <RefreshIcon style={{ fontSize: '20px' }} />
                </IconButton>
              </div>
              {loadingTasks ?
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: '30px' }}>
                  <CircularProgress />
                  <div style={{ marginTop: "10px" }}>Loading...</div>
                </div>
                :
                <Grid container spacing={2} >
                  {categories.map((item, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <Card
                        className={"dashboardContainerA1Inside"}
                        data-type={index}
                        onClick={() => props.onHandleTaskClick(index, item.count)}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <span className={"dashboardContainerA1InsideLabel"}>
                              {item.title}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <span
                              className={
                                item.count > 0
                                  ? "dashboardContainerA1InsideLabel2"
                                  : "dashboardContainerA1InsideLabel3"
                              }
                            >
                              {item.count > 99 ? "99+" : item.count}
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <span className={"dashboardContainerA1InsideLabel"}>
                              {item.title === 'Graduating' ? 'users' : 'tasks'}
                            </span>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper style={{ height: "100%" }}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#256141', padding: '10px', color: 'white', fontSize: '14px' }}>
                    <span style={{ flexGrow: 1 }}>   Current NAVPS Rates</span>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ padding: "10px" }}>
                  <span>As of: {fundDate}</span>
                  <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <TableContainer>
                      <Table stickyHeader size="small" aria-label="Navps table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>Fund Name</b>
                            </TableCell>
                            <TableCell>
                              <b>Rate</b>
                            </TableCell>
                            <TableCell>
                              <b>Date</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fundRates.map((item) => (
                            <TableRow key={item.fundName}>
                              <TableCell component="th" scope="row">
                                {item.fundName}
                              </TableCell>
                              <TableCell align="right">{`${item.rate}`}</TableCell>
                              <TableCell align="right">
                                {item.date.seconds === undefined
                                  ? 0
                                  : new moment.unix(item.date.seconds).format(
                                    "MM/DD/YYYY"
                                  )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ padding: "20px", marginTop: "auto" }}>
                    {isRatesUpdating ? (
                      <LinearProgress />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ color: "white" }}
                        onClick={props.refreshNavps()}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={"dashboardContainerA2Content1"}>
              <label className={"dashboardContainerA2Content1Label"}>
                Most Invested Company
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  src={mostInvestedCompany.icon}
                  alt="company logo"
                  className={"dashboardContainerA2Content1Img"}
                />

                <div className={"dashboardContainerA2Content1Divider"} />

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className={"dashboardContainerA2Content1Label"}>
                    {mostInvestedCompany.name}
                  </label>
                  <label className={"dashboardContainerA2Content1Count"}>
                    {mostInvestedCompany.users}
                  </label>
                  <label className={"dashboardContainerA2Content1Acc"}>
                    Transactions processed
                  </label>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={"dashboardContainerA2Content1"}>
              <label className={"dashboardContainerA2Content1Label"}>
                Most Invested Fund
              </label>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={"dashboardContainerA2Content1Label2"}>
                  {mostInvestedFund.name}
                </label>
                <label className={"dashboardContainerA2Content1Count"}>
                  {helpers.formatToPHP(mostInvestedFund.amount)}
                </label>
                <label className={"dashboardContainerA2Content1Acc"}>
                  Investments accumulated
                </label>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={"dashboardContainerA2Content1"}>
              <label className={"dashboardContainerA2Content1Label"}>
                Total Clients
              </label>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={"dashboardContainerA2Content1Label2"}>
                  Number of Approved Users:
                </label>
                <label className={"dashboardContainerA2Content1Count"}>
                  {usersAmount}
                </label>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={"dashboardContainerA2Content1"}>
              <label className={"dashboardContainerA2Content1Label"}>
                Total Accumulated Investments:
              </label>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={"dashboardContainerA2Content1Label2"}>
                  Pesos:
                </label>
                <label className={"dashboardContainerA2Content1Count"}>
                  {helpers.formatToPHP(totalFundsAmount)}
                </label>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Paper className={"dashboardContainerA2Content1"}>
              <label className={"dashboardContainerA2Content1Label"}>
                Total Rampver Online Assets Under Management:
              </label>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className={"dashboardContainerA2Content1Label2"}>
                  Pesos, as of {fundDate}:
                </label>
                <label className={"dashboardContainerA2Content1Count"}>
                  {helpers.formatToPHP(totalSharesAmount)}
                </label>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DashboardForm;
