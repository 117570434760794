import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../Session/withAuthorization";
import CustomDataTable from "../CustomDataTable";

import Navigation from "../Navigation";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import moment from "moment";

const FeedbackPage = () => {
  return <Navigation content={<Feedback />} />;
};

class FeedbackBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: [],
      columns: [],
      transactionId:""
    };
  }

  componentDidMount() {
    const transactionRef = this.props.firebase.db.collection(`transactions`)
    const feedbackRef = this.props.firebase.db
      .collection("feedback")
      .doc("redemptionFeedback");

    const columns = [
      {
        name: "dateCompiled",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === ""
                  ? "-"
                  : moment.unix(value.seconds).format("MM/DD/YYYY h:mm a")}
              </>
            );
          },
        },
      },
      {
        name: "transactionId",
        label: "Transaction Id",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "clientName",
        label: "Client Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "question1",
        label: "Question #1",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            let display = "";
            value.answers.forEach((answers) => {
              if (display === "") display = `#${answers}`;
              else display = `${display}, #${answers}`;
            });
            return <>{display}</>;
          },
        },
      },
      {
        name: "other",
        label: "if answer no. 6 (other) ",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.question1.otherText ? value.question1.otherText : "---"}
            </span>
          );
       },
        },
      },

      {
        name: "question02",
        label: "Question #2",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            return (
              <span
                className=" capitalize"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {value.question4 === undefined
                  ? value.question2 === ""
                    ? "---"
                    : value.question2
                  : value.question4 === ""
                  ? "---"
                  : value.question4}
              </span>
            );
          },
        },
      },
    ];

transactionRef
  .get()
  .then((transactionsSnapshot) => {
    const transactionsData = transactionsSnapshot.docs.map((doc) => {
      const firstName = doc.data().clientDetails.userFirstName;
      const lastName = doc.data().clientDetails.userLastName;
      const documentId = doc.id;
      return { documentId, firstName, lastName };
    });
    this.setState({ transactionId: transactionsData });
  });


feedbackRef
  .get()
  .then((snapshot) => {
    let arrPromise = [];
    snapshot.data().years.forEach((item) => {
      arrPromise.push(
        this.props.firebase.db
          .collection(`feedback/redemptionFeedback/${item}`)
          .get()
      );
 
    });
    return Promise.all(arrPromise);
  })
  .then((result) => {
let compiledFeedback = [];

result.forEach((querySnapshot) => {
  querySnapshot.forEach((doc) => {
    doc.data().entries.forEach((item) => {
      const filterByID = this.state.transactionId.filter(
        (id) => id.documentId === item.transactionId
        );

        const userName = filterByID.map((name, index) => (
          <span className="capitalize" key={index}>
            {name.firstName} {name.lastName}
          </span>
        ));

        compiledFeedback.push({
          clientName: userName,
          other: item,
          question02: item,
          ...item,
        });

    });
  });

    });
    return this.setState({ feedback: compiledFeedback, columns });
  })
  .catch((err) => {
    alert(err);
  });
  }

  render() {
    const { feedback, columns } = this.state;

    const options = {
      filterType: "checkbox",
      responsive: "standard",
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      selectableRows: false,
      sortOrder: {
        name: "dateCompiled",
        direction: "desc",
      },
    };

    return (
      <div className="cms-main-content">
        <div className="body-content">
          <div className="transaction-list">
            <h1>Feedback</h1>
            <span>Listing of all current feedback</span>
            <br />
            <br />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span style={{ fontWeight: "700", fontSize: "18px" }}>
                  View current survey questions:
                </span>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3>
                  Question 1: Why are you redeeming your mutual fund investment?
                </h3>
                <ol>
                  <li>
                    Realized Gains (I have already achieved my financial goal
                    for this investment)
                  </li>
                  <li>
                    Fund Switching (I want to invest in a different mutual fund
                    within Rampver's products)
                  </li>
                  <li>
                    Use for another financial product (I will use the proceeds
                    form my mutual fund investment to fund another financial
                    product)
                  </li>
                  <li>
                    Emergency (I will use the proceeds from my mutual fund
                    investment to pay for an emergency expense)
                  </li>
                  <li>
                    Not satisfied with the returns (I am not satisfied with the
                    performance of my mutal fund investment)
                  </li>
                  <li>Others (Kindly specify the reason)</li>
                </ol>
                <h3>
                  Question 2: Do you have any feedback or suggestion for rampver
                  online?
                </h3>
              </AccordionDetails>
            </Accordion>
            <br />
            <br />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomDataTable
                  title={"Redemption Feedback"}
                  data={feedback}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

const Feedback = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(FeedbackBase);

export default FeedbackPage;
