import React from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  Fade,
  FormHelperText,
} from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";

class MobileNumberField extends ValidatorComponent {
  renderValidatorComponent() {
    const { value, name, onChange, className } = this.props;
    return (
      <div>
        <FormControl>
          <OutlinedInput
            error={this.errorText() !== null ? true : false}
            name={name}
            className={className}
            value={value}
            autoComplete="off"
            placeholder="10 digits number..."
            inputProps={{
              style: { padding: "12px 0px 12px 12px" },
              maxLength: 10,
            }}
            startAdornment={
              <InputAdornment position="start">+63</InputAdornment>
            }
            onChange={onChange}
          />
        </FormControl>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default MobileNumberField;
