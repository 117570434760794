import axios from "axios";
import * as ROUTES from "../../../constants/routes";
import Helpers from "../../Helpers";

export default class FundAddFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doUpdateMisc = (misc) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + misc.url,
          JSON.stringify({
            title: misc.termsTitle,
            description: misc.termsDescription,
            content: misc.termsContentText,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
