import React, { Component } from "react";
import withAuthorization from "../Session/withAuthorization";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import Navigation from "../Navigation";
import CustomDataTable from "../CustomDataTable";
import { AuthUserContext } from "../Session";
// import * as ROUTES from "../../constants/routes";

const InvestmentRequest = () => {
  return <Navigation content={<InvestmenPage />} />;
};

class InvestmentRequestBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: "trans_id",
          label: "Transaction ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "trans_date",
          label: "Transaction Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "fund_name",
          label: "Fund Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "maturity_date",
          label: "Maturity Date",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "amount",
          label: "Amount",
          options: {
            filter: true,
            sort: true,
          },
        },
      ],
      options: {
        filterType: "checkbox",
        responsive: "standard",
        selectableRowsHideCheckboxes: true,
        selectableRows: false,
        // onRowClick: (data) => {
        //   this.props.history.push(
        //     `${ROUTES.INVESTMENT_REQUEST_UPLOAD}/${data[0]}`
        //   );
        // },
        // serverSide: true
      },
      investments: [],
    };
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    let investmentRef = this.props.firebase.db.collection("transactions");

    investmentRef
      .get()
      .then((snapshot) => {
        let investmentsPending = [];
        snapshot.docs.forEach((doc) => {
          investmentsPending.push({
            trans_id: doc.data().eventId,
            trans_date: doc.data().datePlaced.toDate().toDateString(),
            fund_name: doc.data().fundDetails.fundName,
            maturity_date: doc.data().dateMaturity,
            status: doc.data().status,
            amount: `PHP ${doc.data().amount}`,
          });
        });
        return investmentsPending;
      })
      .then((investments) => {
        this.setState({ investments: investments });
      })
      .catch((err) => {});
  }

  render() {
    document.body.style.backgroundColor = "#fff";
    const { columns, options, investments } = this.state;
    return (
      <div className="cms-main-content">
        <div className="body-content">
          <div className="transaction-list">
            <h1>Investment Requests</h1>
            <span>Consist of all investment requests</span>
            <br /> <br /> <br />
            <CustomDataTable
              title="Investment Requests"
              data={investments}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

const InvestmenPage = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(InvestmentRequestBase);

export default InvestmentRequest;
export { InvestmenPage };
