import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function Download(props) {
  let convertedData = [];
  let data = {
    fundName: props.data.fundName,
    clientName: props.data.clientName,
    fundCode: props.data.fundCode,
    companyName: props.data.companyName,
    amount: props.data.amount,
    approvedBy: props.data.approvedBy,
    applicationDate: props.data.applicationDate,
    applicationTime: props.data.applicationTime,
    transactionId: props.data.transactionId,
    status: props.data.status,
    investmentType: props.data.investmentType,
    curAddress: props.data.curAddress,
    permAddress: props.data.permAddress,
    birthday: props.data.birthday,
    placeOfBirth: props.data.placeOfBirth,
    nationality: props.data.nationality,
    occupation: props.data.occupation,
    sourceOfFunds: props.data.sourceOfFunds,
    contactNum: props.data.contactNum,
    SSS: props.data.SSS,
    TIN: props.data.TIN,
  };
  convertedData.push(data);
  return (
    <ExcelFile element={props.button} filename={props.filename}>
      <ExcelSheet data={convertedData} name="Investment Details">
        <ExcelColumn label="Fund Name" value="fundName" />
        <ExcelColumn label="Client Name" value="clientName" />
        <ExcelColumn label="Fund Code" value="fundCode" />
        <ExcelColumn label="Company Name" value="companyName" />
        <ExcelColumn label="Amount" value="amount" />
        <ExcelColumn label="Approved By" value="approvedBy" />
        <ExcelColumn label="Application Date" value="applicationDate" />
        <ExcelColumn label="Application Time" value="applicationTime" />
        <ExcelColumn label="Transaction ID" value="transactionId" />
        <ExcelColumn label="Investment Status" value="status" />
        <ExcelColumn label="Investment Type" value="investmentType" />
        <ExcelColumn label="Current Address" value="curAddress" />
        <ExcelColumn label="Permanent Address" value="permAddress" />
        <ExcelColumn label="Date Of Birth" value="birthday" />
        <ExcelColumn label="Place Of Birth" value="placeOfBirth" />
        <ExcelColumn label="Nationality" value="nationality" />
        <ExcelColumn label="Occupation" value="occupation" />
        <ExcelColumn label="Source of Funds" value="sourceOfFunds" />
        <ExcelColumn label="Contact Number" value="contactNum" />
        <ExcelColumn label="SSS/GSIS" value="SSS" />
        <ExcelColumn label="TIN" value="TIN" />
      </ExcelSheet>
    </ExcelFile>
  );
}
