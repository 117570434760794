import React, { Component } from "react";

import CategoriesEditForm from "./categoriesEditFront";
import CategoriesEditFunc from "./categoriesEditFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Helpers from "../../../Helpers";

const INITIAL_STATE = {
  funds: [],
  categoryCode: "",
  categoryTitle: "",
  choosenFunds: [],
  categoryDescription: "",
  categoryCodeError: "",
  categoryTitleError: "",
  choosenFundsError: "",
  fundsSelected: [],
  isLoading: false,
  toggleAlert: false,
  messageType: "warning",
  messageAlert: "",
  toggleActionAlert: false,
};

class CategoriesEditFormBase extends Component {
  constructor(props) {
    super(props);

    this.helpers = new Helpers();
    this.state = { ...INITIAL_STATE };
    this.categoriesEditFunction = new CategoriesEditFunc();
    this.db = firebase.firestore();
    this.fundCatRef = this.db.collection("fundCategories");
  }

  componentDidMount() {
    var catId = this.props.location.state.id;

    this.fundCatRef
      .doc(catId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const categoryData = doc.data();
          let catDesc = this.helpers.stringToWhiteSpaceConverter(
            categoryData.description
          );
          this.setState({
            categoryCode: categoryData.code,
            categoryTitle: categoryData.name,
            categoryDescription: catDesc,
          });
        } else {
          this.setState({
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: "Document doesn't exists.",
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validator(name);
    });
  };

  validator = (name) => {
    const { categoryCode, categoryTitle } = this.state;

    switch (name) {
      case "categoryCode":
        if (categoryCode.trim() === "") {
          this.setState({ categoryCodeError: "Please input category code." });
        } else {
          this.setState({ categoryCodeError: "" });
        }
        break;
      case "categoryTitle":
        if (categoryTitle.trim() === "") {
          this.setState({ categoryTitleError: "Please input category title." });
        } else {
          this.setState({ categoryTitleError: "" });
        }
        break;
      default:
        break;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();
    var categoryId = this.props.location.state.id;

    this.setState({ isLoading: true });

    const {
      categoryCode,
      categoryTitle,
      categoryDescription,
      categoryCodeError,
      categoryTitleError,
    } = this.state;

    const isValid = categoryCodeError === "" && categoryTitleError === "";

    if (isValid) {
      this.categoriesEditFunction
        .doCategoryEdit(
          categoryId,
          categoryCode,
          categoryTitle,
          categoryDescription
        )
        .then(() => {
          this.setState({
            isLoading: false,
            toggleActionAlert: true,
            messageType: "success",
            messageAlert: "Category edited successfully.",
          });

          setTimeout(() => {
            this.props.history.push(ROUTES.CATEGORIES_LIST);
          }, 1000);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: error.message,
          });
        });
    } else {
      this.setState({
        isLoading: false,
        toggleActionAlert: true,
        messageType: "error",
        messageAlert: "An error occured.",
      });
    }
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleActionAlert: false });
  };

  render() {
    return (
      <CategoriesEditForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
      />
    );
  }
}

export default withRouter(CategoriesEditFormBase);
