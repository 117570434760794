import React, { Component } from "react";

// import Typography from '@material-ui/core/Typography';

import CompanyFunction from "./companyFunction";
import { withFirebase } from "../../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../../Session/withAuthorization";

import Navigation from "../../../Navigation";

const CompanyPage = () => {
  return (
    <>
      <Navigation content={<CompanyForm />} />
    </>
  );
};

class CompanyPageBase extends Component {
  render() {
    return (
      <>
        <CompanyFunction props={this.props} />
        <label
          variant="caption"
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const CompanyForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(CompanyPageBase);

export default CompanyPage;

export { CompanyForm };
