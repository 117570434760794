import axios from "axios";
import * as ROUTES from "../../../../constants/routes";
import Helpers from "../../../Helpers";

export default class CategoryEditFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doCategoryEdit = (catId, catCode, catName, catDesc) => {
    const token = this.helpers.getCookie("token");
    catDesc = this.helpers.whiteSpaceToStringConverter(catDesc);
    return new Promise((resolve, reject) => {
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/editCategory",
          JSON.stringify({
            categoryUid: catId,
            name: catName,
            description: catDesc,
            code: catCode,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
