import { useState, useEffect } from 'react';
import moment from 'moment';
import ReactExport from "react-export-excel";
import { Button } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function Download(props) {

    return (
        <ExcelFile element={props.button} filename={props.filename}>
            <ExcelSheet data={props.data} name={props.batchName}>
                <ExcelColumn label="User Account's Number" value="accountNumber" />
                <ExcelColumn label="Client Name" value="clientName" />
            </ExcelSheet>
        </ExcelFile>
    );
}

const useFetchBatchData = (props) => {
    const { firebase } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    function ExportUserTransactions() {
        alert('excel');
    }

    const ExportUserInfo = ({ id }) => {
        const batchData = data.find((x) => x.id === id);

        return (
            <div>
                <ExcelFile
                    element={
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                backgroundColor: "#256141",
                                color: "white",
                                marginRight: "5px",
                            }}
                        >
                            Export User Data
                        </Button>
                    }
                    filename={`Batch ${batchData.name}`}
                >
                    <ExcelSheet data={batchData.userData} name={`Batch ${batchData.name}`}>
                        <ExcelColumn label="User Account's Number" value="accountNumber" />
                        <ExcelColumn label="First Name" value="firstName" />
                        <ExcelColumn label="Last Name" value="lastName" />
                    </ExcelSheet>
                </ExcelFile>
            </div>
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersRef = firebase.db.collection('users');
                const querySnapshot = await usersRef.where('miscId.trc-batch', '!=', null).get();
                const usersData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const groupedByBatch = usersData.reduce((acc, user) => {
                    const batch = user.miscId['trc-batch'].seconds
                    if (!batch) return acc;

                    if (!acc[batch]) {
                        acc[batch] = [];
                    }
                    acc[batch].push(user);
                    return acc;
                }, {});

                const formattedData = Object.keys(groupedByBatch).map((batch,) => ({
                    id: batch,
                    name: `Batch ${moment.unix(batch).format('YYYY/MM/DD HH:mm a')}`,
                    userData: groupedByBatch[batch],
                }));

                setData(formattedData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return {
        data,
        loading,
        error,
        ExportUserInfo,
    };
};

export default useFetchBatchData;