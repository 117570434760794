import React, { Component } from "react";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../Session/withAuthorization";
import Navigation from "../../Navigation";
import { NewInvestmentListFront } from "./NewInvestmentListFront";
import Helpers from "../../Helpers";
import "./css/transactionlist.css";
import * as ROUTES from "../../../constants/routes";

const NewInvestmentsPage = () => {
  return <Navigation content={<NewInvestmentsList />} />;
};

class NewInvestmentsListBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "user_id",
          label: "User Account's Number",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "client_name",
          label: "Client Name",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "fund_name",
          label: "Fund Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "fund_code",
          label: "Fund Code",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "company_name",
          label: "Company Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "amount",
          label: "Amount",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "app_date",
          label: "Application Date",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "app_time",
          label: "Application Time",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "trans_id",
          label: "Transaction ID",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "approved_by",
          label: "Approved By",
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            filter: false,
            sort: true,
            filterList: this.props.location.search !== "" && [
              new URLSearchParams(this.props.location.search).get("status"),
            ],
          },
        },
        {
          name: "type",
          label: "Investment Type",
          options: {
            filter: false,
            sort: true,
          },
        },
      ],
      transactions_list: [],
      transactions_list_pending: [],
      status: "all",
    };

    this.helpers = new Helpers();
  }

  componentDidMount() {
    var invRef = this.props.firebase.db.collection("transactions");
    var userRef = this.props.firebase.db.collection("users");
    var transactions_list = [];
    var dateFormat = this.helpers.dateFormat;
    invRef
      .where("status", "not-in", ["processingRedemption", "redeemed"])
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach(function (doc, index) {
          const data = doc.data();
          if (
            data.approvedBy !== undefined &&
            data.approvedBy.approvedByManagerDate !== undefined
          ) {
            var date = data.approvedBy.approvedByManagerDate
              .toDate()
              .toDateString();
            var app_time = data.approvedBy.approvedByManagerDate
              .toDate()
              .toLocaleTimeString();
            var app_date = dateFormat(date);
          } else {
            var app_time = "N/A";
            var app_date = "N/A";
          }
          var user_id =
            data.clientDetails !== undefined ||
            data.clientDetails.userAccountNumber !== ""
              ? data.clientDetails.userAccountNumber
              : "N/A";
          var first_name =
            data.clientDetails !== undefined &&
            data.clientDetails.userFirstName !== ""
              ? data.clientDetails.userFirstName
              : "N/A";
          var last_name =
            data.clientDetails !== undefined &&
            data.clientDetails.userLastName !== ""
              ? data.clientDetails.userLastName
              : "N/A";
          var fund_name =
            data.fundDetails !== undefined && data.fundDetails.fundName !== ""
              ? data.fundDetails.fundName
              : "N/A";
          var fund_code =
            data.fundDetails !== undefined && data.fundDetails.fundCode !== ""
              ? data.fundDetails.fundCode
              : "N/A";
          var amount =
            data.amount !== undefined && data.amount !== ""
              ? parseFloat(data.amount).toFixed(2)
              : "N/A";
          var trans_id =
            data.eventId !== undefined && data.eventId !== ""
              ? data.eventId
              : "N/A";
          var approved_by =
            data.approvedBy !== undefined &&
            data.approvedBy.approvedByManagerName !== ""
              ? data.approvedBy.approvedByManagerName
              : "N/A";
          var status =
            data.status !== undefined && data.status !== ""
              ? data.status
              : "N/A";
          var company_name =
            data.fundCompanyDetails !== undefined &&
            data.fundCompanyDetails.fundCompanyName !== ""
              ? data.fundCompanyDetails.fundCompanyName
              : "N/A";
          let type =
            data.investmentType !== undefined && data.investmentType !== ""
              ? data.investmentType
              : "N/A";

          transactions_list.push({
            user_id: user_id,
            client_name: first_name + " " + last_name,
            fund_name: fund_name,
            fund_code: fund_code,
            amount: amount,
            trans_id: trans_id,
            approved_by: approved_by,
            status: status.toLowerCase(),
            app_time: app_time,
            app_date: app_date,
            company_name: company_name,
            fee: data.fee,
            rateAtBooking: data.rateAtBooking,
            folio: data.folio,
            shares: data.shares,
            settlementDetails: null,
            type: type,
          });
        });
        this.setState({
          transactions_list: transactions_list,
          transactions_list_pending: transactions_list,
        });
        this.arrangeTransactionsByDesc();
        this.filterStatusChange(
          "status",
          [new URLSearchParams(this.props.location.search).get("status")],
          true
        );
      })
      .catch((err) => {
        console.log("get transaction list error: ", err.message);
      });
  }

  arrangeTransactionsByDesc = () => {
    var transactions = this.state.transactions_list;
    var transactionsWithDate = [];
    var transactionsWithoutDate = [];
    var returnTransactions = [];
    for (var a = 0; a < transactions.length; a++) {
      if (transactions[a].app_date !== "N/A")
        transactionsWithDate.push(transactions[a]);
      else transactionsWithoutDate.push(transactions[a]);
    }
    transactionsWithDate.sort(function (a, b) {
      var dateA = new Date(a.app_date + " " + a.app_time);
      var dateB = new Date(b.app_date + " " + b.app_time);
      return dateB - dateA;
    });
    returnTransactions.push(...transactionsWithDate);
    returnTransactions.push(...transactionsWithoutDate);

    this.setState({ transactions_list: returnTransactions });
  };

  filterStatusChange = (columnId, value, fromDashboard) => {
    var getValue = fromDashboard ? [value[0]] : value[10];
    var transactions = this.state.transactions_list;
    var toGenerate = [];
    if (columnId === "status") {
      if (getValue.length !== 0) {
        this.setState({ status: getValue });
        for (var a = 0; a < transactions.length; a++) {
          for (var b = 0; b < getValue.length; b++) {
            if (transactions[a].status === getValue[b]) {
              toGenerate.push(transactions[a]);
            }
          }
        }
        this.setState({
          transactions_list_pending: toGenerate,
        });
      } else {
        this.setState({
          transactions_list_pending: transactions,
        });
      }
    }
  };

  render() {
    const { transactions_list, transactions_list_pending, columns, status } =
      this.state;
    const filterStatusChange = this.filterStatusChange;
    const options = {
      filterType: "checkbox",
      responsive: "standard",
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      selectableRows: false,
      download: false,
      rowHover: true,
      onFilterChange: function (columnId, value) {
        filterStatusChange(columnId, value, false);
      },
      onRowClick: (data) => {
        this.props.history.push({
          pathname: `${ROUTES.INVESTMENT_DETAILS}/${data[8]}`,
          state: { code: data[8] },
        });
      },
    };

    return (
      <NewInvestmentListFront
        arrangeTransactionsByDesc={this.arrangeTransactionsByDesc}
        transactions_list_pending={transactions_list_pending}
        transactions_list={transactions_list}
        options={options}
        columns={columns}
        dateFormat={this.dateFormat}
        status={status}
      />
    );
  }
}

const condition = (authUser) => !!authUser;

const NewInvestmentsList = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(NewInvestmentsListBase);

export default NewInvestmentsPage;

export { NewInvestmentsList };
