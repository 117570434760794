import axios from "axios";
import * as ROUTES from "../../../../constants/routes";
import Helpers from "../../../Helpers";

export default class FundListFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doUploadDailyRates = (excelFile, date) => {
    const token = this.helpers.getCookie("token");
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append("req", `{"date": "${date}"}`);
      formData.append("excel", excelFile[0].file);

      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "cms-navps-navps/uploadNavps",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
