import React, { Component } from "react";

import ClientProfileFront from "./clientProfileFront";

import { withRouter } from "react-router-dom";
// import * as ROUTES from '../../../constants/routes';

import { FirebaseContext } from "../../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/storage";

import Helpers from "../../Helpers";

import ApproveClientFunc from "./clientProfileFunc";
import doEditDocuments from "./clientProfileFunc";
import doSetValidId from "./clientProfileFunc";
import { riskData } from "./riskProfileAnswers";

const INITIAL_STATE = {
  tabValue: 1,
  itemSelected: 1,
  kycPage: 1,
  clientId: "",
  FATCA: [],
  PEP: [],
  annualIncome: [],
  businessAdd: [],
  civil: [],
  contact: [],
  currentAdd: [],
  dateOfBirth: "",
  placeOfBirth: "",
  email: "",
  gender: "",
  idPhoto: "",
  idPhotoFront: "",
  idPhotoBack: "",
  proofOfAddress: "",
  PEPDocument: "",
  ids: [],
  investmentManager: [],
  mobileNumber: "",
  name: [],
  nationality: "",
  otherProducts: "",
  permanentAdd: [],
  regularBasis: "",
  selfie: "",
  signature: "",
  sustainabilityAssessment: [],
  validated: false,
  validationDate: "",
  work: [],
  accountDetails: [],
  managerList: [],
  clientDetails: [],
  investmentTotalAmount: 0,
  transactionsList: [],
  dialogType: "approve",
  coachId: "",
  idPhotoSrc: "",
  idPhotoFrontSrc: "",
  idPhotoBackSrc: "",
  proofOfAddressSrc: "",
  PEPDocumentSrc: "",
  signatureSrc: "",
  selfieSrc: "",
  imageType: -100,
  investmentDetails: [],
  transSelectedId: "",
  depositSlipSrc: "",
  transType: "pending",
  totalInvestmentAmount: 0,
  selfieSize: 0,
  idPhotoFrontSize: 0,
  idPhotoBackSize: 0,
  proofOfAddressSize: 0,
  PEPDocumentSize: 0,
  signatureSize: 0,
  toggleShowImage: false,
  toggleDialog: false,
  toggleAlert: false,
  alertType: "warning",
  alertMessage: "",
  isLoading: false,
  comments: "",
  commentToAdd: "",
  additionalDocuments: [],
  additionalDocumentsDisplay: [],
  loadingDownload: false,
  editDialogVisible: false,
  editDocumentsDisplay: [],
  toDeleteDocument: [],
  editLoading: false,
  depositSlipLoading: false,
  panelsExpand: false,
  panels: {
    panelClientInfo: false,
    panelAddress: false,
    panelProDetails: false,
    panelPEP: false,
    panelFATCA: false,
    panelRisk: false,
    panelSettlement: false,
  },
  userRiskData: [],
  stats: {},
  cityOfBirth:""
};

class ClientProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.db = firebase.firestore();
    this.approveClientFunction = new ApproveClientFunc();
    this.editDocuments = new doEditDocuments();
    this.doSetValidId = new doSetValidId();
    this.storage = firebase.storage().ref();
  }

  async componentDidMount() {
    await this.getKYCs();
    await this.getRiskDetails();

    if (this.props.location.state.from.toUpperCase() === "TL") {
      this.setState({
        tabValue: 2,
        transSelectedId: this.props.location.state.transId,
        transType: this.props.location.state.transType,
      });
    }
  }

  async getRiskDetails() {
    try {
      const userId = this.props.location.state.user;
      const snapshot = await this.db
        .collection("users")
        .doc(userId)
        .collection("riskQuestionnaire")
        .doc("RQ")
        .get();

      const userRiskData = riskData.map((item) => {
        const entry = snapshot.data()[item.key];

        const answer = Object.keys(entry).find((key) => entry[key] === true);
        const answerIndex = parseInt(answer.slice(-1)) - 1;

        const type = ["Conservative", "Moderate", "Aggressive"][answerIndex];
        const color = ["#507BE9", "#256141", "#ED5555"][answerIndex];

        return {
          question: item.question,
          answer:
            answerIndex !== -1 ? item.answer[answerIndex] : "Not answered",
          type: answerIndex !== -1 ? type : "Not answered",
          color: answerIndex !== -1 ? color : "#000000",
        };
      });

      this.setState({ userRiskData });
    } catch (err) {
      alert(err);
    }
  }

  async getKYCs() {
    var kyc;
    const userId = this.props.location.state.user;

    await this.db
      .collection("users")
      .doc(userId)
      .get()
      .then((result) => {
        this.setState({
          clientDetails: result.data(),
          additionalDocumentsDisplay: result.data().additionalDocuments,
          editDocumentsDisplay: result.data().additionalDocuments,
          stats: result.data().stats,
          tierUpgradeStatus: result.data().stats.tierUpgradeStatus,
        });
        return this.db
          .collection("users")
          .doc(userId)
          .collection("KYC")
          .doc("KYC")
          .get();
      })
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          kyc = querySnapshot.data();
        } else {
          this.setState({
            toggleAlert: true,
            alertType: "error",
            alertMessage: "KYC doesn't exists.",
          });
        }
        if (kyc) {
          const data = kyc;
          this.setState({
            clientId: userId,
            FATCA: data.FATCA !== undefined ? data.FATCA : [],
            PEP: data.PEP !== undefined ? data.PEP : [],
            annualIncome:
              data.annualIncome !== undefined ? data.annualIncome : [],
            businessAdd:
              data.businessAddress !== undefined ? data.businessAddress : [],
            civil: data.civil !== undefined ? data.civil : [],
            contact: data.contact !== undefined ? data.contact : [],
            currentAdd:
              data.currentAddress !== undefined ? data.currentAddress : [],
            dateOfBirth:
              data.dateOfBirth !== undefined ? data.dateOfBirth : "N/A",
            placeOfBirth:
              data.placeOfBirth !== undefined ? data.placeOfBirth : "N/A",
            cityOfBirth:
              data.cityOfBirth !== undefined ? data.cityOfBirth : "N/A",
            email: data.email !== undefined ? data.email : "N/A",
            gender: data.gender !== undefined ? data.gender : "N/A",
            idPhoto: data.idPhoto !== undefined ? data.idPhoto : "N/A",
            idPhotoFront:
              data.idPhotoFront !== undefined ? data.idPhotoFront : "N/A",
            idPhotoBack:
              data.idPhotoBack !== undefined ? data.idPhotoBack : "N/A",
            ids: data.ids !== undefined ? data.ids : [],
            investmentManager:
              data.investmentManager !== undefined
                ? data.investmentManager
                : [],
            mobileNumber:
              data.mobileNumber !== undefined ? data.mobileNumber : "N/A",
            name: data.name !== undefined ? data.name : [],
            nationality:
              data.nationality !== undefined ? data.nationality : "N/A",
            otherProducts:
              data.otherProducts !== undefined ? data.otherProducts : "N/A",
            permanentAdd:
              data.permanentAddress !== undefined ? data.permanentAddress : [],
            regularBasis:
              data.regularBasis !== undefined ? data.regularBasis : "N/A",
            selfie: data.selfie !== undefined ? data.selfie : "N/A",
            signature: data.signature !== undefined ? data.signature : "N/A",
            sustainabilityAssessment:
              data.sustainabilityAssessment !== undefined
                ? data.sustainabilityAssessment
                : [],
            validated: data.validated !== undefined ? data.validated : false,
            validationDate:
              data.validationDate !== undefined ? data.validationDate : "N/A",
            work: data.work !== undefined ? data.work : [],
            selfieSize: data.selfieSize !== undefined ? data.selfieSize : 0,
            idPhotoFrontSize:
              data.idPhotoFrontSize !== undefined ? data.idPhotoFrontSize : 0,
            idPhotoBackSize:
              data.idPhotoBackSize !== undefined ? data.idPhotoBackSize : 0,
            comments: data.comments !== undefined ? data.comments : "N/A",
            proofOfAddress:
              data.proofOfAddress !== undefined ? data.proofOfAddress : "N/A",
            proofOfAddressSize:
              data.proofOfAddressSize !== undefined
                ? data.proofOfAddressSize
                : 0,
            PEPDocument:
              data.PEPDocument !== undefined ? data.PEPDocument : "N/A",
            PEPDocumentSize:
              data.PEPDocumentSize !== undefined ? data.PEPDocumentSize : 0,
            idImages: data.idImages,
          });
        } else {
          this.setState({
            toggleAlert: true,
            alertType: "error",
            alertMessage: "Unable to retrieve client informations.",
          });
        }
      })
      .catch(() => {
        this.setState({
          toggleAlert: true,
          alertType: "error",
          alertMessage: "Unable to retrieve client KYC.",
        });
      });

    if (this.state.clientDetails.referrerId) {
      await this.getReferrer(this.state.clientDetails.referrerId);
    }

    await this.getCoachList();
    await this.getClientAccountDetails();
    await this.getClientUploadedIds();
    await this.getClientTransaction();
  }

  async getReferrer(refId) {
    await this.db
      .collection("referrers")
      .doc(refId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.setState({
            clientDetails: {
              ...this.state.clientDetails,
              referrerId: `${doc.data().firstName} ${doc.data().lastName}`,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          toggleAlert: true,
          alertType: "error",
          alertMessage: "Unable to retrieve Referrer.",
        });
      });
  }

  async getCoachList() {
    try {
      const querySnapshot = await this.db
        .collection("users")
        .where("role", "==", "manager")
        .get();

      const managerList = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const details = doc.data();
          const managerId = doc.id;
          const firstName = details.firstName || "";
          const lastName = details.lastName || "";

          const formattedFirstName =
            firstName.charAt(0).toUpperCase() + firstName.slice(1);
          const formattedLastName =
            lastName.charAt(0).toUpperCase() + lastName.slice(1);

          const fullname = `${formattedFirstName} ${formattedLastName}`;

          if (fullname.trim() !== "") {
            managerList.push({ id: managerId, name: fullname });
          }
        } else {
          this.setState({
            toggleAlert: true,
            alertType: "error",
            alertMessage: "Unable to retrieve IM/RM list.",
          });
        }
      });

      if (managerList.length > 0) {
        const rampverIndex = managerList.findIndex(
          (manager) => manager.name === "Rampver Financials"
        );
        if (rampverIndex !== -1) {
          const [rampverManager] = managerList.splice(rampverIndex, 1);
          managerList.unshift(rampverManager);
        }

        this.setState({
          managerList: managerList,
          coachId: managerList[0].id,
        });
      } else {
        this.setState({
          toggleAlert: true,
          alertType: "error",
          alertMessage: "No managers found.",
        });
      }
    } catch (err) {
      this.setState({
        toggleAlert: true,
        alertType: "error",
        alertMessage: "Unable to retrieve IM/RM list.",
      });
    }
  }

  async getClientAccountDetails() {
    var accountDetails;
    const userId = this.props.location.state.user;

    await this.db
      .collection("users/" + userId + "/accountDetails")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if (doc.exists) {
            accountDetails = doc.data();
          } else {
            this.setState({
              toggleAlert: true,
              alertType: "error",
              alertMessage: "Unable to retrieve account details",
            });
          }
        });

        return accountDetails;
      })
      .then((response) => {
        this.setState({
          accountDetails: response,
        });
      })
      .catch((error) => {
        this.setState({
          toggleAlert: true,
          alertType: "error",
          alertMessage: "Unable to retrieve account details",
        });
      });
  }

  async getClientUploadedIds() {
    let idsDetails = this.state.idImages;
    idsDetails.forEach(async (item, index) => {
      await this.storage
        .child(item.url)
        .getDownloadURL()
        .then((url) => {
          idsDetails[index].url = url;
        })
        .catch(() => {
          this.setState({
            toggleAlert: true,
            alertType: "error",
            alertMessage: `Unable to retrieve client ${item.name} photo.`,
          });
        });
    });
    this.setState({ idImages: idsDetails });
  }

  async getClientTransaction() {
    var investmentDetails = [];
    var totalAmountInvestment = 0;
    var user_details;
    const userId = this.props.location.state.user;

    var investRef = this.db
      .collection("transactions")
      .where("clientDetails.userUid", "==", userId)
      .orderBy("datePlaced", "desc");
    var userRef = this.db.collection("users").doc(userId);
    var helpers = new Helpers();

    await userRef
      .get()
      .then((result) => {
        user_details = result.data();
        return investRef.get();
      })
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          if (doc.exists) {
            const details = doc.data();

            var transId =
              details.eventId !== undefined ? details.eventId : "N/A";
            var invAmount = details.amount !== undefined ? details.amount : 0;
            var invApprovedBy =
              details.approvedBy !== undefined ? details.approvedBy : [];
            var invClientDetails =
              details.clientDetails !== undefined ? details.clientDetails : [];
            var invDateMaturity =
              details.dateMaturity !== undefined ? details.dateMaturity : "N/A";
            var invDatePlaced =
              details.datePlaced !== undefined ? details.datePlaced : "N/A";
            var invFundCompDetails =
              details.fundCompanyDetails !== undefined
                ? details.fundCompanyDetails
                : [];
            var invFundDetails =
              details.fundDetails !== undefined ? details.fundDetails : [];
            var invRateType =
              details.rateType !== undefined ? details.rateType : "N/A";
            var invStatus =
              details.status !== undefined
                ? details.status.toLowerCase()
                : "pending";

            var depositSlip =
              details.depositSlip !== undefined ? details.depositSlip : "N/A";

            var folioNumber =
              details.folio !== "" && details.value !== undefined
                ? details.folio
                : "N/A";

            var shares =
              details.shares !== "" && details.value !== undefined
                ? helpers.numberCommaSeparated(details.shares)
                : "N/A";

            var navps =
              details.rateAtBooking !== "" && details.value !== undefined
                ? helpers.formatToNavps(details.rateAtBooking) +
                  `, Placed at ${details.datePlaced.toDate().toDateString()}`
                : "N/A";

            var fee =
              details.fee !== "" && details.value !== undefined
                ? helpers.formatToPHP(details.fee)
                : "N/A";

            var currentRate =
              details.rate !== "" && details.rate !== undefined
                ? helpers.formatToPHP(details.rate)
                : "N/A";

            var total_value =
              details.value !== "" && details.value !== undefined
                ? helpers.formatToPHP(details.value)
                : "N/A";

            var depositSlipSize =
              details.depositSlipSize !== undefined
                ? details.depositSlipSize
                : 0;

            var depositSlipSecondary =
              details.depositSlipSecondary !== undefined
                ? details.depositSlipSecondary
                : "N/A";

            switch (details.status) {
              case "approved":
                totalAmountInvestment = totalAmountInvestment + invAmount;
                break;

              case "redeemed":
                totalAmountInvestment = totalAmountInvestment - invAmount;
                break;
              default:
                break;
            }

            investmentDetails.push({
              transId: transId,
              amount: invAmount,
              approvedBy: invApprovedBy,
              clientDetails: invClientDetails,
              dateMaturity: invDateMaturity,
              datePlaced: invDatePlaced,
              fundCompDetails: invFundCompDetails,
              fundDetails: invFundDetails,
              rateType: invRateType,
              status: invStatus,
              depositSlip: depositSlip,
              depositSlipSecondary,
              folioNumber: folioNumber,
              shares: shares,
              navps: navps,
              fee: fee,
              currentRate: currentRate,
              accountNumber: user_details.accountNumber,
              total_value: total_value,
              depositSlipSize: depositSlipSize,
            });
          }
        });
        return {
          investmentDetails: investmentDetails,
          total: totalAmountInvestment,
        };
      })
      .then((response) => {
        var transSelectedId =
          response.investmentDetails.length > 0
            ? response.investmentDetails[0].transId
            : 0;
        var transType =
          response.investmentDetails.length > 0
            ? response.investmentDetails[0].status
            : "pending";

        this.setState({
          totalInvestmentAmount: response.total,
          investmentDetails: response.investmentDetails,
          transSelectedId: transSelectedId,
          transType: transType,
        });
      })
      .catch((error) => {
        this.setState({
          toggleAlert: true,
          alertType: "error",
          alertMessage: error.message,
        });
      });
  }

  setValidId(isValid) {
    const userId = this.props.location.state.user;
    let promise = new Promise((resolve, reject) => {
      this.doSetValidId
        .doSetValidId(userId, isValid)
        .then(async (res) => {
          await this.getKYCs();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  approveClient(userId, isApproved, docs, isLegacy) {
    let promise = new Promise((resolve, reject) => {
      this.approveClientFunction
        .doApproveClient(userId, isApproved, "", docs, isLegacy)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  assignManager(userId, managerId) {
    let promise = new Promise((resolve, reject) => {
      this.approveClientFunction
        .doAssignManager(userId, managerId, "")
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  declineClient(userId, isApproved, adminComments) {
    let promise = new Promise((resolve, reject) => {
      if (adminComments !== "") {
        this.approveClientFunction
          .doApproveClient(userId, isApproved, adminComments, null)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Please enter your comments"));
      }
    });
    return promise;
  }

  TabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  ItemChange1(event) {
    this.setState({ itemSelected: 1 });
  }

  ItemChange2(event) {
    this.setState({ itemSelected: 2 });
  }

  ItemTransactionChange = (event) => {
    this.setState({
      transSelectedId: event.currentTarget.dataset.transKey,
      transType: event.currentTarget.dataset.transType,
    });
  };

  ToggleAllFolders = (bool) => {
    let panels = this.state.panels;
    for (const key in panels) {
      panels[`${key}`] = !bool;
    }
    this.setState({ panels, panelsExpand: !bool });
  };

  TogglePanel = (panelName) => {
    let panels = this.state.panels;
    panels[`${panelName}`] = !panels[`${panelName}`];
    let open = 0,
      close = 0;
    for (const key in panels) {
      if (panels[`${key}`]) open++;
      else close++;
    }
    this.setState({ panels, panelsExpand: open > close });
  };

  handleManagerDropwdown = (event) => {
    this.setState({ coachId: event.target.value });
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleAlert: false });
  };

  handleDialogClose = (event) => {
    this.setState({
      toggleDialog: false,
    });
  };

  handleDialogType = (event) => {
    this.setState({
      toggleDialog: true,
      dialogType: event.currentTarget.dataset.type,
    });
  };

  handleDropzone = (files) => {
    let docs = this.state.additionalDocuments;
    docs.push(files[0]);
    this.setState({ additionalDocuments: docs });
  };

  handleDropzoneDelete = (e) => {
    let docs = this.state.additionalDocuments;
    docs.splice(e, 1);
    this.setState({ additionalDocuments: docs });
  };

  handleApproveConfirm = (isLegacy) => {
    if (this.state.dialogType === "approve") {
      this.setState({ isLoading: true });

      this.approveClient(
        this.state.clientId,
        true,
        this.state.additionalDocuments,
        isLegacy
      )
        .then((response) => {
          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: response.data.ok ? "success" : "error",
            alertMessage: response.data.message,
          });
          setTimeout(() => {
            this.getKYCs();
          }, 1000);
        })
        .catch((error) => {
          this.handleDialogClose();

          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: "error",
            alertMessage: error.message,
          });
        });
    } else if (this.state.dialogType === "assign") {
      this.setState({ isLoading: true });

      this.assignManager(this.state.clientId, this.state.coachId)
        .then((res) => {
          this.handleDialogClose();

          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: "success",
            alertMessage: "Manager assigned successfully.",
          });

          setTimeout(() => {
            this.getKYCs();
          }, 1000);
        })
        .catch((error) => {
          this.handleDialogClose();

          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: "error",
            alertMessage: error.message,
          });
        });
    } else {
      this.setState({ isLoading: true });

      this.declineClient(this.state.clientId, false, this.state.commentToAdd)
        .then((response) => {
          this.handleDialogClose();

          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: response.data.ok ? "success" : "error",
            alertMessage: response.data.message,
          });

          setTimeout(() => {
            this.getKYCs();
          }, 1000);
        })
        .catch((error) => {
          this.handleDialogClose();

          this.setState({
            isLoading: false,
            toggleDialog: false,
            toggleAlert: true,
            alertType: "error",
            alertMessage: error.message,
          });
        });
    }
  };

  GetDownloadFile = async (event) => {
    this.setState({ loadingDownload: true }, () => {
      return this.storage
        .child(event)
        .getDownloadURL()
        .then((url) => {
          window.open(url, "_blank");
          return this.setState({ loadingDownload: false });
        })
        .catch((error) => {
          alert(error);
        });
    });
  };

  ShowImage = async (url, isID) => {
    this.setState({ depositSlipLoading: true }, () => {
      if (isID) {
        window.open(url, "_blank");
        return this.setState({ depositSlipLoading: false });
      } else {
        return this.storage
          .child(url)
          .getDownloadURL()
          .then((url) => {
            window.open(url, "_blank");
            return this.setState({ depositSlipLoading: false });
          })
          .catch((error) => {
            alert(error);
          });
      }
    });
  };

  CloseImage = (event) => {
    this.setState({
      imageType: -100,
      toggleShowImage: false,
    });
  };

  handleInputChange = async (event) => {
    console.log(`${event.target.name} ->> ${event.target.value}`);
    this.setState({ [event.target.name]: event.target.value });
  };

  OpenEditDialog = (e) => {
    this.setState({ editDialogVisible: true });
  };

  CloseEditDialog = (e) => {
    this.setState({
      editDialogVisible: false,
      additionalDocuments: [],
      editDocumentsDisplay: e,
    });
  };

  removeEditItem = (e) => {
    let docs = this.state.editDocumentsDisplay;
    let item = docs.splice(e, 1);
    let toDelete = this.state.toDeleteDocument;
    toDelete.push(item[0]);
    this.setState({ editDocumentsDisplay: docs, toDelete: toDelete });
  };

  acceptEditChanges = async (e) => {
    this.setState({ editLoading: true }, () => {
      this.editDocuments
        .doEditDocuments(
          this.state.toDelete,
          this.state.additionalDocuments,
          this.state.clientId
        )
        .then((result) => {
          if (result.data.ok) {
            this.setState({ editLoading: false, additionalDocuments: [] });
            return this.db.collection("users").doc(this.state.clientId).get();
          } else throw new Error(result.message);
        })
        .then((result) => {
          this.setState({
            clientDetails: result.data(),
            additionalDocumentsDisplay: result.data().additionalDocuments,
            editDocumentsDisplay: result.data().additionalDocuments,
            editDialogVisible: false,
          });
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <ClientProfileFront
            firebase={firebase}
            state={this.state}
            onTabChange={() => this.TabChange.bind(this)}
            onItemChange1={() => this.ItemChange1.bind(this)}
            onItemChange2={() => this.ItemChange2.bind(this)}
            onToggleAllFolders={(data) =>
              this.ToggleAllFolders.bind(this, data)
            }
            onTogglePanel={(data) => this.TogglePanel.bind(this, data)}
            onhandleClose={() => this.onhandleClose.bind(this)}
            onhandleDialogClose={() => this.handleDialogClose.bind(this)}
            handleDialogType={() => this.handleDialogType.bind(this)}
            onhandleApproveConfirm={(data) => this.handleApproveConfirm(data)}
            onhandleManagerDropwdown={() =>
              this.handleManagerDropwdown.bind(this)
            }
            onShowImage={(data, data1) =>
              this.ShowImage.bind(this, data, data1)
            }
            onChange={() => this.handleInputChange.bind(this)}
            onCloseImage={() => this.CloseImage.bind(this)}
            onItemTransactionChange={() =>
              this.ItemTransactionChange.bind(this)
            }
            handleDropzone={() => this.handleDropzone.bind(this)}
            handleDropzoneDelete={(data) =>
              this.handleDropzoneDelete.bind(this, data)
            }
            GetDownloadFile={(data) => this.GetDownloadFile.bind(this, data)}
            OpenEditDialog={() => this.OpenEditDialog.bind(this)}
            CloseEditDialog={(data) => this.CloseEditDialog.bind(this, data)}
            removeEditItem={(data) => this.removeEditItem.bind(this, data)}
            acceptEditChanges={() => this.acceptEditChanges.bind(this)}
            setValidId={(isValid) => this.setValidId(isValid)}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(ClientProfilePage);
