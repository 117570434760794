import React from "react";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import {
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Zoom,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import { Search, Edit, Delete, WarningRounded } from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";

import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./css/categories.css";

import Pagination from "@material-ui/lab/Pagination";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  inlineDisplay: {
    right: 0,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#256141",
    color: theme.palette.common.white,
    fontSize: "14px !important",
    fontWeight: "bold",
    height: "65px !important",
    "@media only screen and (max-width: 1366px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
  },
  body: {
    fontSize: "14px !important",
    height: "65px !important",
    cursor: "default",
    "@media only screen and (max-width: 1366px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    textDecoration: "none !important",
  },
}))(TableRow);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C4C4C4",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C8932A",
      },
      fontSize: 15,
      "@media only screen and (max-width: 1366px)": {
        fontSize: 12,
      },
      textTransform: "capitalize !important",
    },
  },
})(TextField);

const CategoriesForm = (props) => {
  const classes = useStyles();

  const {
    search,
    pageIndex,
    pageSize,
    pageCount,
    currentPage,
    tempRows,
    toDelName,
    isLoading,
    showDialog,
    toggleAlert,
    messageType,
    messageAlert,
  } = props.state;

  return (
    <>
      <div className={"categoriesRoot"}>
        <div className={"categoriesPaper"}>
          <label variant="h5" className={"categoriesTitleText"}>
            <strong>Categories</strong>
          </label>

          <label variant="caption" className={"categoriesDescription"}>
            Consist of all fund categories
          </label>

          <Button
            variant="contained"
            className={"categoriesAddButton"}
            component={Link}
            to={ROUTES.CATEGORIES_ADD}
          >
            Add new category
          </Button>
        </div>
        <div className={"categoriesPaper2"}>
          <FormControl
            className={clsx("categoriesTextField")}
            variant="outlined"
            size="small"
          >
            <CssTextField
              name="search"
              variant="outlined"
              size="small"
              id="search"
              value={search}
              onChange={props.onhandleInputChange()}
              placeholder="Search category name..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <TableContainer>
            <Table className={"categoriesTableWidth"} aria-label="users table">
              <TableHead>
                <TableRow>
                  {[
                    { name: "Category ID", width: 100 },
                    { name: "Category Code", width: 50 },
                    { name: "Category Name", width: 300 },
                    { name: "Action", width: 100 },
                  ].map((item, index) => (
                    <StyledTableCell key={index} align="center">
                      {item.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tempRows.length > 0 ? (
                  tempRows.map(
                    (row, index) =>
                      row.categoryName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 &&
                      index + 1 >= pageIndex &&
                      index + 1 <= pageSize && (
                        <StyledTableRow key={row.categoryId}>
                          <StyledTableCell align="center">
                            {row.categoryId}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.categoryCode}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.categoryName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Tooltip TransitionComponent={Zoom} title="Edit">
                              <IconButton
                                size="small"
                                style={{ cursor: "pointer", color: "#1AB071" }}
                                component={Link}
                                to={{
                                  pathname: ROUTES.CATEGORIES_EDIT,
                                  state: {
                                    id: row.categoryId,
                                    code: row.categoryCode,
                                  },
                                }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            &nbsp;
                            <Tooltip TransitionComponent={Zoom} title="Delete">
                              <IconButton
                                size="small"
                                style={{ cursor: "pointer" }}
                                data-id={row.categoryId}
                                data-code={row.categoryCode}
                                data-name={row.categoryName}
                                onClick={props.onhandleDialogChange()}
                              >
                                <Delete fontSize="small" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan="4" align="center">
                      Category List Empty
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.inlineDisplay}>
            <br />
            <label variant="caption" stlye={{ float: "left" }}>
              <strong>
                {tempRows.length > 0
                  ? `Showing ${pageIndex} to ${pageSize} of ${tempRows.length} entries`
                  : "Showing 0 to 0 of 0 entries"}
              </strong>
            </label>

            <div style={{ float: "right", marginTop: -8 }}>
              <Pagination
                className={"categoriesPagination"}
                color="primary"
                count={pageCount}
                size="small"
                shape="rounded"
                page={currentPage}
                onChange={props.onhandlePageChange()}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onhandleDialogChange()}
      >
        <DialogTitle>
          <div className={"categoriesIconBackground"}>
            <WarningRounded className={"categoriesIcon"} />
          </div>
        </DialogTitle>
        <DialogContent>
          <label className={"categoriesDialogTitle"}>
            Are you sure you want to delete category
          </label>
          <br />
          <label className={"categoriesDialogName"}>{`${toDelName}?`}</label>
        </DialogContent>
        <br />

        {!isLoading ? (
          <>
            <Button
              onClick={props.onhandleDeleteConfirm()}
              className={"categoriesDialogDeleteBtn"}
            >
              Yes, Delete Category
            </Button>
            <Button
              onClick={props.onhandleDialogChange()}
              className={"categoriesDialogCancelBtn"}
            >
              Cancel
            </Button>
          </>
        ) : (
          <CircularProgress className={"categoriesLoading"} />
        )}
        <br />
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CategoriesForm;
