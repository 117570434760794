import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import styles from "./Button.module.css";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  // to and component are props that are used by react-router-dom, there might be better ways to do this but this works for now
  to?: string;
  component?: ReactNode;
} & ButtonProps;

export default function Button({ children, ...props }: Props) {
  return (
    <MuiButton
      variant="contained"
      className={`${styles.button} ${props.className}`}
      color="primary"
      style={{
        color: "#fff",
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
