import React, { Component } from "react";

import {
  Button,
  Slide,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";

import { WarningRounded } from "@material-ui/icons";

import "../../Users/ClientProfile/css/clientProfile.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomModal extends Component {
  render() {
    const { toggleDialog, onClose } = this.props;

    return (
      <Dialog
        open={toggleDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <div className={"dialogIconBackgroundDecline"}>
          <WarningRounded className={"dialogIcon"} />
        </div>
        <DialogContent>
          <label className={"dialogTitle"}>Discard Changes</label>
          <br />
          <br />
          <label className={"dialogDescription"}>
            Are you sure to discard changes?
          </label>
        </DialogContent>
        <br />
        <Button
          name="dialogCancel"
          onClick={onClose}
          className={"dialogApproveButton"}
        >
          Yes, Cancel
        </Button>
        <Button onClick={onClose} className={"dialogCancelButton"}>
          Cancel
        </Button>
      </Dialog>
    );
  }
}

export default CustomModal;
