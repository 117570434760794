import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "./Button";
import { ReactNode } from "react";
import { ButtonProps } from "@material-ui/core/Button";

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

export default function CustomModal({ open, onClose, children }: Props) {
  return (
    <div
      className={`bg-black fixed top-0 left-0 right-0 bottom-0 justify-center items-center ${
        open ? "flex" : "hidden"
      }`}
      style={{
        // this should just be the right z-index so that it's above the navigation, and under the dropdowns
        zIndex: 1300,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div className="bg-white max-w-xl w-full rounded shadow p-8 pb-12 px-12">
        <div className="text-right">
          <IconButton
            aria-label="close modal"
            style={{
              transform: "translateX(20px)",
            }}
            onClick={onClose}
          >
            <CloseIcon
              style={{
                color: "#9CA3AF",
              }}
            />
          </IconButton>
        </div>
        {children}
      </div>
    </div>
  );
}

CustomModal.Title = Title;
CustomModal.Footer = Footer;
CustomModal.SubmitButton = SubmitButton;

function Title({ children }: { children: ReactNode }) {
  return <h1>{children}</h1>;
}

function Footer({ children }: { children: ReactNode }) {
  return <div className="flex justify-end mt-8 gap-6">{children}</div>;
}

type SubmitButtonProps = {
  children: ReactNode;
} & ButtonProps;

function SubmitButton({ children, ...props }: SubmitButtonProps) {
  return (
    <Button type="submit" {...props}>
      {children}
    </Button>
  );
}
