import { CLIENT_BASE_URL } from "../constants/routes";
import Navigation from "./Navigation";
import CustomDataTable from "./CustomDataTable";
import LinkIcon from "@material-ui/icons/Link";
import IconButton from "@material-ui/core/IconButton";
import Button from "./Button";
import { useState, useEffect, MouseEvent } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "./Firebase/firebase";
import { useParams } from "react-router-dom";
import { copyTextToClipboard } from "../utils";
import MoreIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextInput from "./TextInput";
import Modal from "./Modal";
import Select from "./Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { Referrer } from "@/types";
import withAuthentication from "@/hoc/withAuthentication";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import { useForm, Controller } from "react-hook-form";
import useNotify from "@/hooks/useNotify";
import { isReferralCodeUnique as checkIfReferralCodeIsUnique } from "@/services";
import { trimAllWhiteSpace } from "@/utils";
import { isReferralCodeUnique } from "@/services";
import { removeSpecialCharactersFromString } from "@/utils";

type ReferrerProfile = {
  subAccounts: Referrer[];
} & Referrer;

type AddSubAccountFormData = {
  firstName: string;
  lastName: string;
  phone: string;
  referralCode: string;
};

type RefLinkFormData = {
  referralCode: string;
};

type TransferAccountFormData = {
  referralManager: string;
};

const ReferrerProfilePage = () => {
  const { id: referralManagerId } = useParams<{ id: string }>();
  const notify = useNotify();
  const [isSubAccountModalOpen, setIsSubAccountModalOpen] = useState(false);
  const [isCreateReferralLinkModalOpen, setIsCreateReferralLinkModalOpen] =
    useState(false);
  // fetch managers for transfer account modal
  const [managers, setManagers] = useState<Referrer[]>([]);
  const [referrerProfile, setReferrerProfile] =
    useState<ReferrerProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [transferAccount, setTransferAccount] = useState<Referrer | null>(null);
  const [selectedRM, setSelectedRM] = useState<string | null>(null);
  const [isEditReferralLinkModalOpen, setIsEditReferralLinkModalOpen] =
    useState(false);
  const {
    register: addSubAccountFormRegister,
    handleSubmit: addSubAccountFormHandleSubmit,
    reset: addSubAccountFormReset,
    formState: { errors: addSubAccountFormErrors },
  } = useForm<AddSubAccountFormData>();
  const {
    register: refLinkFormRegister,
    handleSubmit: refLinkFormHandleSubmit,
    setValue: refLinkFormSetValue,
    watch: createRefLinkFormWatch,
    formState: { errors: refLinkFormErrors },
  } = useForm<RefLinkFormData>();
  const {
    handleSubmit: transferAccountFormHandleSubmit,
    control: transferAccountFormControl,
  } = useForm<TransferAccountFormData>();

  // when you click more options on a sub account, this is the sub account that is clicked. This prevents the menu from opening on all sub accounts
  const [subAccountMoreOptionsOpen, setSubAccountMoreOptionsOpen] = useState<
    string | null
  >(null);
  const history = useHistory();

  // Options available for the sub account when transferring to a different parent account
  const RMSelectOptions =
    managers.length > 0
      ? managers.map((manager) => ({
          value: manager.userId as string, // if manager, userId is defined
          label: `${manager.firstName} ${manager.lastName}`,
        }))
      : [];
  // returns referral managers that are not the current referral manager of the sub account
  const filteredRMSelectOptions = RMSelectOptions.filter(
    (rm) => rm.value !== referrerProfile?.userId
  );

  // fetch referral managers, active rm profile and its sub accounts
  useEffect(() => {
    (async () => {
      try {
        // fetch managers as it is needed for transfer sub account modal
        const q = query(
          collection(db, "referrers"),
          where("parentUserId", "==", null)
        );

        const querySnapshot = await getDocs(q);

        const managers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Referrer[];

        const activeProfileQuery = doc(db, "referrers", referralManagerId);

        const activeProfileSnapshot = await getDoc(activeProfileQuery);

        if (!activeProfileSnapshot.exists()) {
          throw new Error(
            "Failed to fetch rm profile, profile does not exist."
          );
        }

        const activeProfile = {
          id: activeProfileSnapshot.id,
          ...activeProfileSnapshot.data(),
        } as Referrer;

        // if active profile is a sub account, don't fetch sub accounts
        if (activeProfile.parentUserId) {
          setReferrerProfile({
            ...activeProfile,
            subAccounts: [],
          });
          setManagers(managers);

          return;
        }

        // fetch sub accounts
        const subAccountsQuery = query(
          collection(db, "referrers"),
          where("parentUserId", "==", activeProfile.userId)
        );

        const subAccountsQuerySnapshot = await getDocs(subAccountsQuery);

        const subAccounts = subAccountsQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Referrer[];

        const RMProfileWithSubAccounts = {
          ...activeProfile,
          subAccounts,
        };

        setReferrerProfile(RMProfileWithSubAccounts);
        setManagers(managers);
      } catch (e) {
        console.log(e);
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [referralManagerId]);

  useEffect(() => {
    if (filteredRMSelectOptions.length > 0) {
      setSelectedRM(filteredRMSelectOptions[0].value);
    }
  }, [filteredRMSelectOptions]);

  // gets the referral code from the form or from the referrer profile
  const referralCode = createRefLinkFormWatch("referralCode");

  const referralLinkPreview = `${CLIENT_BASE_URL}/ref/${referralCode}`;

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "phone",
      label: "Number",
      options: {
        filter: false,
      },
    },
    {
      name: "referralLink",
      label: "Referral Link",
      options: {
        filter: false,
      },
    },
    {
      name: "actions",
      label: " ",
      options: {
        filter: false,
      },
    },
  ];

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;

  // opens the menu for more options on a sub account
  const handleClickMoreOptions = (
    event: MouseEvent<HTMLButtonElement>,
    subAccountId: string
  ) => {
    event.stopPropagation();

    setSubAccountMoreOptionsOpen(subAccountId);
    setAnchorEl(event.currentTarget);
  };

  const data = referrerProfile?.subAccounts?.map((subAccount, index) => ({
    name: `${subAccount.firstName} ${subAccount.lastName}`,
    phone: subAccount.phone,
    referralLink: (
      <IconButton
        aria-label="referral link"
        onClick={(e) => {
          e.stopPropagation();

          if (!subAccount.referralCode) {
            notify("Referral link not available for this user.", "error");
            return;
          }

          copyTextToClipboard(
            `${CLIENT_BASE_URL}/ref/${subAccount.referralCode}`
          );

          notify("Copied to clipboard.", "success");
        }}
      >
        <LinkIcon />
      </IconButton>
    ),
    actions: (
      <div className="relative">
        <IconButton
          aria-label="more"
          onClick={(e) => handleClickMoreOptions(e, subAccount.id)}
        >
          <MoreIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={
            Boolean(anchorEl) && subAccountMoreOptionsOpen === subAccount.id
          }
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();

              if (!filteredRMSelectOptions.length) {
                setAnchorEl(null);
                notify("No other referral managers available.", "error");
                return;
              }

              setAnchorEl(null);
              setTransferAccount(subAccount);
            }}
          >
            Transfer Account
          </MenuItem>
        </Menu>
      </div>
    ),
  }));

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    customToolBar: () => <div>sadsdasad</div>,
    selectableRowsHideCheckboxes: true,
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData: any, rowMeta: { dataIndex: number }) => {
      // id of the referrer in sub accounts that was clicked
      const referrerId = referrerProfile?.subAccounts[rowMeta.dataIndex].id;

      history.push({
        pathname: `/referrers/profiles/${referrerId}`,
      });
    },
  };

  const handleAddSubAccountToRM = addSubAccountFormHandleSubmit(
    async (data) => {
      try {
        const { firstName, lastName, phone, referralCode } = data;

        if (!referrerProfile) throw new Error("Referrer profile not found.");

        const newReferrer: Omit<Referrer, "id"> = {
          firstName,
          lastName,
          phone,
          parentUserId: referrerProfile?.userId,
          email: null,
          referralCode,
          userId: null,
        };

        const docRef = await addDoc(collection(db, "referrers"), newReferrer);

        // set rm profile state to include added sub account
        setReferrerProfile((prevProfile) => {
          if (!prevProfile) throw new Error("Referrer profile not found.");

          return {
            ...prevProfile,
            subAccounts: [
              ...prevProfile.subAccounts,
              {
                firstName,
                lastName,
                phone,
                parentUserId: referralManagerId,
                email: null,
                referralCode,
                id: docRef.id,
                userId: null,
              },
            ],
          };
        });

        addSubAccountFormReset();

        setIsSubAccountModalOpen(false);
      } catch {
        notify("Failed to add sub account.", "error");
      }
    }
  );

  const handleCreateReferralLink = refLinkFormHandleSubmit(async (data) => {
    try {
      const trimmedCode = trimAllWhiteSpace(data.referralCode);

      const isReferralCodeUnique = await checkIfReferralCodeIsUnique(
        trimmedCode
      );

      if (!isReferralCodeUnique) {
        alert("Referral code already exists.");
        return;
      }

      await updateDoc(doc(db, "referrers", referralManagerId), {
        referralCode: trimmedCode,
      });

      // update referrerProfile state to include referral code
      setReferrerProfile((prevProfile) => {
        if (!prevProfile) throw new Error("Referrer profile not found.");

        return {
          ...prevProfile,
          referralCode: trimmedCode,
        };
      });

      setIsCreateReferralLinkModalOpen(false);
    } catch (e) {
      // there is no permission to update a referral code, this means that the referral code already exists using setDoc
      if (
        e instanceof Error &&
        e.message === "Missing or insufficient permissions."
      ) {
        notify("Referral code already exists.", "error");
      }
      notify("Failed to create referral link.", "error");
    }
  });

  const handleEditReferralLink = refLinkFormHandleSubmit(async (data) => {
    try {
      const trimmedCode = trimAllWhiteSpace(data.referralCode);
      // const trimmedCode = trimAllWhiteSpace(code);

      const isReferralCodeUnique = await checkIfReferralCodeIsUnique(
        trimmedCode
      );

      if (!isReferralCodeUnique) {
        alert("Referral code already exists.");
        return;
      }

      await updateDoc(doc(db, "referrers", referralManagerId), {
        referralCode: trimmedCode,
      });

      // update referrerProfile state to include referral code
      setReferrerProfile((prevProfile) => {
        if (!prevProfile) throw new Error("Referrer profile not found.");
        return {
          ...prevProfile,
          referralCode: trimmedCode,
        };
      });

      setIsEditReferralLinkModalOpen(false);
    } catch (e) {
      notify("Failed to edit referral link.", "error");
    }
  });

  const handleTransferAccount = transferAccountFormHandleSubmit(
    async (data) => {
      try {
        if (!transferAccount) throw new Error("Transfer account not found.");

        await updateDoc(doc(db, "referrers", transferAccount.id), {
          parentUserId: selectedRM,
        });

        // remove transfer account from sub accounts
        setReferrerProfile((prevProfile) => {
          if (!prevProfile) throw new Error("Referrer profile not found.");

          return {
            ...prevProfile,
            subAccounts: prevProfile.subAccounts.filter(
              (subAccount) => subAccount.id !== transferAccount.id
            ),
          };
        });

        setTransferAccount(null);
      } catch (e) {
        notify("Failed to transfer account.", "error");
      }
    }
  );

  const handleRemoveReferralLink = async (refferrerProfileId: string) => {
    try {
      await updateDoc(doc(db, "referrers", refferrerProfileId), {
        referralCode: null,
      });

      refLinkFormSetValue("referralCode", "");

      // update referrerProfile state to include referral code
      setReferrerProfile((prevProfile) => {
        if (!prevProfile) throw new Error("Referrer profile not found.");
        return {
          ...prevProfile,
          referralCode: null,
        };
      });

      setIsEditReferralLinkModalOpen(false);
      setIsCreateReferralLinkModalOpen(false);
    } catch (e) {
      notify("Failed to remove referral link.", "error");
    }
  };

  return (
    <div>
      <Navigation
        content={
          <div className="p-16" style={{ marginLeft: "260px" }}>
            <h1>
              {referrerProfile?.parentUserId
                ? "Referrer Profile"
                : "Referral Manager Profile"}
            </h1>

            {/* referral manager profile details */}
            <div
              className="grid gap-4 mt-12"
              style={{
                gridTemplateColumns: "100px minmax(200px, 1fr)",
              }}
            >
              <p className="m-0 font-bold col-auto">Name</p>
              <p className="m-0">{`${referrerProfile?.firstName} ${referrerProfile?.lastName}`}</p>
              {/* sub accounts don't have email */}
              {!referrerProfile?.parentUserId && (
                <>
                  <p className="m-0 font-bold">Email</p>
                  <p className="m-0">{referrerProfile?.email}</p>
                </>
              )}
              <p className="m-0 font-bold">Number</p>
              <p className="m-0">{referrerProfile?.phone}</p>
              <p className="m-0 font-bold self-center ">Referral Link</p>
              {/* if referral manager already has a referral code, show the referral link; otherwise, show the 'create referral link' button */}
              <div>
                {referrerProfile?.referralCode && (
                  <div className="inline-flex gap-4 items-center">
                    <p className="m-0">
                      {`${CLIENT_BASE_URL}/ref/${referrerProfile.referralCode}`}
                    </p>
                    <div
                      style={{
                        color: "green",
                      }}
                    >
                      <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setIsEditReferralLinkModalOpen(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </div>
                )}

                {!referrerProfile?.referralCode && (
                  <IconButton aria-label="Create referral link" size="small">
                    <AddCircleIcon
                      color="primary"
                      onClick={() => setIsCreateReferralLinkModalOpen(true)}
                    />
                  </IconButton>
                )}
              </div>
            </div>
            {/* don't render sub accounts table if profile is a sub account */}
            {!referrerProfile?.parentUserId && (
              <>
                <div className="flex items-center mt-12">
                  <h2 className="flex-1">Sub Accounts</h2>
                  <Button onClick={() => setIsSubAccountModalOpen(true)}>
                    Add Sub Account
                  </Button>
                </div>
                <CustomDataTable
                  columns={columns}
                  options={options}
                  data={data}
                />
              </>
            )}
          </div>
        }
      />
      {/* add sub account modal */}
      <Modal
        open={isSubAccountModalOpen}
        onClose={() => setIsSubAccountModalOpen(false)}
      >
        <Modal.Title>Add Sub Account</Modal.Title>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleAddSubAccountToRM}
        >
          <TextInput
            label="First Name"
            {...addSubAccountFormRegister("firstName", {
              required: {
                value: true,
                message: "First name is required",
              },
              minLength: {
                value: 2,
                message: "First name is too short",
              },
              maxLength: {
                value: 128,
                message: "First name is too long",
              },
            })}
            error={!!addSubAccountFormErrors.firstName}
            helperText={addSubAccountFormErrors.firstName?.message}
          />

          <TextInput
            label="Last Name"
            {...addSubAccountFormRegister("lastName", {
              required: {
                value: true,
                message: "Last name is required",
              },
              minLength: {
                value: 2,
                message: "Last name is too short",
              },
              maxLength: {
                value: 128,
                message: "Last name is too long",
              },
            })}
            error={!!addSubAccountFormErrors.lastName}
            helperText={addSubAccountFormErrors.lastName?.message}
          />

          <TextInput
            label="Phone"
            type="tel"
            {...addSubAccountFormRegister("phone", {
              required: {
                value: true,
                message: "Phone is required",
              },
              minLength: {
                value: 2,
                message: "Phone is too short",
              },
              maxLength: {
                value: 128,
                message: "Phone is too long",
              },
            })}
            error={!!addSubAccountFormErrors.phone}
            helperText={addSubAccountFormErrors.phone?.message}
          />

          <TextInput
            label="Referral Code"
            {...addSubAccountFormRegister("referralCode", {
              required: {
                value: true,
                message: "Referral code is required",
              },
              validate: {
                isRefCodeUnique: async (value) => {
                  const isRefCodeUnique = await isReferralCodeUnique(value);

                  if (!isRefCodeUnique) {
                    return "Referral code already exists";
                  }

                  return true;
                },
              },
              minLength: {
                value: 4,
                message: "Referral code is too short",
              },
              maxLength: {
                value: 20,
                message: "Referral code is too long",
              },
              onChange: (e) => {
                e.target.value = removeSpecialCharactersFromString(
                  e.target.value
                ).toLowerCase();
              },
            })}
            error={!!addSubAccountFormErrors.referralCode}
            helperText={addSubAccountFormErrors.referralCode?.message}
          />

          <ReferralLinkGuideLine className="mt-4" />

          <Modal.Footer>
            <Modal.SubmitButton>Add Sub Account</Modal.SubmitButton>
          </Modal.Footer>
        </form>
      </Modal>

      {/* transfer account modal */}
      {/* only render this modal if there is a selected referral manager and there are available referral manager  that are not currently the parent referral manager of the sub account or else it will error out due to select not expecting an empty array*/}
      {filteredRMSelectOptions.length > 0 && selectedRM && (
        <Modal
          open={!!transferAccount}
          onClose={() => setTransferAccount(null)}
        >
          <Modal.Title>Transfer Account</Modal.Title>
          <form
            className="flex flex-col gap-4"
            onSubmit={handleTransferAccount}
          >
            <div className="mt-4">
              <Controller
                control={transferAccountFormControl}
                name="referralManager"
                defaultValue={filteredRMSelectOptions[0].value}
                render={({ field: { onChange, value } }) => (
                  <Select
                    autoWidth
                    label="Referral Manager"
                    options={filteredRMSelectOptions}
                    // wanted the menu to be as wide as the select, but can't achieve it using width: 100%, so I used this hack instead
                    MenuProps={{
                      PaperProps: {
                        style: {
                          width: "480px",
                        },
                      },
                    }}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    value={value}
                  />
                )}
              />
            </div>

            <Modal.Footer>
              <Modal.SubmitButton>Transfer Account</Modal.SubmitButton>
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {/* create or update referral link modal */}
      {referrerProfile && (
        <Modal
          open={isCreateReferralLinkModalOpen || isEditReferralLinkModalOpen}
          onClose={() => {
            setIsCreateReferralLinkModalOpen(false);
            setIsEditReferralLinkModalOpen(false);
          }}
        >
          <Modal.Title>
            {isEditReferralLinkModalOpen ? "Edit " : "Create "}
            Referral Link
          </Modal.Title>
          <form
            className="flex flex-col gap-4"
            onSubmit={
              isEditReferralLinkModalOpen
                ? handleEditReferralLink
                : handleCreateReferralLink
            }
          >
            <TextInput
              label="Referral Code"
              {...refLinkFormRegister("referralCode", {
                required: {
                  value: true,
                  message: "Referral code is required",
                },
                validate: {
                  isRefCodeUnique: async (value) => {
                    const isRefCodeUnique = await isReferralCodeUnique(value);

                    if (!isRefCodeUnique) {
                      return "Referral code already exists";
                    }

                    return true;
                  },
                },
                minLength: {
                  value: 4,
                  message: "Referral code is too short",
                },
                maxLength: {
                  value: 20,
                  message: "Referral code is too long",
                },
                // force input to be alphanumberic and in lowercase
                onChange: (e) => {
                  const code = removeSpecialCharactersFromString(
                    e.target.value
                  ).toLowerCase();

                  e.target.value = code;

                  // the first special character typed goes through in preview so this is need to prevent that
                  refLinkFormSetValue("referralCode", code);
                },
              })}
              error={!!refLinkFormErrors.referralCode}
              helperText={refLinkFormErrors.referralCode?.message}
            />
            <TextInput
              label="Preview"
              variant="standard"
              multiline
              disabled
              value={referralLinkPreview}
            />

            <ReferralLinkGuideLine className="mt-4" />

            <Modal.Footer>
              <Button
                color="secondary"
                onClick={() => handleRemoveReferralLink(referrerProfile.id)}
              >
                Remove Referral Link
              </Button>
              <Modal.SubmitButton>
                {isEditReferralLinkModalOpen ? "Edit " : "Create "}
                Referral Link
              </Modal.SubmitButton>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default withAuthentication(ReferrerProfilePage);

export function ReferralLinkGuideLine({ className }: { className: string }) {
  return (
    <div className={className}>
      <b>Referral codes are:</b>
      <ul className="p-0 pl-8">
        <li>Alphanumeric strictly (e.g. referralcode123, code123sample)</li>
        <li>Enforced to lowercase</li>
        <li>4-20 characters only</li>
      </ul>
    </div>
  );
}
