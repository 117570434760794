import React, { Component } from "react";

import { withFirebase } from "../../Firebase";
import firebase from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Grid, Button } from "@material-ui/core";
import withAuthorization from "../../Session/withAuthorization";
import { Download } from "./Export";
import NavigationMenus from "../../Navigation";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { Divider } from "@material-ui/core";
import Helpers from "../../Helpers";
import "./css/InvestmentDetails.css";

const INITIAL_STATE = {
  userAccountNumber: "",
  clientName: "",
  fundName: "",
  fundCode: "",
  companyName: "",
  amount: "",
  applicationDate: "",
  applicationTime: "",
  transactionId: "",
  approvedBy: "",
  status: "",
  investmentType: "",
  curAddress: "",
  permAddress: "",
  birthday: "",
  placeOfBirth: "",
  nationality: "",
  occupation: "",
  contactNum: "",
  SSS: "",
  TIN: "",
  sourceOfFunds: "",
  toggleAlert: false,
  alertType: "warning",
  alertMessage: "",
};

const ClientProfilePage = () => {
  return <NavigationMenus content={<InvestmentDetailForm />} />;
};

class InvestmentDetailPageBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    //this.db = firebase.firestore();
    //this.storage = firebase.storage().ref();
    this.code = new URLSearchParams();
    this.helpers = new Helpers();
  }

  async componentDidMount() {
    await this.getInvestmentDetails(this.props.match.params.code);
    await this.getPersonalDetails(this.state.userAccountNumber);
  }

  async getPersonalDetails(code) {
    await this.props.firebase.db
      .collection("users")
      .doc(code)
      .collection("KYC")
      .doc("KYC")
      .get()
      .then((result) => {
        let data = result.data();

        let currentAdd = `${data.currentAddress.street} ${data.currentAddress.barangay}, ${data.currentAddress.city} ${data.currentAddress.zip}, ${data.currentAddress.city} ${data.currentAddress.province}`;
        let permAdd = `${data.permanentAddress.street} ${data.permanentAddress.barangay}, ${data.permanentAddress.city} ${data.permanentAddress.zip}, ${data.permanentAddress.city} ${data.permanentAddress.province}`;

        this.setState({
          curAddress: currentAdd,
          permAddress: permAdd,
          birthday: data.dateOfBirth,
          placeOfBirth:
            data.placeOfBirth !== undefined && data.placeOfBirth !== ""
              ? data.placeOfBirth
              : "N/A",
          nationality:
            data.nationality !== undefined && data.nationality !== ""
              ? data.nationality
              : "N/A",
          occupation: data.work.primaryOccupation,
          contactNum: `(+63)${data.mobileNumber}`,
          SSS: data.ids.SSS,
          TIN: data.ids.TIN,
          sourceOfFunds: data.work.sourceOfFunds,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  async getInvestmentDetails(code) {
    let dateFormat = this.helpers.dateFormat;

    await this.props.firebase.db
      .collection("transactions")
      .where("eventId", "==", code)
      .get()
      .then((result) => {
        let data = result.docs[0].data();

        let first_name =
          data.clientDetails !== undefined &&
          data.clientDetails.userFirstName !== ""
            ? data.clientDetails.userFirstName
            : "N/A";
        let last_name =
          data.clientDetails !== undefined &&
          data.clientDetails.userLastName !== ""
            ? data.clientDetails.userLastName
            : "N/A";

        let fullName = `${first_name} ${last_name}`;

        let app_time, app_date;

        if (
          data.approvedBy !== undefined &&
          data.approvedBy.approvedByManagerDate !== undefined
        ) {
          var date = data.approvedBy.approvedByManagerDate
            .toDate()
            .toDateString();
          app_time = data.approvedBy.approvedByManagerDate
            .toDate()
            .toLocaleTimeString();
          app_date = dateFormat(date);
        } else {
          app_time = "N/A";
          app_date = "N/A";
        }

        this.setState({
          userAccountNumber:
            data.clientDetails !== undefined ||
            data.clientDetails.userUid !== ""
              ? data.clientDetails.userUid
              : "N/A",
          clientName: fullName,
          fundName:
            data.fundDetails !== undefined && data.fundDetails.fundName !== ""
              ? data.fundDetails.fundName
              : "N/A",
          fundCode:
            data.fundDetails !== undefined && data.fundDetails.fundCode !== ""
              ? data.fundDetails.fundCode
              : "N/A",
          companyName:
            data.fundCompanyDetails !== undefined &&
            data.fundCompanyDetails.fundCompanyName !== ""
              ? data.fundCompanyDetails.fundCompanyName
              : "N/A",
          amount:
            data.amount !== undefined && data.amount !== ""
              ? data.amount
              : "N/A",
          applicationDate: app_date,
          applicationTime: app_time,
          transactionId:
            data.eventId !== undefined && data.eventId !== ""
              ? data.eventId
              : "N/A",
          approvedBy:
            data.approvedBy !== undefined &&
            data.approvedBy.approvedByManagerName !== ""
              ? data.approvedBy.approvedByManagerName
              : "N/A",
          status:
            data.status !== undefined && data.status !== ""
              ? data.status
              : "N/A",
          investmentType:
            data.investmentType !== undefined && data.investmentType !== ""
              ? data.investmentType
              : "N/A",
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  render() {
    return (
      <>
        <div className="cms-main-content">
          <Button
            onClick={() => this.props.history.goBack()}
            color="primary"
            startIcon={<ArrowBackIosOutlinedIcon></ArrowBackIosOutlinedIcon>}
          >
            Return
          </Button>
          <div className="body-content">
            <div className="transaction-list">
              <h1>Investment Details</h1>
              <br /> <br />
              <Download
                data={this.state}
                button={
                  <Button
                    variant="contained"
                    color="primary"
                    className="csv_link"
                  >
                    Download as Excel
                  </Button>
                }
                filename={`InvestmentDetails-${this.state.transactionId}`}
              />
              <Divider />
              <br /> <br />
              <div className={"content2Title"}>
                <div>
                  <label className={"content2ItemTitle"}>
                    {this.state.fundName}
                  </label>
                  <br />
                  <label className={"content2ItemDescription"}>
                    {`Transaction ID: ${this.props.match.params.code}`}
                  </label>
                </div>
              </div>
              <table className={"detailsTable"}>
                <tbody>
                  <tr>
                    <td>Fund Name</td>
                    <td>{this.state.fundName ? this.state.fundName : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Client Name</td>
                    <td>
                      {this.state.clientName ? this.state.clientName : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Fund Code</td>
                    <td>{this.state.fundCode ? this.state.fundCode : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td>
                      {this.state.companyName ? this.state.companyName : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount</td>
                    <td>{this.state.amount ? this.state.amount : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Approved By</td>
                    <td>
                      {this.state.approvedBy ? this.state.approvedBy : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Application Date</td>
                    <td>
                      {this.state.applicationDate
                        ? this.state.applicationDate
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Application Time</td>
                    <td>
                      {this.state.applicationTime
                        ? this.state.applicationTime
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Transaction ID</td>
                    <td>
                      {this.state.transactionId
                        ? this.state.transactionId
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{this.state.status ? this.state.status : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Investment Type</td>
                    <td>
                      {this.state.investmentType
                        ? this.state.investmentType
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Current Address</td>
                    <td>
                      {this.state.curAddress ? this.state.curAddress : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Permanent Address</td>
                    <td>
                      {this.state.permAddress ? this.state.permAddress : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td>{this.state.birthday ? this.state.birthday : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Place of Birth</td>
                    <td>
                      {this.state.placeOfBirth
                        ? this.state.placeOfBirth
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Nationality</td>
                    <td>
                      {this.state.nationality ? this.state.nationality : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Occupation</td>
                    <td>
                      {this.state.occupation ? this.state.occupation : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Source Of Funds</td>
                    <td>
                      {this.state.sourceOfFunds
                        ? this.state.sourceOfFunds
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>
                      {this.state.contactNum ? this.state.contactNum : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>SSS</td>
                    <td>{this.state.SSS ? this.state.SSS : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>TIN</td>
                    <td>{this.state.TIN ? this.state.TIN : "N/A"}</td>
                  </tr>
                </tbody>
              </table>
              {/* <Download
                            //data={props.transactions_list_pending}
                            button={
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="csv_link"
                                >
                                    Generate Excel File
                            </Button>
                            }
                            filename="Transactions"
                            //status={props.status}
                        /> */}
            </div>
          </div>
        </div>

        <label
          variant="caption"
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const InvestmentDetailForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(InvestmentDetailPageBase);

export default ClientProfilePage;

export { InvestmentDetailForm };
