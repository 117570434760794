import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { OutlinedInput, FormHelperText } from "@material-ui/core";

class OutlinedInputValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      value,
      ...rest
    } = this.props;

    const { isValid } = this.state;

    return (
      <>
        <OutlinedInput {...rest} error={!isValid || error} />
        {this.errorText()}
      </>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <FormHelperText style={{ color: "red" }}>
        {this.getErrorMessage()}
      </FormHelperText>
    );
  }
}

export default OutlinedInputValidator;
