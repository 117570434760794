import React, { useEffect, useMemo } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Box, CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";


import { withFirebase } from "../../Firebase";
import withAuthorization from "../../Session/withAuthorization";
import Navigation from '../../Navigation';
import { useAUMFetch, useClientDataFetch, useClientGraph, DashboardHeader, SummaryBox, useSummaryFetch, useAUMGraphFetch } from '../common';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    minWidth: "150px",
    maxWidth: "150px",
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#4D4D4D",
  },
  itemHover: {
    "&:hover": {
      backgroundColor: "#e4e3e3",
      cursor: "pointer"
    },
  },
}));

const MyRechartsAreaChart = ({ data, color }) => (
  <ResponsiveContainer width="100%" height={220}>
    <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
      <defs>
        <linearGradient
          id={`${color === "#82ca9d" ? "client" : "aum"}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="5%" stopColor={color} stopOpacity={0.8} />
          <stop offset="95%" stopColor="white" topOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />

      <Area
        type="monotone"
        dataKey={`${color === "#82ca9d" ? "Client" : "AUM"}`}
        stroke={color}
        fillOpacity={1}
        fill={`${color === "#82ca9d" ? "url(#client)" : "url(#aum)"}`}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export const DisplayBox = ({ value, title, subtitle, index, action }) => {
  const classes = useStyles();

  return (
    <Paper key={index}>
      {action && <h1 className=' text-center'>{value}</h1>}
      <Box className={classes.boxHeader} style={{marginTop: "20px"}}>
        {!action && <h1>{value}</h1>}
        <Box className={classes.titleContainer}>
          <b className={classes.text}>{title}</b>
          <b className={classes.text}>{subtitle}</b>
        </Box>
      </Box>
    </Paper>
  );
}

const AnalyticsDashboard = (props) => {
  const classes = useStyles();

  const { summary } = useSummaryFetch();

  const { data: clientGraphData, loading: clientGraphLoading, refresh: clientGraphRefresh } = useClientGraph();
  const { data: clientData, loading: clientDataLoading, refresh: clientDataRefresh } = useClientDataFetch();
  const { data: aumData, loading: aumDataLoading, refresh: aumDataRefresh } = useAUMFetch();
  const { data: aumGraphData, loading: aumGraphLoading, refresh: aumGraphRefresh } = useAUMGraphFetch();

  const loading = clientGraphLoading === true || clientDataLoading === true || aumDataLoading === true || aumGraphLoading === true;

  const refreshData = async () => {
    clientGraphRefresh();
    clientDataRefresh();
    aumGraphRefresh();
    aumDataRefresh();
  }
  useEffect(()=>{



  },[])

  const emailDisplay = useMemo(() => {
    return [
      {
        title: "Email Verified",
        subtitle: "(Tier 1)",
        value: clientData.tier1,
      },
      {
        title: "Identity Verified",
        subtitle: "(Tier 2)",
        value: clientData.tier2,
      },
      {
        title: "Fully Verified",
        subtitle: "(Tier 3)",
        value: clientData.tier3,
      },
    ];
  }, [clientData]);

  const assetsDisplay = useMemo(() => {
    return [
      {
        title: "Tier 2 AUM",
        subtitle: "( < 50,000 )",
        value: aumData.tier2AUM,
      },
      {
        title: "Identity Verified",
        subtitle: "( > 50,000 )",
        value: aumData.identity,
      },
    ];
  }, [aumData.tier2AUM, aumData.identity]);

  return (
    <div className={"dashboardRoot"}>
      <div className={"dashboardMasterContainer"}>
        <span className={"dashboardLabel"}>Analytics Dashboard</span>

        <Grid container spacing={4} style={{ marginTop: "20px" }}>
          {/*
           *****************Summary Display*****************
           */}
          <Grid item xs={12}>
            <SummaryBox />
          </Grid>

          {/*
           *****************Data Overview Display*****************
           */}
          <Grid item xs={12}>
            <DashboardHeader
              title={"Data Overview"}
              enableRefresh
              onRefresh={refreshData}
            />
            {/*
             *****************Data Overview Line 1*****************
             */}
            {loading ? (
              <Grid item xs={12}>
                <div className=" text-center">
                  <CircularProgress />
                  <div style={{ marginTop: "10px" }}>Loading...</div>
                </div>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={3}>
                    <Paper className={classes.paper}>
                      <Box className={classes.box}>
                        <h1 style={{ fontSize: "52px" }}>
                          {summary?.client ?? "-"}
                        </h1>
                        <span>Total Clients</span>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridGap="10px"
                      height="100%"
                    >
                      {emailDisplay.map((item, index) => (
                        <DisplayBox
                          value={item.value}
                          title={item.title}
                          subtitle={item.subtitle}
                          index={index}
                        />
                      ))}
                    </Box>
                  </Grid>

                  <Grid item xs>
                    <Paper className={classes.paper}>
                      <h2
                        style={{
                          margin: "20px 0px 20px 0px",
                          textAlign: "left",
                          paddingLeft: "40px",
                        }}
                      >
                        Total Client
                      </h2>
                      <MyRechartsAreaChart
                        data={clientGraphData}
                        color="#82ca9d"
                      />
                    </Paper>
                  </Grid>
                </Grid>

                {/*
                 *****************Data Overview Line 2*****************
                 */}

                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={3}>
                    <Paper className={classes.paper}>
                      <Box className={classes.box}>
                        <h1>{summary?.aum ?? "-"}</h1>
                        <span>Total AUM</span>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gridGap="10px"
                      height="100%"
                    >
                      {assetsDisplay.map((item, index) => (
                        <DisplayBox
                          value={item.value}
                          title={item.title}
                          subtitle={item.subtitle}
                          index={index}
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Paper className={classes.paper}>
                      <h2
                        style={{
                          margin: "20px 0px 20px 0px",
                          textAlign: "left",
                          paddingLeft: "40px",
                        }}
                      >
                        Total AUM
                      </h2>
                      <MyRechartsAreaChart
                        data={aumGraphData}
                        color="#C8932A"
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const AnalyticsPage = () => {
  return (
    <>
      <Navigation content={<Analytics />} />
    </>
  );
};

const condition = (authUser) => !!authUser;

const Analytics = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(AnalyticsDashboard)

export default AnalyticsPage;
