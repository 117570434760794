import { Alert as MuiAlert, AlertProps } from "@material-ui/lab";
import { ReactNode } from "react";
import Snackbar from "@material-ui/core/Snackbar";

type Props = {
  open: boolean;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  children: ReactNode;
} & AlertProps;

export default function Alert({ open, onClose, children, ...props }: Props) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <MuiAlert {...props} onClose={onClose}>
        {children}
      </MuiAlert>
    </Snackbar>
  );
}
