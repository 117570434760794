import React, { Component } from "react";

import FundRatesUploadForm from "./fundRatesUploadFront";
import FundRatesUploadFunc from "./fundRatesUploadFunc";

import { FirebaseContext } from "../../../Firebase";
// import firebase from 'firebase/app';
// import 'firebase/firestore';

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import moment from "moment";

const INTIAL_STATE = {
  uploadedFiles: [],
  fileName: "",
  fundRatesUploadDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  isLoading: false,
  messageType: "warning",
  messageAlert: "",
  toggleAlert: false,
  toggleActionAlert: false,
};

class FundRatesUploadPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INTIAL_STATE };
    this.fundRatesUploadFunction = new FundRatesUploadFunc();
  }

  handleDropzone = (files) => {
    var sizeMB = (files[0].file.size / (1024 * 1024)).toFixed(2);

    if (sizeMB > 5) {
      this.setState({
        toggleActionAlert: true,
        messageType: "error",
        messageAlert: "Uploaded file exceeds the required size.",
      });
    } else {
      this.setState({
        uploadedFiles: files,
        fileName: files[0].file.name,
      });
    }
  };

  handleOnClose = (event) => {
    this.setState({ toggleActionAlert: !this.state.toggleActionAlert });
  };

  handleFileDelete = (event) => {
    this.setState({ fileName: "", uploadedFiles: [] });
  };

  handleDateChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { fileName, uploadedFiles, fundRatesUploadDate } = this.state;

    this.setState({ isLoading: true });

    const isValid = fileName !== "";

    if (isValid) {
      this.fundRatesUploadFunction
        .doUploadDailyRates(uploadedFiles, fundRatesUploadDate)
        .then((result) => {
          if (result.data.ok) {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "success",
              messageAlert: result.data.message,
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.FUND_LIST);
            }, 1000);
          } else {
            this.setState({
              isLoading: false,
              toggleActionAlert: true,
              messageType: "error",
              messageAlert: result.data.message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: error.message,
          });
        });
    } else {
      this.setState({
        isLoading: false,
        toggleActionAlert: true,
        messageType: "error",
        messageAlert: "Please upload a file.",
      });
    }
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <FundRatesUploadForm
            state={this.state}
            firebase={firebase}
            onhandleDropzone={() => this.handleDropzone.bind(this)}
            onhandleClose={() => this.handleOnClose.bind(this)}
            onhandleFileDelete={() => this.handleFileDelete.bind(this)}
            onSubmit={() => this.onSubmit.bind(this)}
            onHandleDateChange={() => this.handleDateChange.bind(this)}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(FundRatesUploadPage);
