import React from "react";
import { Chip, Paper } from "@material-ui/core";
import { AttachFileOutlined } from "@material-ui/icons";

function Documents(props) {
  var renderFiles;
  if (props.files !== null) {
    renderFiles = props.files.map((file) => {
      var name = file.split("/")[2];
      return (
        <Chip
          icon={<AttachFileOutlined />}
          label={name}
          onDelete={() => props.deleteFile(name)}
          className="custom-chip-upload"
          key={name}
        />
      );
    });
  }
  return (
    <>
      <Paper className="custom-list-upload" component="ul">
        {props.files !== null ? renderFiles : "No Existing Documents."}
      </Paper>
    </>
  );
}

export default Documents;
