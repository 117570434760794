import React, { Component } from "react";

import CategoriesForm from "./categoriesFront";
import CategoriesFunc from "./categoriesFunc";

import { FirebaseContext } from "../../../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { withRouter } from "react-router-dom";
// import * as ROUTES from '../../../../constants/routes';

const fixPageSize = 20;

const INTIAL_STATE = {
  search: "",
  pageIndex: 1,
  pageSize: fixPageSize,
  pageCount: 0,
  currentPage: 1,
  toDelId: "",
  toDelCode: "",
  toDelName: "",
  isLoading: false,
  showDialog: false,
  messageType: "warning",
  messageAlert: "",
  toggleAlert: false,
  rows: [],
  tempRows: [],
};

class CategoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INTIAL_STATE };
    this.db = firebase.firestore();
    this.userRef = this.db.collection("fundCategories");
    this.categoriesFunction = new CategoriesFunc();
  }

  componentDidMount() {
    this.filterCategoryList();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  filterCategoryList() {
    const categories = [];
    this.setState({ rows: [] });

    var promise = new Promise((resolve, reject) => {
      this.userRef
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.exists) {
              const myDetails = doc.data();

              var categoryId = doc.id;

              var categoryCode =
                myDetails.code !== undefined ? myDetails.code : "";
              var categoryName =
                myDetails.name !== undefined ? myDetails.name : "";

              categories.push({
                categoryId: categoryId,
                categoryCode: categoryCode,
                categoryName: categoryName,
              });
            }
          });

          resolve(categories);
        })
        .catch(function (error) {
          reject(error);
        });
    });

    promise
      .then((res) => {
        this.setState({
          rows: res,
          tempRows: res,
          pageCount: Math.ceil(categories.length / fixPageSize),
          pageSize:
            categories.length < fixPageSize ? categories.length : fixPageSize,
        });
      })
      .catch(function (error) {});
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    var text = event.target.value;
    var dataRes = [];

    this.state.rows.forEach((item) => {
      if (item.categoryName.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
        dataRes.push(item);
      }
    });

    this.setState({
      tempRows: dataRes,
      pageCount: Math.ceil(dataRes.length / fixPageSize),
      pageSize: dataRes.length < fixPageSize && dataRes.length,
    });
  };

  handlePageChange = (event, value) => {
    const { pageIndex, pageSize, pageCount, currentPage, rows } = this.state;

    var updatedPageIndex;
    var updatedPageSize;

    value !== currentPage
      ? value > currentPage
        ? (updatedPageIndex = pageIndex + fixPageSize * (value - currentPage))
        : (updatedPageIndex = pageIndex - fixPageSize * (currentPage - value))
      : (updatedPageIndex = pageIndex);

    value !== currentPage
      ? value !== pageCount
        ? (updatedPageSize = fixPageSize * value)
        : (updatedPageSize = rows.length)
      : (updatedPageSize = pageSize);

    this.setState({
      currentPage: value,
      pageIndex: updatedPageIndex,
      pageSize: updatedPageSize,
    });
  };

  handleOnClose = (event) => {
    this.setState({ toggleAlert: !this.state.toggleAlert });
  };

  handleDialogChange = (event) => {
    if (event.currentTarget.dataset.id !== undefined) {
      var id = event.currentTarget.dataset.id;
      var code = event.currentTarget.dataset.code;
      var name = event.currentTarget.dataset.name;

      this.setState({
        toDelId: id,
        toDelCode: code,
        toDelName: name,
      });
    }

    this.setState({ showDialog: !this.state.showDialog });
  };

  handleDeleteConfirm = (event) => {
    const { toDelId, toDelCode } = this.state;
    this.setState({ isLoading: true });
    this.categoriesFunction
      .doDeleteCategory(toDelId, toDelCode)
      .then(() => {
        this.setState({
          isLoading: false,
          showDialog: false,
          toggleAlert: true,
          messageType: "success",
          messageAlert: "Category deleted.",
        });

        setTimeout(() => {
          this.filterCategoryList();
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showDialog: false,
          toggleAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <CategoriesForm
            state={this.state}
            firebase={firebase}
            onhandlePageChange={() => this.handlePageChange.bind(this)}
            onhandleInputChange={() => this.handleInputChange.bind(this)}
            onhandleClose={() => this.handleOnClose.bind(this)}
            onhandleDialogChange={() => this.handleDialogChange.bind(this)}
            onhandleDeleteConfirm={() => this.handleDeleteConfirm.bind(this)}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(CategoriesPage);
