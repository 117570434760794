import React, { Component } from "react";

import UserEditForm from "./userEditFront";
import EditFunc from "./userEditFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNum: "",
  userType: 1,
  status: 1,
  userName: "",
  isValid: false,
  loading: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  errors: null,
  isEditSuccess: false,
  userId: null,
};

class EditFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.editUserFunction = new EditFunc();
    this.db = firebase.firestore();
  }

  componentDidMount() {
    const userId = this.props.location.state.user;
    this.setState({ userId: userId });

    const docRef = this.db.collection("users").doc(userId);

    var promise = new Promise((resolve, reject) => {
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const myDetails = doc.data();

            resolve(myDetails);
          } else {
            this.setState({
              isEditSuccess: true,
              severity: "error",
              messageAlert: "Data not existed.",
            });

            setTimeout(() => {
              this.props.history.push(ROUTES.USERS);
            }, 2000);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });

    promise
      .then((response) => {
        var firstname =
          response.firstName !== undefined ? response.firstName : "";
        var lastname = response.lastName !== undefined ? response.lastName : "";

        var mobilenum =
          response.mobileNumber !== undefined ? response.mobileNumber : "";

        var role = response.role !== undefined ? response.role : "";
        var userType = role !== "" ? (role === "admin" ? 1 : 2) : 1;

        var status = response.status !== undefined ? response.status : true;
        var uStatus = status === true ? 1 : 0;

        this.setState({
          firstName: firstname,
          lastName: lastname,
          mobileNumber: mobilenum.substring(3),
          userType: userType,
          status: uStatus,
          firstNameError: "",
          lastNameError: "",
          mobileNumberError: "",
        });
      })
      .catch(function (error) {});
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validator(event);
    });
  };

  validator = (e) => {
    const { firstName, lastName, mobileNumber } = this.state;

    const errors = {};

    switch (e.target.name) {
      case "firstName":
        if (firstName === "") {
          this.setState({ firstNameError: "Please input first name." });
        } else {
          this.setState({ firstNameError: "" });
        }
        break;
      case "lastName":
        if (lastName === "") {
          this.setState({ lastNameError: "Please input last name." });
        } else {
          this.setState({ lastNameError: "" });
        }
        break;
      case "mobileNumber":
        if (mobileNumber.length < 10) {
          this.setState({
            mobileNumberError: "Please input correct mobile number",
          });
        } else {
          this.setState({
            mobileNumberError: "",
          });
        }
        break;
      default:
        break;
    }

    this.setState({
      errors: errors,
    });
  };

  editUser(userId, firstName, lastName, mobileNum, status) {
    let userStatus = status === 1 ? true : false;

    let promise = new Promise((resolve, reject) => {
      this.editUserFunction
        .doEditUser(userId, firstName, lastName, mobileNum, userStatus)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const {
      firstName,
      lastName,
      mobileNumber,
      // userType,
      status,
      firstNameError,
      lastNameError,
      mobileNumberError,
      userId,
    } = this.state;

    const isValid =
      firstNameError === "" && lastNameError === "" && mobileNumberError === "";

    if (isValid) {
      this.editUser(userId, firstName, lastName, mobileNumber, status)
        .then((response) => {
          this.setState({
            loading: false,
            isEditSuccess: true,
            severity: "success",
            messageAlert: "User updated successfully.",
          });

          setTimeout(() => {
            this.props.history.push(ROUTES.USERS);
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            toggleAlert: true,
            severity: "error",
            messageAlert: "Error occured while editing user.",
          });
        });
    } else {
      this.setState({
        loading: false,
        toggleAlert: true,
        severity: "error",
        messageAlert: "Invalid Data.",
      });
    }
  };

  onMobileNumKeyUp = (event) => {
    const newVal = event.target.value.replace(/[^\d]/, "");
    this.setState(
      {
        mobileNumber: newVal,
      },
      () => this.validator(event)
    );
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isEditSuccess: false });
  };

  render() {
    return (
      <UserEditForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onMobileNumKeyUp={() => this.onMobileNumKeyUp.bind(this)}
        onCopyPasteCut={() => this.onCopyPasteCut.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
      />
    );
  }
}

export default withRouter(EditFormBase);
