import React, { Component } from "react";

import TermsAndConditionForm from "./termsAndConditionFront";
import TermsAndConditionFunc from "./termsAndConditionFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import Helpers from "../../Helpers";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { EditorState, ContentState } from "draft-js";
import { ValidatorForm } from "react-material-ui-form-validator";
import { convertToRaw } from "draft-js";
import draftToMarkdown from "draftjs-to-markdown";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const INITIAL_STATE = {
  termsTitle: "",
  termsDescription: "",
  termsContent: EditorState.createEmpty(),
  termsContentText: "<p></p>",
  isContentEmpty: false,
  isChange: false,
  isSubmit: false,
  pastContent: EditorState.createEmpty(),
  toggleDialog: false,
  isLoading: false,
  messageType: "warning",
  messageAlert: "",
  toggleActionAlert: false,
};

class TermsAndConditionFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.termsAndConditionFunction = new TermsAndConditionFunc();
    this.db = firebase.firestore();
    this.termsRef = this.db.collection("miscellaneous");
    this.helper = new Helpers();
  }

  async componentDidMount() {
    this.helper.wysiwgyValidator();

    switch (this.props.location.pathname) {
      case ROUTES.CONTENT:
        await this.getTermsDetails();
        break;

      case ROUTES.REDEMPTION_TERMS:
        await this.getRedemptionTerms();
        break;

      default:
        await this.getPrivacyDetails();
        break;
    }
  }

  async componentDidUpdate(prevProp) {
    if (prevProp.location.pathname !== this.props.location.pathname) {
      switch (this.props.location.pathname) {
        case ROUTES.CONTENT:
          await this.getTermsDetails();
          break;

        case ROUTES.REDEMPTION_TERMS:
          await this.getRedemptionTerms();
          break;

        default:
          await this.getPrivacyDetails();
          break;
      }
    }
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isContentReq");
  }

  async getTermsDetails() {
    await this.termsRef
      .doc("termsAndConditions")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const blocksFromHtml = htmlToDraft(doc.data().content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);

          const contentText = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
          const isEmpty = draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          );

          this.setState({
            termsTitle: doc.data().title ? doc.data().title : "",
            termsDescription: doc.data().description
              ? doc.data().description
              : "",
            termsContent: editorState,
            termsContentText: contentText,
            isContentEmpty: isEmpty.trim() === "" ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  async getPrivacyDetails() {
    await this.termsRef
      .doc("privacyPolicy")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const blocksFromHtml = htmlToDraft(doc.data().content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);

          const contentText = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
          const isEmpty = draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          );

          this.setState({
            termsTitle: doc.data().title ? doc.data().title : "",
            termsDescription: doc.data().description
              ? doc.data().description
              : "",
            termsContent: editorState,
            termsContentText: contentText,
            isContentEmpty: isEmpty.trim() === "" ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  async getRedemptionTerms() {
    await this.termsRef
      .doc("redemptionTerms")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const blocksFromHtml = htmlToDraft(doc.data().content);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);

          const contentText = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
          const isEmpty = draftToMarkdown(
            convertToRaw(editorState.getCurrentContent())
          );

          this.setState({
            termsTitle: doc.data().title ? doc.data().title : "",
            termsDescription: doc.data().description
              ? doc.data().description
              : "",
            termsContent: editorState,
            termsContentText: contentText,
            isContentEmpty: isEmpty.trim() === "" ? true : false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isChange: true,
    });
  };

  handleContentChange = (editorState, event) => {
    var contentText = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    var htmlToText = draftToMarkdown(
      convertToRaw(editorState.getCurrentContent())
    );
    var pastContent = draftToMarkdown(
      convertToRaw(this.state.pastContent.getCurrentContent())
    );
    var isEmpty = htmlToText.trim() === "" ? true : false;
    if (pastContent.trim() !== htmlToText.trim()) {
      this.setState({
        termsContent: editorState,
        termsContentText: contentText,
        isContentEmpty: this.state.isSubmit ? isEmpty : false,
        isSubmit: false,
        isChange: true,
        pastContent: editorState,
      });
    } else {
      this.setState({
        termsContent: editorState,
        termsContentText: contentText,
        isContentEmpty: this.state.isSubmit ? isEmpty : false,
      });
    }
  };

  checkWysiwygError = () => {
    var isEmpty = draftToMarkdown(
      convertToRaw(this.state.termsContent.getCurrentContent())
    );

    this.setState({
      isContentEmpty: isEmpty.trim() === "" ? true : false,
      isSubmit: true,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const { termsTitle, termsDescription, termsContentText } = this.state;

    let url = "";
    switch (this.props.location.pathname) {
      case ROUTES.CONTENT:
        url = "cms-admin/updateTermsConditions";
        break;

      case ROUTES.REDEMPTION_TERMS:
        url = "cms-admin/updateRedemptionTerms";
        break;

      default:
        url = "cms-admin/updatePrivacyPolicy";
        break;
    }

    const misc = {
      termsTitle: termsTitle,
      termsDescription: termsDescription,
      termsContentText: termsContentText,
      url: url,
    };

    this.termsAndConditionFunction
      .doUpdateMisc(misc)
      .then(async () => {
        this.setState({
          isChange: false,
          isLoading: false,
          toggleActionAlert: true,
          messageType: "success",
          messageAlert: "Updated successfully.",
        });
        switch (this.props.location.pathname) {
          case ROUTES.CONTENT:
            await this.getTermsDetails();
            break;

          case ROUTES.REDEMPTION_TERMS:
            await this.getRedemptionTerms();
            break;

          default:
            await this.getPrivacyDetails();
            break;
        }
      })
      .catch((error) => {
        this.setState({
          isChange: false,
          isLoading: false,
          toggleActionAlert: true,
          messageType: "error",
          messageAlert: error.message,
        });
      });
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ toggleActionAlert: false });
  };

  handleDialog = async (event) => {
    this.state.isChange &&
      this.setState({
        toggleDialog: !this.state.toggleDialog,
        isChange: event.currentTarget.name === "dialogCancel" ? false : true,
      });

    if (event.currentTarget.name === "dialogCancel") {
      switch (this.props.location.pathname) {
        case ROUTES.CONTENT:
          await this.getTermsDetails();
          break;

        case ROUTES.REDEMPTION_TERMS:
          await this.getRedemptionTerms();
          break;

        default:
          await this.getPrivacyDetails();
          break;
      }
    }
  };

  render() {
    return (
      <TermsAndConditionForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
        onHandleContentChange={() => this.handleContentChange.bind(this)}
        onCheckWysiwygError={() => this.checkWysiwygError.bind(this)}
        onHandleDialog={() => this.handleDialog.bind(this)}
      />
    );
  }
}

export default withRouter(TermsAndConditionFormBase);
