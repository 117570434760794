import { ComponentType } from "react";
import { useEffect } from "react";
import { auth } from "@/components/Firebase/firebase";
import { useHistory } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

// a page wrapped with this HOC will be accessible only to authenticated users, otherwise it will redirect to the login page
export default function withAuthentication<T extends {}>(
  Component: ComponentType<T>
) {
  return function (props: T) {
    const history = useHistory();

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (!user) {
          history.push("/");
        }
      });

      return () => {
        unsubscribe();
      };
    }, [history]);

    return <Component {...props} />;
  };
}
