import React from "react";

import { Container, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  bgColor: {
    marginLeft: "260px",
    marginTop: "75px",
    backgroundColor: "yellow",
  },
}));

const AccessControl = () => {
  const classes = useStyles();

  return (
    <div className={classes.bgColor}>
      <Container>
        <Typography>Accesss Control</Typography>
      </Container>
    </div>
  );
};

export default AccessControl;
