import React from "react";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import {
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Zoom,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import {
  Search,
  Edit,
  Delete,
  WarningRounded,
  FiberManualRecord,
} from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";

import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "./css/fundList.css";

import Pagination from "@material-ui/lab/Pagination";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  inlineDisplay: {
    right: 0,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#256141",
    color: theme.palette.common.white,
    fontSize: "14px !important",
    fontWeight: "bold",
    height: "65px !important",
    "@media only screen and (max-width: 1366px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
  },
  body: {
    fontSize: "14px !important",
    height: "65px !important",
    cursor: "default",
    "@media only screen and (max-width: 1366px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
    "@media only screen and (max-width: 1280px)": {
      fontSize: "12px !important",
      height: "20px !important",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    textDecoration: "none !important",
  },
}))(TableRow);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C4C4C4",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C8932A",
      },
      fontSize: 15,
      "@media only screen and (max-width: 1366px)": {
        fontSize: 12,
      },
      textTransform: "capitalize !important",
    },
  },
})(TextField);

const FundForm = (props) => {
  const classes = useStyles();

  const {
    search,
    pageIndex,
    pageSize,
    pageCount,
    currentPage,
    tempRows,
    toDelName,
    isLoading,
    showDialog,
    toggleAlert,
    messageType,
    messageAlert,
  } = props.state;

  return (
    <>
      <div className={"fundListRoot"}>
        <div className={"fundListPaper"}>
          <label variant="h5" className={"fundListTitleText"}>
            <strong>Funds</strong>
          </label>

          <label variant="caption" className={"fundListDescription"}>
            Consist of all funds
          </label>

          <Button
            variant="contained"
            className={"fundListAddButton"}
            component={Link}
            to={ROUTES.FUND_ADD}
          >
            Add new fund
          </Button>

          <Button
            variant="contained"
            className={"fundListAttachButton"}
            component={Link}
            to={ROUTES.FUND_RATES_UPLOAD}
          >
            Upload daily rates
          </Button>
        </div>
        <div className={"fundListPaper2"}>
          <FormControl
            className={clsx("fundListTextField")}
            variant="outlined"
            size="small"
          >
            <CssTextField
              name="search"
              variant="outlined"
              size="small"
              id="search"
              value={search}
              onChange={props.onhandleInputChange()}
              placeholder="Search fund name..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="search" edge="end">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <TableContainer>
            <Table className={"fundListTableWidth"} aria-label="users table">
              <TableHead>
                <TableRow>
                  {[
                    { name: "Fund Name", width: 100 },
                    { name: "Fund Manager", width: 50 },
                    { name: "Risk Type", width: 300 },
                    { name: "Status", width: 300 },
                    { name: "Action", width: 100 },
                  ].map((item, index) => (
                    <StyledTableCell key={index} align="center">
                      {item.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tempRows.length > 0 ? (
                  tempRows.map(
                    (row, index) =>
                      row.fundName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 &&
                      index + 1 >= pageIndex &&
                      index + 1 <= pageSize && (
                        <StyledTableRow key={row.fundId}>
                          <StyledTableCell align="center">
                            {row.fundName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.fundManager}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{
                              color:
                                row.fundRiskType === "conservative"
                                  ? "#507BE9"
                                  : row.fundRiskType === "moderate"
                                  ? "#C8932A"
                                  : "#ED5555",
                            }}
                          >
                            {row.fundRiskType}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={
                              row.fundStatus
                                ? { color: "#1AB071" }
                                : { color: "#9F9F9F" }
                            }
                          >
                            <FiberManualRecord style={{ fontSize: 12 }} />
                            &nbsp;
                            {row.fundStatus ? "Active" : "Inactive"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Tooltip TransitionComponent={Zoom} title="Edit">
                              <IconButton
                                size="small"
                                style={{ cursor: "pointer", color: "#1AB071" }}
                                component={Link}
                                to={{
                                  pathname: ROUTES.FUND_EDIT,
                                  state: { id: row.fundId, code: row.fundCode },
                                }}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            &nbsp;
                            <Tooltip TransitionComponent={Zoom} title="Delete">
                              <IconButton
                                size="small"
                                style={{ cursor: "pointer" }}
                                data-id={row.fundId}
                                data-code={row.fundCode}
                                data-name={row.fundName}
                                onClick={props.onhandleDialogChange()}
                              >
                                <Delete fontSize="small" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan="4" align="center">
                      Fund List Empty
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.inlineDisplay}>
            <br />
            <label variant="caption" stlye={{ float: "left" }}>
              <strong>
                {tempRows.length > 0
                  ? `Showing ${pageIndex} to ${pageSize} of ${tempRows.length} entries`
                  : "Showing 0 to 0 of 0 entries"}
              </strong>
            </label>

            <div style={{ float: "right", marginTop: -8 }}>
              <Pagination
                className={"fundListPagination"}
                color="primary"
                count={pageCount}
                size="small"
                shape="rounded"
                page={currentPage}
                onChange={props.onhandlePageChange()}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onhandleDialogChange()}
      >
        <DialogTitle>
          <div className={"fundListIconBackground"}>
            <WarningRounded className={"fundListIcon"} />
          </div>
        </DialogTitle>
        <DialogContent>
          <label className={"fundListDialogTitle"}>
            Are you sure you want to delete fund
          </label>
          <br />
          <label className={"fundListDialogName"}>{`${toDelName}?`}</label>
        </DialogContent>
        <br />

        {!isLoading ? (
          <>
            <Button
              onClick={props.onhandleDeleteConfirm()}
              className={"fundListDialogDeleteBtn"}
            >
              Yes, Delete Fund
            </Button>
            <Button
              onClick={props.onhandleDialogChange()}
              className={"fundListDialogCancelBtn"}
            >
              Cancel
            </Button>
          </>
        ) : (
          <CircularProgress className={"fundListLoading"} />
        )}
        <br />
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FundForm;
