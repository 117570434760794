import React, { Component } from "react";

import UserListForm from "./userListFront";
import DelFunc from "./userListFunc";

import { FirebaseContext } from "../../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import * as ROUTES from "../../../constants/routes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Helpers from "../../Helpers";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";

const INTIAL_STATE = {
  columns: [],
  options: {},
  clientList: [],
  alertOpen: false,
  navps: [],
  exportData: [],
  adminStatusOptions: {},
};

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INTIAL_STATE };
    this.db = firebase.firestore();
    this.deleteUserFunction = new DelFunc();
    this.Helpers = new Helpers();
  }

  componentDidMount() {
    var filter = 0;
    if (this.props.history.location.state) {
      if (this.props.history.location.state.from === "DB") {
        filter = this.props.history.location.state.status;
      }
    }

    const columns = [
      {
        name: "date",
        label: "Submission Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === 9999999999
                  ? "-"
                  : moment.unix(value).format("MM/DD/YYYY h:mm a")}
              </>
            );
          },
        },
      },
      {
        name: "registrationDate",
        label: "Creation Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{moment.unix(value).format("MM/DD/YYYY h:mm a")}</>;
          },
        },
      },
      {
        name: "accountNumber",
        label: "Account Number",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "clientName",
        label: "Client Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "portfolioValue",
        label: "Portfolio Value",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === 0 ? "-" : this.Helpers.formatToPHP(value)}</>;
          },
        },
      },
      {
        name: "status",
        label: "Client Status",
        options: {
          filter: true,
          sort: true,
          filterList: filter === "pending"
            ? ["Pending Verification", "Submitted Long KYC"]
            : filter === "dormant"
              ? ["Pending Verification", "Submitted Long KYC"]
              : filter === 1
                ? ["Submitted Short KYC", "Returned Short KYC"]
                : []
        },
      },
      {
        name: "tier",
        label: "Tier",
        options: {
          filter: true,
          sort: true,
          filterList:
            filter === 1
              ? [1, "Legacy Tier"]
              : filter === 2
                ? [2, "Legacy Tier"]
                : [],
        },
      },
      ...(filter !== 1
        ? [
          {
            name: "daysPending",
            label: "Days Since Submission Date",
            options: {
              filter: true,
              sort: true,
              filterList: filter === "dormant" ? ["31 Days and Above"] : filter === 'pending' ? ['<31 Days'] : [],
              filterOptions: {
                names: filter === "dormant" ? ["31 Days and Above"] : filter === 'pending' ? ['<31 Days'] : [],
                logic: (value, filters) => {
                  if (filters.length) {
                    console.log(filter);
                    switch(filter) {
                      case 'dormant':
                        return parseInt(value.split(" ")[0]) <= 31;

                      case 'pending':
                        return parseInt(value.split(" ")[0]) > 31;

                      default:
                        return false;
                    }
                    
                  }
                  return false;
                },
              },
              customBodyRender: (value) => {
                return value === "-"
                  ? "N/A"
                  : value > 1
                    ? `${value} Days`
                    : `${value} Day`;
              },
            },
          },
        ]
        : []),
      ...(filter === 2 || filter === "pending" || filter === "dormant"
        ? [
          {
            name: "adminStatus",
            label: "Admin Status",
            options: {
              filter: true,
              sort: true,
              filterList:
                filter === "pending"
                  ? [
                    "Pending Zoom Verification",
                    "BDO Attestation",
                    "Awaiting for client`s confirmation",
                    "Scheduled but no show",
                    "Re-schedule Zoom",
                    "Schedule confirmed",
                  ]
                  : filter === 2
                    ? ["Submitted Long KYC", "Returned Long KYC"]
                    : [],
              filterOptions: {
                names: [
                  "Investment Cap Reached",
                  "Submitted Long KYC",
                  "Returned Long KYC",
                  "Pending Zoom Verification",
                  "BDO Attestation",
                  "Awaiting for client`s confirmation",
                  "Scheduled but no show",
                  "Re-schedule Zoom",
                  "Schedule confirmed",
                ],
                logic: (adminStatus, filters) => {
                  const statusMapping = {
                    LIMIT: "Investment Cap Reached",
                    "LONG_KYC-2": "Submitted Long KYC",
                    "LONG_KYC-3": "Returned Long KYC",
                    "LONG_KYC-6": "Pending Zoom Verification",
                    "LONG_KYC-7": "BDO Attestation",
                    "LONG_KYC-9": "Awaiting for client`s confirmation",
                    "LONG_KYC-10": "Scheduled but no show",
                    "LONG_KYC-11": "Re-schedule Zoom",
                    "LONG_KYC-12": "Schedule confirmed",
                  };

                  const mappedFilters = filters.map((filter) => {
                    const mappedValue = Object.keys(statusMapping).find(
                      (key) => statusMapping[key] === filter
                    );
                    return mappedValue || filter;
                  });

                  return !mappedFilters.includes(adminStatus);
                },
              },

              customBodyRender: (value, tableMeta) => {
                const rowData = tableMeta.rowData;

                if (!value) return "";

                if (
                  [
                    "LIMIT",
                    "LONG_KYC-2",
                    "LONG_KYC-3",
                    "LONG_KYC-5",
                    "LONG_KYC-6",
                    "LONG_KYC-7",
                    "LONG_KYC-9",
                    "LONG_KYC-10",
                    "LONG_KYC-11",
                    "LONG_KYC-12",
                  ].includes(value)
                ) {
                  const adminStat =
                    filter === 2
                      ? [value, "LONG_KYC-6", "LONG_KYC-7"]
                      : value === "LONG_KYC-7"
                        ? [value, "LONG_KYC-7"]
                        : [
                          value,
                          "LONG_KYC-9",
                          "LONG_KYC-12",
                          "LONG_KYC-10",
                          "LONG_KYC-11",
                        ];
                  return (
                    <FormControl>
                      <Select
                        value={value}
                        onChange={(e) => {
                          e.stopPropagation();
                          this.updateStatus(e.target.value, rowData[4]);
                        }}
                        onClose={(e) => e.stopPropagation()}
                      >
                        {adminStat
                          .filter(
                            (item, index, arr) =>
                              index === 0 || item !== arr[0]
                          )
                          .map((item, index) =>
                            index === 0 ? (
                              <MenuItem value={value}>
                                {this.state.adminStatusOptions[value]}
                              </MenuItem>
                            ) : (
                              <MenuItem value={item}>
                                {this.state.adminStatusOptions[item]}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  );
                } else {
                  return "N/A";
                }
              },
            },
          },
        ]
        : []),
    ];

    const options = {
      filterType: "checkbox",
      responsive: "standard",
      selectableRowsHideCheckboxes: true,
      selectableRowsHeader: false,
      selectableRows: false,
      download: false,
      print: false,
      rowsPerPage: 100,
      sortOrder: {
        name: "date",
        direction: "asc",
      },
      onRowClick: (data, meta) => {
        this.redirectUser(meta.dataIndex);
      },
    };
    this.setState({ columns, options });
    this.getCurrentNavps();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  getCurrentNavps() {
    let navps = {};

    let arrPromise = [];

    arrPromise.push(
      this.db
        .collection("fundRates")
        .orderBy("dateUploaded", "desc")
        .limit(1)
        .get()
    );

    arrPromise.push(this.db.collection("funds").get());

    Promise.all(arrPromise)
      .then((result) => {
        if (result[0].size < 1) throw new Error("NAVPS not found");

        if (result[1].size < 1) throw new Error("Funds not found");

        navps = result[0].docs[0].data().fundDetails;
        result[1].forEach((doc) => {
          let navpsIndex = navps.findIndex(
            (x) => x.fundName === doc.data().name
          );
          navps[navpsIndex].fundCode = doc.data().code;
        });

        this.setState({ navps });
        this.getUserList();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  async updateStatus(status, email,) {
    try {
      const user = this.state.clientList.find((x) => x.email === email);
      if (!user) throw new Error('missing user');

      const token = this.Helpers.getCookie("token");
      await axios.post(
        ROUTES.FUNCTIONAL_BASE_URL + "metrics-metrics/assign-status",
        JSON.stringify({
          uid: user.userId,
          status,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await this.getUserList();
    } catch (err) {
      console.log(err);
      alert('Admin Status: ', err.message);
    }
  }

  async getUserList() {
    const users = [],
      exportData = [];

    const referrersSnapshot = await this.db.collection("referrers").get();
    const statusRef = await this.db.collection("miscellaneous").doc('constants').get();
    const statusConstants = statusRef.data().status;

    const referrers = [];
    referrersSnapshot.forEach((doc) =>
      referrers.push({ id: doc.id, ...doc.data() })
    );

    this.db
      .collection("users")
      .where("role", "==", "client")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const myDetails = doc.data();
          let status = this.getStatus(myDetails);
          const daysPending = this.getDaysPending(myDetails, status);

          let date = myDetails.dateKYCCompleted?.seconds || 9999999999;

          let hasKYC;
          if (myDetails.hasLongKYC) hasKYC = myDetails.hasLongKYC;
          else if (myDetails.hasShortKYC) hasKYC = myDetails.hasShortKYC;
          else hasKYC = false;

          const tier = myDetails.stats?.tier
            ? myDetails.stats?.tier === 1 &&
              myDetails.stats?.status === "SIGN_UP-1"
              ? "N/A"
              : myDetails.stats?.tier
            : "Legacy Tier";

          const referrer =
            myDetails?.referrerId && myDetails?.referrerId?.length > 0
              ? (() => {
                const obj = referrers.find(
                  (x) => x.id === myDetails.referrerId
                );
                return `${obj.firstName} ${obj.lastName}`;
              })()
              : "n/a";

          let adminStatus = myDetails.stats?.status ? myDetails.stats?.status : '';

          if (tier === 3) adminStatus = "LONG_KYC-8";

          users.push({
            userId: doc.id,
            hasKYC,
            accountNumber:
              myDetails.accountNumber === "" ? "-" : myDetails.accountNumber,
            clientName: `${myDetails.firstName} ${myDetails.lastName}`,
            registrationDate: myDetails.dateCreated.seconds,
            date,
            portfolioValue: this.getPortfolioValue(myDetails),
            email: myDetails.email,
            manager:
              !myDetails.investmentManager ||
                myDetails.investmentManager.investmentManagerId === ""
                ? "N/A"
                : myDetails.investmentManager.investmentManagerName,
            status,
            daysPending,
            tier,
            referrer,
            adminStatus
          });

          exportData.push({
            submissionDate:
              date === 9999999999
                ? "-"
                : moment.unix(date).format("MM/DD/YYYY h:mm a"),
            registrationDate: moment
              .unix(myDetails.dateCreated.seconds)
              .format("MM/DD/YYYY h:mm a"),
            accountNumber: myDetails?.accountNumber,
            lastName: myDetails.lastName,
            firstName: myDetails.firstName,
            email: myDetails.email,
            portfolioValue: this.getPortfolioValue(myDetails),
            status: status,
            daysPending: daysPending,
            referrer,
          });
        });

        this.setState({ clientList: users, exportData, adminStatusOptions: statusConstants });
      })
      .catch((error) => {
        alert(error);
      });
  }

  redirectUser = (index) => {
    let clickedUser = this.state.clientList[index];

    if (clickedUser.hasKYC)
      this.props.history.push({
        pathname: ROUTES.CLIENT_PORFILE,
        state: { user: clickedUser.userId, from: "CL" },
      });
    else {
      this.setState({ alertOpen: true });
    }
  };

  getStatus = (data) => {
    const filter = this.props.history.location.state?.status;
    let status = "Started Short KYC";
    const userDeclined = data.declinedStatus;
    const usertier = data?.stats?.tier ?? 3;
    const userStatus = data?.stats?.status;
    const userTierUpgrade = data?.stats?.tierUpgradeStatus;
    const datekyc = parseInt(
      (moment().unix() - data?.dateKYCCompleted?.seconds) / 86400
    );
    if ("declinedStatus" in data) {
      if (userDeclined === "returned") {
        if (usertier === 1) return (status = "Returned Short KYC");
        else status = "On-going Application";
      } else if (userDeclined === "approved") {
        if (userStatus === "LONG_KYC-4") {
          status = "Declined Long KYC";
        } else if (userStatus === "LONG_KYC-1") status = "Started Long KYC";
        else {
          if (usertier === 2) {
            if (userTierUpgrade && userTierUpgrade === "pending") {
              if (
                userStatus === "LONG_KYC-10" ||
                userStatus === "LONG_KYC-11" ||
                userStatus === "LONG_KYC-9" ||
                userStatus === "LONG_KYC-12"
              ) {
                return (status = "Pending Verification");
              } else if (
                userStatus === "LONG_KYC-7" ||
                userStatus === "LONG_KYC-6"
              ) {
                return (status = "Submitted Long KYC");
              }
              else {
                if (userStatus === "LONG_KYC-2")
                  return (status = "Submitted Long KYC");
                else if (userStatus === "LONG_KYC-3")
                  return (status = "Returned Long KYC");
              }
            }
            if (userTierUpgrade && userTierUpgrade === "returned") {
              if (
                userStatus === "LONG_KYC-10" ||
                userStatus === "LONG_KYC-11" ||
                userStatus === "LONG_KYC-9" ||
                userStatus === "LONG_KYC-12"
              ) {
                return (status = "Pending Verification");
              } else if (
                userStatus === "LONG_KYC-7" ||
                userStatus === "LONG_KYC-6"
              ) {
                return (status = "Returned Long KYC");
              }
              return status = "Returned Long KYC"
            }
            return (status = "Approved Short KYC");
          }
          status = "Approved Long KYC";
        }
      } else {
        status = "Declined Short KYC";
      }
    } else {
      if (data.dateKYCCompleted) {
        if (data.approvedLongKYC) status = "Approved Long KYC";
        else if (userStatus === "SHORT_KYC-2")
          return (status = "Submitted Short KYC");
      } else {
        if (userStatus === "SIGN_UP-1") return (status = "Account Sign-up");
        else if (userStatus === "SIGN_UP-2") return (status = "Email Verified");
        status = "On-going Application";
      }
    }
    return status;
  };

  getDaysPending = (data, status) => {
    let daysPending = "-";
    if (!data.dateKYCCompleted) return daysPending;

    if (
      [
        "Pending Verification",
        "Returned Long KYC",
        "Submitted Long KYC",
      ].includes(status)
    ) {
      daysPending = parseInt(
        (moment().unix() - data.dateKYCCompleted.seconds) / 86400
      );
    }

    return daysPending;
  };

  getPortfolioValue = (data) => {
    let moneyValue = 0;
    if ("investmentSummary" in data) {
      data.investmentSummary.forEach((item) => {
        const fundIndex = this.state.navps.findIndex(
          (x) => x.fundCode === item.fundCode
        );
        moneyValue += this.state.navps[fundIndex].rate * item.value;
      });
    }
    return moneyValue;
  };

  closeAlert = (event, reason) => {
    if (reason === "clickaway") return;

    this.setState({ alertOpen: false });
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <UserListForm
            state={this.state}
            firebase={firebase}
            closeAlert={() => this.closeAlert.bind(this)}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(UserPage);
