import React, { Component } from "react";

import UserAddForm from "./userAddFront";
import AddFunc from "./userAddFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import Validators from "../../Helpers/validators";
import { removeSpecialCharactersFromString } from "@/utils";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNum: "",
  userType: "Admin",
  status: 1,
  userName: "",
  password: "",
  confirmPassword: "",
  isValid: false,
  loading: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  errors: null,
  isAddSuccess: false,
  referralCode: "",
};

class AddFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.addUserFunction = new AddFunc();
    this.validators = new Validators();
  }

  componentDidMount() {
    const userType = new URLSearchParams(this.props.location.search).get(
      "userType"
    );
    if (!userType) return;

    this.setState({ userType });
  }

  onChangeValidator = (event) => {
    this.handleInputChange(event);
  };

  handleInputChange = (event) => {
    // if referralCode, keep it lowercase and remove special characters
    if (event.target.name === "referralCode") {
      this.setState(
        {
          [event.target.name]: removeSpecialCharactersFromString(
            event.target.value
          ).toLowerCase(),
        },
        () => {
          this.validators.isMatchValue(this.state);
        }
      );

      return;
    }

    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validators.isMatchValue(this.state);
    });
  };

  validator = (e) => {
    const {
      firstName,
      lastName,
      email,
      mobileNum,
      userName,
      password,
      confirmPassword,
    } = this.state;

    const errors = {};

    switch (e.target.name) {
      case "firstName":
        if (firstName === "") {
          this.setState({ firstNameError: "Please input first name." });
        } else {
          this.setState({ firstNameError: "" });
        }
        break;
      case "lastName":
        if (lastName === "") {
          this.setState({ lastNameError: "Please input last name." });
        } else {
          this.setState({ lastNameError: "" });
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          this.setState({ emailError: "Please input a valid email address." });
        } else {
          this.setState({ emailError: "" });
        }
        break;
      case "mobileNum":
        if (mobileNum.length < 10) {
          this.setState({
            mobileNumberError: "Please input correct mobile number",
          });
        } else {
          this.setState({
            mobileNumberError: "",
          });
        }
        break;
      case "userName":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userName)) {
          this.setState({
            userNameError: "Please input a valid email address.",
          });
        } else if (email !== userName) {
          this.setState({ userNameError: "Email and Username doesn't match." });
        } else {
          this.setState({ userNameError: "" });
        }
        break;
      case "password":
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64}$)"
        );

        if (confirmPassword.length <= 0) {
          if (!strongRegex.test(password)) {
            this.setState({
              passwordError:
                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
            });
          } else {
            this.setState({ passwordError: "" });
          }
        } else if (confirmPassword.length > 0 && confirmPassword !== password) {
          this.setState({ confirmPasswordError: "Password doesn't match." });
          if (!strongRegex.test(password)) {
            this.setState({
              passwordError:
                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
            });
          } else {
            this.setState({ passwordError: "" });
          }
        } else if (confirmPassword === password) {
          this.setState({ passwordError: "" });
          this.setState({ confirmPasswordError: "" });
        }
        break;
      case "confirmPassword":
        if (confirmPassword !== password) {
          this.setState({ confirmPasswordError: "Password doesn't match." });
        } else {
          this.setState({ confirmPasswordError: "" });
        }
        break;
      default:
        break;
    }

    this.setState({
      errors: errors,
    });
  };

  addUser(userName, password, mobileNum, userType) {
    const url = ROUTES.FUNCTIONAL_BASE_URL + "cms-admin/createUser";

    let promise = new Promise((resolve, reject) => {
      this.addUserFunction
        .doAddUser(userName, password, mobileNum, url, userType)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  addUserDetails(
    userId,
    firstName,
    lastName,
    mobileNum,
    status,
    email,
    userType,
    referralCode
  ) {
    let userStatus = status === 1 ? true : false;

    let promise = new Promise((resolve, reject) => {
      this.addUserFunction
        .doAddUserDetails(
          userId,
          firstName,
          lastName,
          mobileNum,
          userStatus,
          email,
          userType,
          referralCode
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const {
      firstName,
      lastName,
      userName,
      password,
      mobileNum,
      userType,
      status,
      referralCode,
    } = this.state;

    this.addUser(userName, password, mobileNum, userType)
      .then((response) => {
        if (!response.data.ok) throw new Error(response.data.message);

        return this.addUserDetails(
          response.data.data,
          firstName,
          lastName,
          mobileNum,
          status,
          userName,
          userType,
          referralCode
        );
      })
      .then((response) => {
        if (response.data.ok)
          this.setState({
            loading: false,
            toggleAlert: false,
            isAddSuccess: true,
            severity: "success",
            messageAlert: response.data.message,
          });
        else throw new Error(response);
      })
      .then(async () => {
        if (userType === "Manager") {
          this.props.history.push(ROUTES.REFERRERS);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          toggleAlert: true,
          severity: "error",
          messageAlert: error.message,
        });
      });
  };

  onMobileNumKeyUp = (event) => {
    event.persist();

    this.setState(
      {
        mobileNum: event.target.value,
      },
      () => this.validator(event)
    );
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isAddSuccess: false });
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <UserAddForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onMobileNumKeyUp={() => this.onMobileNumKeyUp.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
        onCopyPasteCut={() => this.onCopyPasteCut.bind(this)}
        onChangeValidator={this.onChangeValidator}
      />
    );
  }
}

export default withRouter(AddFormBase);
