import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import DashboardForm from "./dashboardFront";
import Helpers from "../Helpers";

import { FirebaseContext } from "../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";

const routesArray = [
  { route: ROUTES.USERS, param: 1 },
  { route: ROUTES.TRANSACTION_LIST, param: "processing", tab: "booking" },
  {
    route: ROUTES.TRANSACTION_LIST,
    param: "processingRedemption",
    tab: "redemption",
  },
  { route: ROUTES.RECURRING_INVESTMENTS, param: "processing" },
  { route: ROUTES.RECURRING_TRANSACTIONS, param: "processing" },
  { route: ROUTES.GRADUATING_USERS },
];

const INTIAL_STATE = {
  processingCount: 0,
  processingRedemptionCount: 0,
  applicationCount: 0,
  assignManagerCount: 0,
  autoDebitCount: 0,
  recurringCount: 0,
  graduatingUsers: 0,
  toggleAlert: false,
  alertType: "warning",
  alertMessage: "",
  fundRates: [],
  fundDate: "",
  isRatesUpdating: false,
  updateAlertOpen: false,
  updateAlertObj: {},
  mostInvestedCompany: {},
  mostInvestedFund: {},
  usersAmount: 0,
  totalFundsAmount: 0,
  totalSharesAmount: 0,
  loadingTasks: false,
  processingDate: null,
};

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INTIAL_STATE };
    this.db = firebase.firestore();
    this.helpers = new Helpers();
    this.notifRef = this.db.collection("notificationCountsAdmin");
    this.storage = firebase.storage().ref();
  }

  async componentDidMount() {
    Promise.all([
      this.getTodoListCount(),
      this.getNavpsRates(),
      this.getStats(),
      this.getUsers(),
    ]);
  }

  refreshListCount = async () => {
    const apiUrl = `${ROUTES.FUNCTIONAL_BASE_URL}operations-operations/refresh-task-list`;
    const token = this.helpers.getCookie("token");
    try {
      this.setState({ loadingTasks: true });
      await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      this.setState({ loadingTasks: false });
    } catch (error) {
      alert(error.message);
      this.setState({ loadingTasks: false });
    }
  };

  getTodoListCount() {
    return this.notifRef.doc("listing").onSnapshot((snapShot) => {
      const processingDate = new moment.unix(
        snapShot.data()?.lastUpdated.seconds
      ).format("MM/DD/YYYY, hh:mm a");

      this.setState({
        processingDate: processingDate,
        processingCount: snapShot.data().numberOfTransactionsProcessing
          ? snapShot.data().numberOfTransactionsProcessing
          : 0,
        processingRedemptionCount: snapShot.data()
          .numberOfTransactionsProcessingRedemption
          ? snapShot.data().numberOfTransactionsProcessingRedemption
          : 0,
        applicationCount: snapShot.data().numberOfPendingApplications
          ? snapShot.data().numberOfPendingApplications
          : 0,
        assignManagerCount: snapShot.data().numberOfClientsNoManager
          ? snapShot.data().numberOfClientsNoManager
          : 0,
        autoDebitCount: snapShot.data().numberOfAutoDebit
          ? snapShot.data().numberOfAutoDebit
          : 0,
        recurringCount: snapShot.data().numberOfTransactionsRecurring
          ? snapShot.data().numberOfTransactionsRecurring
          : 0,
        graduatingUsers: snapShot.data().numberofGraduatingUsers
          ? snapShot.data().numberofGraduatingUsers
          : 0,
      });
    });
  }

  getUsers() {
    return this.db
      .collection("miscellaneous")
      .doc("numberOfApprovedUsers")
      .get()
      .then((result) => {
        return this.setState({
          usersAmount: result.data().numberOfApprovedUsers,
        });
      })
      .catch((err) => {
        alert(err);
      });
  }

  getStats() {
    let companyArr = [];
    let companyObj = {};

    return this.db
      .collection("funds")
      .get()
      .then((snapshot) => {
        let fundArray = [];
        let totalAmount = 0;
        let totalSharesValue = 0;
        snapshot.forEach((item) => {
          fundArray.push(item.data());
          const rateItem = this.state.fundRates.find(
            (x) => x.fundName === item.data().name
          ) ?? { rate: 1 };

          totalAmount += item.data()?.fundStats?.totalAmountInvested ?? 0;
          totalSharesValue += isNaN(
            item.data()?.fundStats?.totalShares * rateItem.rate
          )
            ? 0
            : item.data()?.fundStats?.totalShares * rateItem.rate;
          if (
            companyArr.find(
              (x) => x.id === item.data().fundCompanyDetails.fundCompanyId
            ) === undefined
          )
            companyArr.push({
              id: item.data().fundCompanyDetails.fundCompanyId,
              usersAmt: item.data().fundStats?.numberOfTransactions,
            });
          else
            companyArr.find((x, index) => {
              if (x.id === item.data().fundCompanyDetails.fundCompanyId) {
                let users =
                  x.usersAmt + item.data().fundStats?.numberOfTransactions;
                companyArr[index] = {
                  id: item.data().fundCompanyDetails.fundCompanyId,
                  usersAmt: parseFloat(users),
                };
                return true;
              }
              return false;
            });
        });
        companyArr.sort((a, b) => b.usersAmt - a.usersAmt);
        fundArray.sort((a, b) => {
          return (
            a.fundStats?.totalAmountInvested - b.fundStats?.totalAmountInvested
          );
        });
        let fund = fundArray[fundArray.length - 1];
        this.setState({
          totalFundsAmount: totalAmount,
          totalSharesAmount: totalSharesValue,
          mostInvestedFund: {
            name: fund.name,
            amount: fund.fundStats?.totalAmountInvested,
          },
        });
        return this.db.collection("fundCompanies").doc(companyArr[0].id).get();
      })
      .then((snapshot) => {
        companyObj = {
          name: snapshot.data().name,
          users: companyArr[0].usersAmt,
          icon: "",
        };

        return this.storage.child(snapshot.data().icon).getDownloadURL();
      })
      .then((snapshot) => {
        companyObj.icon = snapshot;
        return this.setState({ mostInvestedCompany: companyObj });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  getNavpsRates() {
    return this.db
      .collection("fundRates")
      .orderBy("dateUploaded", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        let date = new moment.unix(
          snapshot.docs[0].data().dateUploaded.seconds
        ).format("MM/DD/YYYY, hh:mm a");
        this.setState({
          fundRates: snapshot.docs[0].data().fundDetails,
          fundDate: date,
        });
      });
  }

  HandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleAlert: false });
  };

  HandleTaskClick = (index, count) => {
    if (count <= 0) return;
    const routeDetails = routesArray[index];

    switch (index) {
      case 0:
        this.props.history.push({
          pathname: routeDetails.route,
          state: { from: "DB", status: routeDetails.param },
        });
        break;

      case 1:
        this.props.history.push(
          `${routeDetails.route}?status=${routeDetails.param}&tab=${routeDetails?.tab}`
        );
        break;
      case 2:
        this.props.history.push(
          `${routeDetails.route}?status=${routeDetails.param}&tab=${routeDetails?.tab}`
        );
        break;

      case 3:
        this.props.history.push(
          `${routeDetails.route}?status=${routeDetails.param}`
        );
        break;
      case 4:
        this.props.history.push(
          `${routeDetails.route}?status=${routeDetails.param}`
        );
        break;
      case 5:
        this.props.history.push(`${routeDetails.route}`);
        break;
      default:
        break;
    }
  };

  refreshNavps = (event) => {
    this.setState({ isRatesUpdating: true }, () => {
      const token = this.helpers.getCookie("token");
      axios
        .post(ROUTES.FUNCTIONAL_BASE_URL + "/cms-admin/refreshNavps", "", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.setState({
            isRatesUpdating: false,
            updateAlertOpen: true,
            updateAlertObj: res.data,
          });
        })
        .catch((err) => {
          this.helpers.handleError(err);
        });
    });
  };

  closeAlert = (event) => {
    this.setState({ updateAlertOpen: false, updateAlertObj: {} });
  };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <DashboardForm
            state={this.state}
            firebase={firebase}
            onHandleClose={() => this.HandleClose.bind(this)}
            onHandleTaskClick={this.HandleTaskClick}
            refreshNavps={() => this.refreshNavps.bind(this)}
            closeAlert={() => this.closeAlert.bind(this)}
            refreshListCount={() => this.refreshListCount}
          />
        )}
      </FirebaseContext.Consumer>
    );
  }
}

export default withRouter(DashboardPage);
