import axios from "axios";
import * as ROUTES from "../../../../constants/routes";
import Helpers from "../../../Helpers";

export default class FundAddEditFunction {
  constructor() {
    this.helpers = new Helpers();
  }

  doFundAdd = (funds, files) => {
    const token = this.helpers.getCookie("token");
    let fundOverview = this.helpers.whiteSpaceToStringConverter(
      funds.fundOverview
    );
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const json = JSON.stringify({
        name: funds.fundTitle,
        code: funds.fundCode,
        overview: fundOverview,
        fundManager: funds.fundManager,
        riskType: funds.riskTypeStr,
        currency: funds.fundCurrency,
        minInitialInvestment: funds.fundMinimumInitial,
        minAdditionalInvestment: funds.fundMinimumAditional,
        inceptionDate: funds.fundInceptionDate,
        managementFee: funds.fundManagementFee,
        recommendedLength: funds.fundLengthStay,
        entryFee: funds.fundEntryFee,
        holdingPeriod: funds.fundHoldingPeriod,
        redemptionFee: funds.fundEarlyRedemption,
        mutualFundType: funds.fundTypeStr,
        status: funds.fundStatus,
        rateType: funds.rateTypeStr,
        categoriesToAdd: funds.fundCategories,
        fundCompanyDetails: {
          fundCompanyId: funds.selectedComp.companyId,
          fundCompanyName: funds.selectedComp.companyName,
        },
        fundColor: funds.fundColor,
        banner: {
          color: funds.bannerColor,
          enabled: funds.bannerEnabled,
          text: funds.bannerText,
          textColor: funds.bannerTextColor,
        }
      });
      if (files !== null) {
        for (var a = 0; a < files.length; a++) {
          formData.append("doc" + a, files[a]);
        }
      }
      formData.append("req", json);
      axios
        .post(
          ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/createNewFund",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };

  doFundEdit = (fundId, funds, files) => {
    const token = this.helpers.getCookie("token");
    let fundOverview = this.helpers.whiteSpaceToStringConverter(
      funds.fundOverview
    );
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      const json = {
        fundUid: fundId,
        name: funds.fundTitle,
        code: funds.fundCode,
        overview: fundOverview,
        fundManager: funds.fundManager,
        riskType: funds.riskTypeStr,
        currency: funds.fundCurrency,
        minInitialInvestment: funds.fundMinimumInitial,
        maxInitialInvestment: funds.fundMaximumInitial,
        minAdditionalInvestment: funds.fundMinimumAditional,
        inceptionDate: funds.fundInceptionDate,
        managementFee: funds.fundManagementFee,
        recommendedLength: funds.fundLengthStay,
        entryFee: funds.fundEntryFee,
        holdingPeriod: funds.fundHoldingPeriod,
        redemptionFee: funds.fundEarlyRedemption,
        mutualFundType: funds.fundTypeStr,
        status: funds.fundStatus,
        rateType: funds.rateTypeStr,
        categoriesToEdit: funds.fundCategories,
        fundCompanyDetails: {
          fundCompanyId: funds.selectedComp.companyId,
          fundCompanyName: funds.selectedComp.companyName,
        },
        documentsToDelete: funds.documentsToDelete,
        fundColor: funds.fundColor,
        banner: {
          color: funds.bannerColor,
          enabled: funds.bannerEnabled,
          text: funds.bannerText,
          textColor: funds.bannerTextColor,
        }
      };
      if (files !== null) {
        for (var a = 0; a < files.length; a++) {
          formData.append("doc" + a, files[a]);
        }
      }
      formData.append("req", JSON.stringify(json));
      axios
        .post(ROUTES.FUNCTIONAL_BASE_URL + "funds-funds/editFund", formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          this.helpers.handleError(err);
          reject(err);
        });
    });
  };
}
