import React from "react";

import "./css/categoriesEdit.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import {
  TextField,
  Fade,
  FormHelperText,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CategoryEditForm = (props) => {
  const {
    categoryCode,
    categoryTitle,
    categoryDescription,
    categoryCodeError,
    categoryTitleError,
    categoryDescriptionError,
    toggleAlert,
    messageType,
    messageAlert,
    toggleActionAlert,
    isLoading,
  } = props.state;

  return (
    <>
      <div className={"categoriesEditRoot"}>
        <form onSubmit={props.onSubmit()}>
          <div className={"categoriesEditContainer"}>
            <label className={"categoriesEditHeader"}>Edit Category</label>

            {toggleAlert && (
              <>
                <MuiAlert elevation={1} variant="filled" severity={messageType}>
                  {messageAlert}
                </MuiAlert>
                <br />
              </>
            )}

            <label className={"categoriesEditTitle"}>Category Code</label>

            <TextField
              name="categoryCode"
              inputProps={{ className: "categoriesEditTextBox" }}
              variant="outlined"
              value={categoryCode}
              onChange={props.onChange()}
              placeholder="Category code..."
              autoComplete="off"
              required
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {categoryCodeError}
              </FormHelperText>
            </Fade>

            <br />
            <label className={"categoriesEditTitle"}>Category Title</label>

            <TextField
              name="categoryTitle"
              inputProps={{ className: "categoriesEditTextBox" }}
              variant="outlined"
              value={categoryTitle}
              onChange={props.onChange()}
              placeholder="Category title..."
              autoComplete="off"
              required
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {categoryTitleError}
              </FormHelperText>
            </Fade>

            <br />
            <label className={"categoriesEditTitle"}>Description</label>

            <TextField
              name="categoryDescription"
              inputProps={{ className: "categoriesEditDescription" }}
              variant="outlined"
              value={categoryDescription}
              onChange={props.onChange()}
              placeholder="Category description..."
              autoComplete="off"
              multiline
              rows={8}
            />
            <Fade in={true}>
              <FormHelperText style={{ color: "red" }}>
                {categoryDescriptionError}
              </FormHelperText>
            </Fade>

            <br />
            <div>
              <Button
                component={Link}
                to={ROUTES.CATEGORIES_LIST}
                style={{ pointerEvents: isLoading ? "none" : "all" }}
                className={"categoriesEditCancelButton"}
              >
                Cancel
              </Button>
              {!isLoading ? (
                <Button type="submit" className={"categoriesEditEditButton"}>
                  Save
                </Button>
              ) : (
                <CircularProgress className={"categoriesEditLoading"} />
              )}
            </div>
          </div>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleActionAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CategoryEditForm;
