import React from 'react';
import Navigation from '../Navigation';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import useFetchBatchData from './hooks';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Button, makeStyles, Grid, CircularProgress, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import CustomDataTable from '../CustomDataTable';

const condition = (authUser) => !!authUser;

const useStyles = makeStyles((theme) => ({
    paperContent: {
        padding: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
    },
}));


const TRCBatchDisplayPage = (props) => {
    return (
        <>
            <Navigation content={<TRCBatchDisplay props={props} />} />
        </>
    );
};


const TRCBatch = (props) => {
    const classes = useStyles();
    const { data, loading, error, ExportUserInfo } = useFetchBatchData({ firebase: props.firebase });

    const columns = [
        {
            name: "name",
            label: "Batch Name",
            options: {
                filter: false,
                fullWidth: true,
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const batch = data.find((x) => x.name === tableMeta.rowData[0]);

                    return (
                        <ExportUserInfo id={batch.id} />
                    );
                }
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        responsive: "standard",
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        selectableRows: false,
        download: false,
        print: false,
        sortOrder: {
            name: "id",
            direction: "asc",
        },
    };

    return (
        <div className="userListRoot">
            <div className="userListpaper">
                <div className="userListBody">
                    <h1>TRC Information</h1>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {loading && <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    zIndex: 999,
                                }}
                            >
                                <CircularProgress />
                            </div>}
                            <CustomDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

const TRCBatchDisplay = compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(TRCBatch);

export default TRCBatchDisplayPage;
