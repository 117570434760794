import React, { Component } from "react";

import SigninFunction from "./signinFunction";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import withoutAuthorization from "../Session/withoutAuthorization";

const SignInPage = () => {
  return <SigninForm />;
};

class SignInPageBase extends Component {
  render() {
    return <SigninFunction props={this.props} />;
  }
}

const condition = (authUser) => !!authUser;

const SigninForm = compose(
  withoutAuthorization(condition),
  withRouter,
  withFirebase
)(SignInPageBase);

export default SignInPage;

export { SigninForm };
