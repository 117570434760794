import React, { Component } from "react";

// import Typography from '@material-ui/core/Typography';

import FundListFunction from "./fundListFunction";
import { withFirebase } from "../../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import withAuthorization from "../../../Session/withAuthorization";

import Navigation from "../../../Navigation";

const FundListPage = () => {
  return (
    <>
      <Navigation content={<FundListForm />} />
    </>
  );
};

class FundListPageBase extends Component {
  render() {
    return (
      <>
        <FundListFunction props={this.props} />
        <label
          variant="caption"
          style={{
            display: "block",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Copyright &copy; {new Date().getFullYear()} RAMPVER. All rights
          reserved.
        </label>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const FundListForm = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(FundListPageBase);

export default FundListPage;

export { FundListForm };
