import React, { Component } from "react";

import CategoriesAddForm from "./categoriesAddFront";
import CategoriesAddFunc from "./categoriesAddFunc";

import { withRouter } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const INITIAL_STATE = {
  funds: [],
  categoryCode: "",
  categoryTitle: "",
  choosenFunds: [],
  categoryDescription: "",
  categoryIcon: [],
  categoryCodeError: "",
  categoryTitleError: "",
  choosenFundsError: "",
  categoryDescriptionError: "",
  isLoading: false,
  toggleAlert: false,
  messageType: "warning",
  messageAlert: "",
  toggleActionAlert: false,
};

class CategoriesAddFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.categoriesAddFunction = new CategoriesAddFunc();
    this.db = firebase.firestore();
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validator(name);
    });
  };

  validator = (name) => {
    const { categoryCode, categoryTitle } = this.state;

    switch (name) {
      case "categoryCode":
        if (categoryCode.trim() === "") {
          this.setState({ categoryCodeError: "Please input category code." });
        } else {
          this.setState({ categoryCodeError: "" });
        }
        break;
      case "categoryTitle":
        if (categoryTitle.trim() === "") {
          this.setState({ categoryTitleError: "Please input category title." });
        } else {
          this.setState({ categoryTitleError: "" });
        }
        break;
      default:
        break;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const {
      categoryCode,
      categoryTitle,
      categoryDescription,
      categoryCodeError,
      categoryTitleError,
    } = this.state;

    const isValid = categoryCodeError === "" && categoryTitleError === "";

    if (isValid) {
      this.categoriesAddFunction
        .doCategoryAdd(categoryTitle, categoryDescription, categoryCode)
        .then(() => {
          this.setState({
            isLoading: false,
            toggleActionAlert: true,
            messageType: "success",
            messageAlert: "Category added successfully.",
          });

          setTimeout(() => {
            this.props.history.push(ROUTES.CATEGORIES_LIST);
          }, 1000);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            toggleActionAlert: true,
            messageType: "error",
            messageAlert: error.message,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  onhandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toggleActionAlert: false });
  };

  render() {
    return (
      <CategoriesAddForm
        state={this.state}
        onChange={() => this.handleInputChange.bind(this)}
        onSubmit={() => this.onSubmit.bind(this)}
        onhandleClose={() => this.onhandleClose.bind(this)}
      />
    );
  }
}

export default withRouter(CategoriesAddFormBase);
