import React from "react";

import "./css/fundRatesUpload.css";

import { Link } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

import { DropzoneAreaBase } from "material-ui-dropzone";

import {
  Button,
  CircularProgress,
  Snackbar,
  Chip,
  TextField,
} from "@material-ui/core";

import { AttachFile } from "@material-ui/icons";

import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FundRatesUploadForm = (props) => {
  const {
    fileName,
    fundRatesUploadDate,
    messageType,
    messageAlert,
    toggleActionAlert,
    isLoading,
  } = props.state;

  return (
    <>
      <div className={"fundRatesUploadRoot"}>
        <form onSubmit={props.onSubmit()}>
          <div className={"fundRatesUploadContainer"}>
            <label className={"fundRatesUploadTitle"}>Upload Daily Rates</label>
            <label className={"fundRatesUploadNotice2"}>
              Please upload Daily Rates File
            </label>
            <br />

            <TextField
              label="Date"
              type="date"
              name="fundRatesUploadDate"
              className={"fundRatesUploadDatePickerInput"}
              inputProps={{
                className: "fundRatesUploadDatePicker",
                max: moment().subtract(1, "days").format("YYYY-MM-DD"),
              }}
              variant="outlined"
              value={fundRatesUploadDate}
              onChange={props.onHandleDateChange()}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />

            <DropzoneAreaBase
              acceptedFiles={[
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "xls",
              ]}
              filesLimit={1}
              showPreviewsInDropzone={true}
              onAdd={props.onhandleDropzone()}
            />

            <div>
              <label className={"fundRatesUploadLabels"}>
                File Supported: XLXS
              </label>
              <label
                style={{ float: "right" }}
                className={"fundRatesUploadLabels"}
              >
                Maximum size: 5MB
              </label>
            </div>

            {fileName && (
              <div>
                <Chip
                  icon={<AttachFile className={"fundRatesUploadIconSize"} />}
                  label={fileName}
                  className={"fundRatesUploadChip"}
                  onDelete={props.onhandleFileDelete()}
                  color="secondary"
                />
              </div>
            )}

            <br />
            <br />
            <div>
              <Button
                component={Link}
                to={ROUTES.FUND_LIST}
                style={{ pointerEvents: isLoading ? "none" : "all" }}
                className={"fundRatesUploadCancelButton"}
              >
                Cancel
              </Button>
              {!isLoading ? (
                <Button type="submit" className={"fundRatesUploadAddButton"}>
                  Upload
                </Button>
              ) : (
                <CircularProgress className={"fundRatesUploadLoading"} />
              )}
            </div>
          </div>
        </form>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        open={toggleActionAlert}
        onClose={props.onhandleClose()}
      >
        <Alert onClose={props.onhandleClose()} severity={messageType}>
          {messageAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FundRatesUploadForm;
